import React, { useState, useEffect } from "react"
import classNames from "classnames"
import { BackLink, Loading, BlockContent, Title } from "../../components"

import SEO from "../../components/SEO"
import { IndustryType } from "../../propTypes"
import { withDefaults } from "../../utils"
import withPageDependencies from "../../hoc/withPageDependencies"
import useSanity from "../../hooks/useSanity"
import { blockContentGroq } from "../../groq"
import { Section } from "../../state/levelContext"
import LinkWrapper from "../../components/linkWrapper"
import ImageTextBlock from "../../components/imageTextBlock"

const Veienvidere = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      veienViderePageType: "hjelpen_veienviderePage",
      industryTag: industry.tag,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `{
      "pageData": *[_type==$veienViderePageType &&
                    industry->tag in [$industryTag, "default"] &&
                    count(*[_id=="drafts."+^._id])==0] {
          ...,
          industry-> {
            tag,
          },
          heroImage {
            ...,
            asset-> {
              url
            },
          },
          heroImageContent[] ${blockContentGroq},
          nextStep[] ${blockContentGroq},
          bedriftshelsetjeneste[] ${blockContentGroq},
      }
    }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data.pageData, industry.tag)

  return (
    <>
      <SEO title={page.title} />
      <div className="content">
        <Section>
          <div className={classNames("desktop-col-12")}>
            <Title.Subject title={page.title} />
          </div>
          <div className={classNames("desktop-col-7")}>
            <p>{page.ingress}</p>
          </div>
          <div className={classNames("desktop-col-12")}>
            <ImageTextBlock
              imgUrl={page.heroImage.asset.url}
              imgAlt={page.heroImage.alt}
              content={page.heroImageContent}
            />
          </div>
          <div className="desktop-col-12">
            <BlockContent blocks={page.nextStep || []} />
            <BlockContent blocks={page.bedriftshelsetjeneste || []} />
          </div>
        </Section>
        <LinkWrapper>
          <div>
            <BackLink to={`/bransje/${industry.route}/handlingsplan/sammendrag`}>Tilbake</BackLink>
          </div>
        </LinkWrapper>
      </div>
    </>
  )
}

Veienvidere.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(Veienvidere)
