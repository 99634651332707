import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { Button, BlockContent } from "../../../../components"
import ForbedringspunktTable from "./forbedringspunkt/_forbedringspunktTable"
import { getICalendarEvents } from "./_utils"
import { SubjectType } from "../../../../propTypes"
import { eventLogger } from "../../../../utils"

const CalendarPicker = ({ texts, industryId, subjects, forbedringspunkter }) => {
  const selectedForbedringspunkter = useRef(forbedringspunkter)
  const location = useLocation()

  return (
    <>
      <h1 className="normal-font-weight">{texts.popupTitle}</h1>
      <BlockContent blocks={texts.popupDescription || []} />
      <ForbedringspunktTable
        industryId={industryId}
        subjects={subjects}
        forbedringspunkter={forbedringspunkter}
        editable={false}
        options={{ hideColumn: { description: true } }}
        onSelectionChanged={forbp => {
          selectedForbedringspunkter.current = forbp
        }}
        texts={{
          title: texts.forbedringspunkt_title,
          subjectImportance: texts.forbedringspunkt_subjectImportance,
          priorityLabel: texts.forbedringspunkt_priorityLabel,
          statusLabel: texts.forbedringspunkt_statusLabel,
          deadlineLabel: texts.forbedringspunkt_deadlineLabel,
          responsibleLabel: texts.forbedringspunkt_responsibleLabel,
          descriptionLabel: texts.forbedringspunkt_descriptionLabel,
          doneLabel: texts.forbedringspunkt_doneLabel,
          doneDateLabel: texts.forbedringspunkt_doneDateLabel,
        }}
      />
      <Button.Primary
        type="button"
        style={{ marginTop: 20 }}
        onClick={() => {
          eventLogger.logCustomEvent(location, "at.events.click.export.calendarInvite")

          const icalendar = getICalendarEvents(selectedForbedringspunkter.current)
          icalendar.download()
        }}
      >
        {texts.popupLink}
      </Button.Primary>
      <div style={{ marginTop: 10 }}>{texts.popupLinkDescription}</div>
    </>
  )
}

CalendarPicker.propTypes = {
  forbedringspunkter: PropTypes.array.isRequired,
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  industryId: PropTypes.string.isRequired,
  texts: PropTypes.shape({
    popupTitle: PropTypes.string.isRequired,
    popupDescription: PropTypes.arrayOf(PropTypes.object),
    popupLink: PropTypes.string.isRequired,
    popupLinkDescription: PropTypes.string.isRequired,
    forbedringspunkt_title: PropTypes.string.isRequired,
    forbedringspunkt_subjectImportance: PropTypes.string.isRequired,
    forbedringspunkt_priorityLabel: PropTypes.string.isRequired,
    forbedringspunkt_statusLabel: PropTypes.string.isRequired,
    forbedringspunkt_deadlineLabel: PropTypes.string.isRequired,
    forbedringspunkt_responsibleLabel: PropTypes.string.isRequired,
    forbedringspunkt_descriptionLabel: PropTypes.string.isRequired,
    forbedringspunkt_doneLabel: PropTypes.string.isRequired,
    forbedringspunkt_doneDateLabel: PropTypes.string.isRequired,
  }).isRequired,
}

export default CalendarPicker
