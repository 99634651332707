/* eslint-disable camelcase */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import { FORBEDRINGSPUNKT_PRIORITY } from "../../constants"

const priorityToName = (id, list) => {
  const item = Object.values(list).find(i => i.ID === id)
  return {
    value: item.ID.toString(),
    label: item.TEXT,
  }
}

const Priority = ({ priority }) => {
  // render the progress bar
  return (
    <div className={classNames(styles.priority, { [styles[`priority${priority}`]]: true })}>
      {priorityToName(priority, FORBEDRINGSPUNKT_PRIORITY).label}
    </div>
  )
}

Priority.propTypes = { priority: PropTypes.number.isRequired }

Priority.defaultProps = {}

export default Priority
