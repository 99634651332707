/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import { Document, Page, View } from "@react-pdf/renderer"
import {
  Header,
  Intro,
  Footer,
  styles,
  IntroInfo,
  BevaringspunkterTable,
  ForbedringspunkterTable,
} from "./_pdfComponents"

const Handlingsplan = ({ bevaringspunkter, forbedringspunkter, subjects }) => {
  const allSubjects = [...bevaringspunkter, ...forbedringspunkter]

  return (
    <Document
      title="Arbeidsmiljøhjelpen - Handlingsplan"
      author="Arbeidsmiljøportalen"
      subject="Handlingsplan"
      creator="Arbeidsmiljøportalen"
      producer="Arbeidsmiljøportalen"
    >
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Header />
        <View style={styles.content} wrap>
          <View style={styles.introPage}>
            <Intro allSubjects={allSubjects} subjects={subjects} />
            <IntroInfo />
          </View>
        </View>
        <Footer />
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Header />
        <BevaringspunkterTable bevaringspunkter={bevaringspunkter} subjects={subjects} />
        <Footer />
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Header />
        <ForbedringspunkterTable forbedringspunkter={forbedringspunkter} subjects={subjects} />
        <Footer />
      </Page>
    </Document>
  )
}

Handlingsplan.propTypes = {
  bevaringspunkter: PropTypes.array,
  forbedringspunkter: PropTypes.array,
  notice: PropTypes.shape({ title: PropTypes.string, content: PropTypes.string }),
  subjects: PropTypes.array,
}

Handlingsplan.defaultProps = {
  bevaringspunkter: null,
  forbedringspunkter: null,
  notice: undefined,
  subjects: [],
}

export default Handlingsplan
