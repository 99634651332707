import React, { useState, useEffect } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { pdf } from "@react-pdf/renderer"
import NProgress from "nprogress"

import { BackLink, Loading, Hero, Button } from "../../../components"
import SEO from "../../../components/SEO"
import { IndustryType } from "../../../propTypes"
import { withDefaults } from "../../../utils"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { H, Section } from "../../../state/levelContext"
import ChapterAccordion from "../../../components/chapterAccordion"
import LinkWrapper from "../../../components/linkWrapper"

import styles from "./style.module.scss"
import Subject from "./pdf"

const Moteleder = ({ industry }) => {
  const [params, setParams] = useState(-1)
  const [expandAllAccordions, setExpandAllAccordions] = useState(false)

  useEffect(() => {
    setParams({
      omPageType: "hjelpen_motelederPage",
      paastanderType: "hjelpen_forbedringspunkterIntroPage",
      premadeForbType: "hjelpen_forbedringspunkterPage",
      bevaringspunkterType: "hjelpen_bevaringspunkterPage",
      deleteDataModuleType: "deleteDataModule",
      industryTag: industry.tag,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `{
      "pageData": *[_type==$omPageType &&
                    industry->tag in [$industryTag, "default"] &&
                    count(*[_id=="drafts."+^._id])==0] {
          ...,
          industry-> {
            tag,
          },
          heroImage {
            ...,
            asset-> {
              url
            },
          },
          forChapterItems[] {
            ...,
            content[] ${blockContentGroq},
            subchapter[] {
              ...,
              content[] ${blockContentGroq}
            }
          },
          underChapterItems[] {
            ...,
            content[] ${blockContentGroq},
            subchapter[] {
              ...,
              content[] ${blockContentGroq}
            }
          }
      },
      "deleteDataModule": *[_id==$deleteDataModuleType || _id=="drafts."+$deleteDataModuleType]{
          ...,
          description[] ${blockContentGroq},
      } | order(_updatedAt desc) [0],
      "paastander": *[_type==$paastanderType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          tag,
          title,
        },
        subject-> {
          _id,
          tag,
          title,
        },
        "formulationItemsRefs": coalesce(formulationItemsRef[]-> { _type, _id, title, title_no, title_en}, []),
      },
      "premadeForbedringspunkter": *[_type==$premadeForbType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        industry-> {
          tag,
          _id,
        },
        subject-> {
          _id
        },
        "formulationItemsRefs": coalesce(formulationItemsRef[]-> { ...,}, []),
      },
      "industrySubjects": *[_type == "subject" && _id in *[_id=="${industry._id}_hjelpen_temaerPage"][0].subjectList[]._ref ]{
        ...,
        image {
          ...,
          asset->{
            ...
          }
        }
      },
      "defaultSubjects": *[_type == "subject" && _id in *[_id=="default_hjelpen_temaerPage"][0].subjectList[]._ref ]{
        ...,
        image {
          ...,
          asset->{
            ...
          }
        }
      },
      "bevaringspunkter" : *[_type==$bevaringspunkterType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
          ...,
          industry-> {
            tag,
            title,
          },
          premadeIngress[] ${blockContentGroq},
          "formulationItemsRefs": coalesce(formulationItemsRef[]-> { ...,}, []),
          userIngress[] ${blockContentGroq},
        },
    }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data.pageData, industry.tag)

  const bevaringspunkterPage = withDefaults(data.bevaringspunkter, industry.tag)

  // TODO: This really needs to be fixed later. This should be a separate component, and should not be passed around as prop.
  // Implemented this way because of tight deadline.
  const OfflineModule = ({ title }) => (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      <p>{title}</p>

      {data.industrySubjects.length !== 0 ? (
        data.industrySubjects.map(s => (
          <Button.Tertiary
            type="button"
            onClick={() => {
              const paastander = withDefaults(
                data.paastander.filter(paastand => paastand.subject._id === s._id),
                industry.tag
              )
              try {
                pdf(
                  <Subject
                    bevaringspunktIntro={bevaringspunkterPage.ingress}
                    ingress="Her skal dere diskutere påstander om arbeidsmiljø. Kjenner dere igjen utfordringen hos dere? Diskusjonene skal hjelpe dere å se hva dere bør jobbe med, når dere i neste steg skal velge og legge til bevaringsområder og forbedringsområder."
                    diskusjonTips="Velg en gruppeleder som noterer underveis

                  Bli enige om hvordan dere skal bruke tiden 
                  Dere kan …

                  diskutere alle påstandene

                  velge ut de påstandene som er mest relevante for dere, og hoppe over dem som ikke er relevante

                  Skap gode diskusjoner
                  Lytt til hverandre, og la alle komme til orde og si sine meninger. Ha respekt for at andre kan ha andre meninger og tanker enn deg.

                  Vær konkret og bruk eksempler
                  Vær så konkret som mulig når dere diskuterer, og kom gjerne med eksempler fra egen arbeidshverdag.

                  Snakk om det dere kan gjøre noe med
                  Ikke bruk for mye tid på ting det ikke er mulig å gjøre noe med."
                    paastander={paastander}
                    forbedringspunkterPremade={data.premadeForbedringspunkter.filter(
                      f => f.subject._id === s._id
                    )}
                    industry={industry}
                    subject={s}
                  />
                )
                  .toBlob()
                  .then(blob => {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(
                        blob,
                        `${s.title.replace(" ", "-")}-arbeidshefte.pdf`
                      )
                    } else {
                      const blobUrl = URL.createObjectURL(blob)
                      const pdfw = window.open(blobUrl, "_blank")
                      pdfw.focus()
                      pdfw.onload = () => {
                        pdfw.print()
                      }
                    }
                  })
                  .finally(NProgress.done())
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(`En feil oppstod ved PDF-generering: ${err.name} ${err.message}`)
                NProgress.done()
              }
            }}
          >
            {s.title.replace(" ", "-")}-arbeidshefte.pdf
          </Button.Tertiary>
        ))
      ) : (
        <p>Det er ikke opprettet temaer knyttet til denne bransjen enda</p>
      )}
    </div>
  )

  OfflineModule.propTypes = {
    title: PropTypes.string.isRequired,
  }

  return (
    <>
      <SEO title={page.title} />
      <Hero
        title={page.title}
        content={page.ingress}
        industryName={industry.title}
        image={page.heroImage.asset.url}
        imageRightSide
        altImageText={page.heroImage.alt}
      />
      <div className="content">
        <div className={classNames(styles.pdfColumn, "desktop-col-9")}>
          <Button.Icon
            type="button"
            icon="printprimary"
            onClick={() => {
              setExpandAllAccordions(true)
              setTimeout(() => {
                window.print()
              }, 500)
            }}
          >
            Skriv ut denne siden
          </Button.Icon>
        </div>

        <Section>
          <div className="desktop-col-9">
            <ProcessPart
              title={page.forTitle}
              ingress={page.forIngress}
              chapters={page.forChapterItems}
              OfflineModule={OfflineModule}
              expand={expandAllAccordions}
            />
            <ProcessPart
              title={page.underTitle}
              ingress={page.underIngress}
              chapters={page.underChapterItems}
              OfflineModule={OfflineModule}
              expand={expandAllAccordions}
            />
            <ProcessPart
              title={page.etterTitle}
              ingress={page.etterIngress}
              chapters={page.etterChapterItems}
              OfflineModule={OfflineModule}
              expand={expandAllAccordions}
            />
          </div>
        </Section>

        {/* <DeleteDataDialog text={data.deleteDataModule} /> */}
        <LinkWrapper>
          <div>
            <BackLink to={`/bransje/${industry.route}`}>Tilbake til bransjeforside</BackLink>
          </div>
        </LinkWrapper>
      </div>
    </>
  )
}

Moteleder.propTypes = {
  industry: IndustryType.isRequired,
}

const ProcessPart = ({ title, ingress, chapters, OfflineModule, expand }) => (
  <div className={styles.processPart}>
    <H className="h2">{title}</H>
    <div
      className={classNames(styles.processPartIngress, "markdown")}
      dangerouslySetInnerHTML={{ __html: ingress }} // eslint-disable-line react/no-danger
    />
    <ChapterAccordion
      chapters={chapters || []}
      OfflineModule={OfflineModule}
      expand={expand}
      subchapter="lightBlue"
    />
  </div>
)

ProcessPart.propTypes = {
  title: PropTypes.string.isRequired,
  ingress: PropTypes.string.isRequired,
  chapters: PropTypes.arrayOf(PropTypes.object),
  OfflineModule: PropTypes.any, // TODO: Type this correctly
  expand: PropTypes.bool,
}

ProcessPart.defaultProps = {
  chapters: [],
  OfflineModule: null,
  expand: false,
}

export default withPageDependencies(Moteleder)
