import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Link, usePopup, Button, BlockContent } from "../../../../components"

const Empty = ({ urlHjelpen, urlBevaring, texts }) => {
  const [EmptyPopup] = usePopup(true)
  return (
    <div className={classNames("content")} style={{ marginTop: "15rem" }}>
      <div className="desktop-flexcol-12 center-content">
        <h2 style={{ textAlign: "center" }}>{texts.listTitle}</h2>
        <Link.Big url={urlHjelpen}>{texts.listLink}</Link.Big>
      </div>
      <EmptyPopup>
        <h1>{texts.popupTitle}</h1>
        <BlockContent blocks={texts.popupDescription || []} />
        <Button.Primary icon="arrow" url={urlBevaring}>
          {texts.popupLink}
        </Button.Primary>
      </EmptyPopup>
    </div>
  )
}

Empty.propTypes = {
  urlHjelpen: PropTypes.string.isRequired,
  urlBevaring: PropTypes.string.isRequired,
  texts: PropTypes.shape({
    listTitle: PropTypes.string.isRequired,
    listLink: PropTypes.string.isRequired,
    popupTitle: PropTypes.string.isRequired,
    popupDescription: PropTypes.arrayOf(PropTypes.object),
    popupLink: PropTypes.string.isRequired,
  }).isRequired,
}

export default Empty
