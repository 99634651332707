import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import styles from "./style.module.scss"

import { SubjectType } from "../../../../../propTypes"
import { DropDown, Input } from "../../../../../components"
import {
  setDirtyTitle,
  cancelEditTitle,
  updateForbedringspunkt,
} from "../../../../../state/forbedringspunkter/actions"
import Priority from "../../../../../components/priority"
import { FORBEDRINGSPUNKT_PRIORITY as PRIORITIES } from "../../../../../constants"
import { getDateString } from "../../../../../utils"
import DatePickerField from "../../../../../components/textInput/dateField"

const getDeadlineString = item => {
  return item.deadline !== -1 ? getDateString(item.deadline) : ""
}

const ForbedringspunktMobileAndTablet = ({ subjects, editable, forbedringspunkter }) => {
  const forbedringspunkterFilter = subjects.map(s => ({
    [s._id]: forbedringspunkter.filter(f => f.subjectId === s._id),
  }))

  return (
    <div className={styles.tableWrapperMobileAndTablet}>
      {forbedringspunkterFilter.map(b => {
        const vals = Object.values(b)[0]
        const key = Object.keys(b)[0]
        if (vals.length > 0) {
          return (
            <>
              <Forbedringspunkt
                key={key}
                vals={vals}
                subjectTitle={subjects.find(s => s._id === key).title}
                editable={editable}
              />
            </>
          )
        }
        return null
      })}
    </div>
  )
}
/* eslint-disable */
const Forbedringspunkt = ({ key, vals, subjectTitle, editable }) => {
  const dispatch = useDispatch()

  const onFieldChanged = (itemId, field, value) => {
    dispatch(updateForbedringspunkt({ itemId, [field]: value }))
  }
  const constantListToDropDownOptions = constantList => {
    return Object.values(constantList).map(item => {
      return {
        value: item.ID.toString(),
        label: item.TEXT,
      }
    })
  }

  const constantIdToDropdownOption = (id, list) => {
    const item = Object.values(list).find(i => i.ID === id)
    return {
      value: item.ID.toString(),
      label: item.TEXT,
    }
  }

  return (
    <div className={styles.tableWrapperMobileAndTablet}>
      <div key={key}>
        <div className={styles.tableHeaderMobileAndTablet}>
          <span>
            <span className={styles.bold}>Tema: </span>
            <span>{subjectTitle}</span>
          </span>
        </div>
        {vals.map(v => {
          return (
            <div className={styles.contentStyle}>
              <div className={styles.punkt}>
                {!editable ? (
                  <div>
                    <span className={styles.bold}>Punkt: </span>
                    {v.title.text}
                  </div>
                ) : (
                  <div>
                    <span className={styles.bold}>Punkt: </span>
                    <Input.TextField
                      name={`forbedringspunkt-${v.id}`}
                      placeholder="Nytt punkt..."
                      value={v.title.text}
                      onFocus={() => {
                        dispatch(setDirtyTitle(v.id, true))
                      }}
                      onBlur={() => {
                        dispatch(setDirtyTitle(v.id, false))
                      }}
                      onChange={event => {
                        dispatch(
                          updateForbedringspunkt({
                            ...v,
                            itemId: v.id,
                            title: event.target.value,
                          })
                        )
                      }}
                      onCancelEdit={() => {
                        dispatch(cancelEditTitle(v.id))
                      }}
                      style={{ marginRight: "1rem", flex: 1 }}
                    />
                  </div>
                )}
              </div>

              <div className={styles.beskrivelse}>
                {!editable ? (
                  <div>
                    <span className={styles.bold}>Beskrivelse: </span>
                    {v.description}
                  </div>
                ) : (
                  <div>
                    <span className={styles.bold}>Beskrivelse: </span>
                    <Input.TextField
                      name={`forbedringspunkt-description-${v.id}`}
                      placeholder="Ny beskrivelse..."
                      value={v.description}
                      onFocus={() => {
                        dispatch(setDirtyTitle(v.id, true))
                      }}
                      onBlur={() => {
                        dispatch(setDirtyTitle(v.id, false))
                      }}
                      onChange={event => {
                        dispatch(
                          updateForbedringspunkt({
                            ...v,
                            itemId: v.id,
                            title: v.title.text,
                            description: event.target.value,
                          })
                        )
                      }}
                      onCancelEdit={() => {
                        dispatch(cancelEditTitle(v.id))
                      }}
                      style={{ marginRight: "1rem", flex: 1 }}
                    />
                  </div>
                )}
              </div>

              <div className={styles.priority}>
                {!editable ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className={styles.bold} style={{ paddingRight: "2rem" }}>
                      {" "}
                      Prioritet:{" "}
                    </span>
                    <Priority priority={v.priority} />
                  </div>
                ) : (
                  <div>
                    <span className={styles.bold}>Prioritet: </span>
                    <DropDown
                      name="priority"
                      options={constantListToDropDownOptions(PRIORITIES)}
                      onChange={event => onFieldChanged(v.id, "priority", parseInt(event.value))}
                      optionValue={constantIdToDropdownOption(v.priority, PRIORITIES)}
                      ariaLabel="Prioritet"
                      className={styles.dropDown}
                    />
                  </div>
                )}
              </div>

              <div className={styles.responsible}>
                {!editable ? (
                  <div>
                    <span className={styles.bold}>Ansvarlig: </span>
                    {v.responsible}
                  </div>
                ) : (
                  <div>
                    <span className={styles.bold}>Ansvarlig: </span>
                    <Input.TextField
                      name={`forbedringspunkt-responsible-${v.id}`}
                      placeholder="Legg til ansvarlig..."
                      value={v.responsible}
                      onFocus={() => {
                        dispatch(setDirtyTitle(v.id, true))
                      }}
                      onBlur={() => {
                        dispatch(setDirtyTitle(v.id, false))
                      }}
                      onChange={event => {
                        dispatch(
                          updateForbedringspunkt({
                            ...v,
                            itemId: v.id,
                            title: v.title.text,
                            responsible: event.target.value,
                          })
                        )
                      }}
                      onCancelEdit={() => {
                        dispatch(cancelEditTitle(v.id))
                      }}
                      style={{ marginRight: "1rem", flex: 1 }}
                    />
                  </div>
                )}
              </div>

              <div className={styles.deadline}>
                {!editable ? (
                  <div>
                    <span className={styles.bold}>Sjekk: </span>
                    <span>{getDeadlineString(v) ? getDeadlineString(v) : "Ingen dato satt"}</span>
                  </div>
                ) : (
                  <div>
                    <span className={styles.bold}>Sjekk: </span>
                    <DatePickerField
                      value={v.deadline}
                      onChange={value => {
                        onFieldChanged(v.id, "deadline", value)
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
/* eslint-disable */
ForbedringspunktMobileAndTablet.propTypes = {
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  editable: PropTypes.bool,
  forbedringspunkter: PropTypes.array,
  options: PropTypes.object,
  onSelectionChanged: PropTypes.func,
}

ForbedringspunktMobileAndTablet.defaultProps = {
  editable: false,
  forbedringspunkter: null,
}

export default ForbedringspunktMobileAndTablet
