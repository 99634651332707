import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styles from "./Modal.module.scss"
import icons from "../button/_icons"

const Modal = ({ isOpen, closeModal, children }) => {
  const modalRef = useRef(null)
  const closeButtonRef = useRef(null)

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        // Escape key
        closeModal()
      }
    }

    const handleTab = event => {
      const focusableElements = modalRef.current.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      )
      const firstElement = focusableElements[0]
      const lastElement = focusableElements[focusableElements.length - 1]

      if (event.key === "Tab") {
        if (event.shiftKey && window.document.activeElement === firstElement) {
          event.preventDefault()
          lastElement.focus()
        } else if (!event.shiftKey && window.document.activeElement === lastElement) {
          event.preventDefault()
          firstElement.focus()
        }
      }
    }

    if (isOpen) {
      window.document.addEventListener("keydown", handleEsc)
      window.document.addEventListener("keydown", handleTab)
      closeButtonRef.current.focus()
    }

    return () => {
      window.document.removeEventListener("keydown", handleEsc)
      window.document.removeEventListener("keydown", handleTab)
    }
  }, [isOpen, closeModal])

  const handleOverlayClick = event => {
    if (event.target === event.currentTarget) {
      closeModal()
    }
  }

  return (
    <div
      className={`${styles.modal} ${isOpen ? styles.open : ""}`}
      onChange={handleOverlayClick}
      tabIndex="-1"
      ref={modalRef}
    >
      <div className={styles.modalContent}>
        <button
          type="button"
          className={styles.closeButton}
          onClick={closeModal}
          ref={closeButtonRef}
        >
          <span>Avbryt og lukk</span>
          <img aria-hidden="true" className={styles.main} src={icons.cross} alt="Lukk" />
        </button>
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default Modal
