import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import CheckmarkWhiteIcon from "../../../../images/icons/checkmark-white.svg"

const SubjectFooter = ({ style, statusText, children }) => {
  return (
    <div
      className={classNames("content", "flexrow", "primary-background", styles.subjectFooter)}
      style={style}
    >
      <div
        className={classNames("desktop-flexcol", "tablet-flexcol", "flexrow", styles.footerCounter)}
        style={{ fontWeight: 500 }}
      >
        <img
          src={CheckmarkWhiteIcon}
          style={{ marginRight: "2rem" }}
          className="center-icon"
          alt="Ikon for utført arbeid"
        />
        {statusText}
      </div>
      <div className={classNames("desktop-flexcol-auto", "tablet-flexcol-auto")}>{children}</div>
    </div>
  )
}

SubjectFooter.propTypes = {
  statusText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

SubjectFooter.defaultProps = {
  style: null,
}

export default SubjectFooter
