import {
  HeadingLevel,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  convertInchesToTwip,
  ShadingType,
} from "docx"

import { getDateStringFormat } from "../../../../../../utils/timeAndDateAltFormat"
import { getDateString } from "../../../../../../utils/timeAndDate"

// TODO: The two below should come from an enum in constants/index.js
const PRIORITY = {
  1: "Lav",
  2: "Middels",
  3: "Høy",
}

const STATUS = {
  1: "Ikke startet",
  2: "Igangsatt",
  3: "Utført",
}

const headerMargins = {
  top: convertInchesToTwip(0.1),
  bottom: convertInchesToTwip(0.1),
  right: convertInchesToTwip(0.1),
  left: convertInchesToTwip(0.1),
}

const cellMargins = {
  top: convertInchesToTwip(0.05),
  bottom: convertInchesToTwip(0.05),
  right: convertInchesToTwip(0.05),
  left: convertInchesToTwip(0.05),
}

const textParagraph = (text, options, textOptions) =>
  new Paragraph({
    ...options,
    children: [new TextRun({ text, font: "Tahoma", ...textOptions })],
  })

const Intro = () => {
  const header = new Paragraph({
    text: `Handlingsplan - ${getDateStringFormat(new Date())}`,
    heading: HeadingLevel.HEADING_1,
    font: "Tahoma",
    break: 2,
  })
  const first = new Paragraph({
    text: `Denne handlingsplanen viser hva vi skal jobbe med framover for å forbedre arbeidsmiljøet vårt.`,
    style: "ingress",
  })
  const second = new Paragraph({
    text: `For hvert forbedringsområde vi har valgt, har vi skrevet inn hvilke tiltak vi skal gjennomføre, hvem som er ansvarlig, prioritet og frist for oppfølging.`,
    style: "ingress",
  })

  return [header, first, second]
}

const Bulletlist = () => {
  const empty = new Paragraph({
    text: `   `,
    style: "ingress",
  })
  const first = new Paragraph({
    children: [
      new TextRun({
        text: `Lovpålagt tilknytning til BHT. `,
        bold: true,
        italics: true,
      }),
      new TextRun({
        text: `Dersom risikoforholdene tilsier det, må arbeidsgiver knytte virksomheten til en godkjent bedriftshelsetjeneste (BTH). BHT skal blant annet hjelpe dere å følge opp arbeidsmiljøet i virksomheten og arbeidshelsen.`,
        italics: true,
      }),
    ],
    style: "ingress",

    bullet: {
      level: 0,
    },
  })

  const second = new Paragraph({
    children: [
      new TextRun({
        text: `Arbeidsmiljø er et felles ansvar. `,
        bold: true,
        italics: true,
      }),
      new TextRun({
        text: `Leder er ansvarlig for at arbeidsmiljøet på arbeidsplassen er forsvarlig, men alle ansatte har plikt og rett til å medvirke for å skape et godt og sikkert arbeidsmiljø. Arbeidsmiljø er derfor et felles ansvar, og alle sitt bidrag er viktig for å lykkes med oppfølgingen av tiltakene.`,
        italics: true,
      }),
    ],
    style: "ingress",

    bullet: {
      level: 0,
    },
  })

  // Bulletlist

  return [empty, first, second]
}

const BevaringspunktTable = (smallTitle, bevaringspunkter) => {
  const header = new Paragraph({
    text: smallTitle,
    heading: HeadingLevel.HEADING_2,
    break: 2,
  })

  const headerRow = new TableRow({
    children: [
      new TableCell({
        tableHeader: true,
        margins: headerMargins,
        width: { size: 5, type: "pct" },
        shading: {
          type: ShadingType.SOLID,
          color: "D6F1EE",
        },
        children: [textParagraph("Tema")],
      }),
      new TableCell({
        tableHeader: true,
        margins: headerMargins,
        shading: {
          type: ShadingType.SOLID,
          color: "D6F1EE",
        },
        children: [textParagraph("Beskrivelse - hva gjør vi bra?")],
      }),
    ],
  })

  const rows = bevaringspunkter.map(b => {
    const tableRow = new TableRow({
      children: [
        new TableCell({
          margins: cellMargins,
          children: [textParagraph(b.subjectTitle.title)],
        }),
        new TableCell({ margins: cellMargins, children: [textParagraph(b.title?.text ?? "")] }),
      ],
    })

    return tableRow
  })

  const table = new Table({
    rows: [headerRow, ...rows],
    width: {
      size: 100,
      type: "pct",
    },
  })

  return [header, table]
}

const ForbedringspunktTable = ({ forbedringspunkter = [], smallTitle }) => {
  const header = new Paragraph({
    text: smallTitle,
    heading: HeadingLevel.HEADING_2,
    break: 2,
  })
  const headerRow = new TableRow({
    children: [
      new TableCell({
        tableHeader: true,
        width: { size: 5, type: "pct" },
        margins: headerMargins,
        shading: {
          type: ShadingType.SOLID,
          color: "FFE9B4",
        },
        children: [textParagraph("Tema")],
      }),
      new TableCell({
        tableHeader: true,
        width: { size: 20, type: "pct" },
        margins: headerMargins,
        shading: {
          type: ShadingType.SOLID,
          color: "FFE9B4",
        },
        children: [textParagraph("Punkt")],
      }),
      new TableCell({
        tableHeader: true,
        width: { size: 30, type: "pct" },
        margins: headerMargins,
        shading: {
          type: ShadingType.SOLID,
          color: "FFE9B4",
        },
        children: [textParagraph("Beskrivelse - hva kan vi gjøre?")],
      }),
      new TableCell({
        tableHeader: true,
        margins: headerMargins,
        shading: {
          type: ShadingType.SOLID,
          color: "FFE9B4",
        },
        children: [textParagraph("Ansvarlig")],
      }),
      new TableCell({
        tableHeader: true,
        margins: headerMargins,
        shading: {
          type: ShadingType.SOLID,
          color: "FFE9B4",
        },
        children: [textParagraph("Prioritet")],
      }),
      new TableCell({
        tableHeader: true,
        margins: headerMargins,
        shading: {
          type: ShadingType.SOLID,
          color: "FFE9B4",
        },
        children: [textParagraph("Sjekk")],
      }),
    ],
  })

  const rows = forbedringspunkter.map(f => {
    const tableRow = new TableRow({
      children: [
        new TableCell({
          margins: cellMargins,
          children: [textParagraph(f.subjectTitle?.title)],
        }),
        new TableCell({
          margins: cellMargins,
          children: [textParagraph(f.title?.text ?? "")],
        }),
        new TableCell({
          margins: cellMargins,
          children: [textParagraph(f.description)],
        }),
        new TableCell({
          margins: cellMargins,
          children: [textParagraph(f.responsible)],
        }),

        new TableCell({
          margins: cellMargins,
          children: [textParagraph(PRIORITY[f.priority])],
        }),
        new TableCell({
          margins: cellMargins,
          children: [textParagraph(f.deadline !== -1 ? getDateString(f.deadline) : "")],
        }),
      ],
    })

    return tableRow
  })

  const table = new Table({
    rows: [headerRow, ...rows],
    width: {
      size: 100,
      type: "pct",
    },
  })

  return [header, table]
}
const Bevaringspunkter = (smallTitle, title, bevaringspunkter) => {
  const header = new Paragraph({
    children: [
      new TextRun({
        text: smallTitle,
        font: "Tahoma",
        break: 2,
      }),
    ],
  })
  const header2 = new Paragraph({
    text: title,
    font: "Tahoma",
    heading: HeadingLevel.HEADING_2,
  })
  const bulletPoints = bevaringspunkter.map(b => {
    return new Paragraph({
      text: b.title.text,
      bullet: {
        level: 0,
      },
    })
  })

  return [header, header2, ...bulletPoints]
}

const Forbedringspunkt = forbedringspunkt => {
  const title = new Paragraph({
    children: [new TextRun({ text: forbedringspunkt.title.text, font: "Tahoma", break: 2 })],
  })

  const content = new Paragraph({
    border: {
      bottom: {
        color: "auto",
        space: 4,
        style: "single",
        size: 2,
      },
    },
    children: [
      new TextRun({
        text: "Prioritet: ",
        font: "Tahoma",
        bold: true,
        break: 2,
      }),
      new TextRun({
        text: PRIORITY[forbedringspunkt.priority],
        font: "Tahoma",
        bold: true,
      }),
      new TextRun({
        text: "Status: ",
        font: "Tahoma",
        bold: true,
        break: 2,
      }),
      new TextRun({
        text: STATUS[forbedringspunkt.status],
        font: "Tahoma",
        bold: true,
      }),
      new TextRun({
        text: "Tidsfrist: ",
        font: "Tahoma",
        bold: true,
        break: 2,
      }),
      new TextRun({
        text:
          forbedringspunkt.deadline === -1 ? "Ikke satt" : getDateString(forbedringspunkt.deadline),
        font: "Tahoma",
        bold: true,
      }),
      new TextRun({
        text: "Ansvarlig: ",
        font: "Tahoma",
        bold: true,
        break: 2,
      }),
      new TextRun({
        text: forbedringspunkt.responsible ? forbedringspunkt.responsible : "Ikke satt",
        font: "Tahoma",
        bold: true,
      }),
    ],
  })

  return [title, content]
}

const Subject = ({ subjectTitle = "", forbedringspunkter = [] }) => {
  const smallTitle = new Paragraph({
    text: "Forbedringspunkter",
    font: "Tahoma",
    break: 2,
  })
  const title = new Paragraph({
    text: subjectTitle,
    font: "Tahoma",
    break: 2,
    heading: HeadingLevel.HEADING_2,
  })

  const content = forbedringspunkter
    .map(f => Forbedringspunkt(f))
    .reduce((acc, cur) => [...acc, ...cur], [])

  return [smallTitle, title, ...content]
}

export { Intro, Bulletlist, BevaringspunktTable, ForbedringspunktTable, Bevaringspunkter, Subject }
