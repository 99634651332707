import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import SubjectToggle from "./_subjectToggle"
import { BlockContent } from "../../../../components"
import { SubjectType, IndustryType } from "../../../../propTypes"
import { H } from "../../../../state/levelContext"
import ChapterAccordion from "../../../../components/chapterAccordion"

const SubjectSelector = ({
  style,
  className,
  smallTitle,
  title,
  ingress,
  tips,
  subjectsTitle,
  subjects,
  industry,
}) => {
  useEffect(() => {
    if (window.location.hash && window.location.hash === "#temavelger") {
      window.document.getElementById("temavelger").scrollIntoView()
    }
  })

  return (
    <div
      id="temavelger"
      className={classNames("background-white", className)}
      style={{ justifyContent: "flex-start", ...style }}
    >
      <div className="desktop-col-12 no-padding-top">
        {smallTitle && <h1 className="h5">{smallTitle}</h1>}
        <h1 className="h1 normal-font-weight no-margin-top">{title}</h1>
      </div>
      <div className="desktop-col-7">
        <BlockContent blocks={ingress || []} />
      </div>

      {tips && (
        <div className="desktop-col-9">
          <ChapterAccordion chapters={[{ ...tips, _key: "tips-1" }]} />
        </div>
      )}
      <div className="desktop-col-7">
        <H className="h5">{subjectsTitle}</H>
      </div>
      <SubjectToggle industry={industry} subjects={subjects} />
    </div>
  )
}

SubjectSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  smallTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  ingress: PropTypes.arrayOf(PropTypes.object).isRequired,
  industry: IndustryType.isRequired,
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  tips: PropTypes.object,
  subjectsTitle: PropTypes.string.isRequired,
}

SubjectSelector.defaultProps = {
  style: null,
  className: null,
  tips: null,
}

export default SubjectSelector
