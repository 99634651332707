import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { IndustrySearch, GoodWorkingEnv } from "./_index/_index"
import { FindIndustryFullWidth, Loading } from "../components"
import SEO from "../components/SEO"
import style from "./_index/style.module.scss"
import { IndustryType } from "../propTypes"
import withPageDependencies from "../hoc/withPageDependencies"
import useSanity from "../hooks/useSanity"
import { Section } from "../state/levelContext"

const IndexPage = ({ industries }) => {
  const [params] = useState({
    landingPageId: "landingPage",
    videoAndTextModuleId: "videoAndTextModule",
  })

  const { loading, error, data } = useSanity(
    `{
      "pageData": *[_id==$landingPageId || _id=="drafts."+$landingPageId]
      {
        ...,
        environmentImage {
          ...,
          asset->
        },
      } | order(_updatedAt desc) [0],
    }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = data.pageData

  return (
    <>
      <SEO title="Hjem" description={page.metaDescription} />
      <div className={classNames(style.frontpageContent)}>
        <GoodWorkingEnv
          smallTitle={page.environmentSmallTitle}
          title={page.environmentTitle}
          content={page.environmentIngress}
          imageSource={page.environmentImage.asset.url}
          altText={page.environmentImage.alt}
        />
      </div>

      <Section>
        <div className={classNames("content")} style={{ justifyContent: "left" }}>
          <IndustrySearch
            title={page.findIndustrySearchTitle}
            text={page.findIndustryIngress}
            industries={industries}
          />
          <FindIndustryFullWidth
            title={page.findIndustryChooseTitle}
            text={page.findIndustryIngress}
            industries={industries}
          />
        </div>
      </Section>
    </>
  )
}
IndexPage.propTypes = {
  industries: PropTypes.arrayOf(IndustryType).isRequired,
}

IndexPage.defaultProps = {}

export default withPageDependencies(IndexPage)
