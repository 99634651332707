/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import { Document, Page, View } from "@react-pdf/renderer"
import { Forbedringspunkt, Footer, styles } from "./_pdfWallComponents"

const Handlingsplan = ({ forbedringspunkter, subjects }) => {
  // TODO: Refactor this to utility function
  const forbedingspunkterWithSubject = forbedringspunkter.map(forbedringspunkt => ({
    ...forbedringspunkt,
    subjectTitle: subjects.find(subject => subject._id === forbedringspunkt.subjectId),
  }))
  return (
    <Document
      title="Arbeidsmiljøhjelpen - Handlingsplan"
      author="Arbeidsmiljøportalen"
      subject="Handlingsplan"
      creator="Arbeidsmiljøportalen"
      producer="Arbeidsmiljøportalen"
    >
      {forbedingspunkterWithSubject.map(forbedringspunkt => (
        <Page size="A4" orientation="landscape" style={styles.page} key={forbedringspunkt._id}>
          <View style={styles.body}>
            <Forbedringspunkt forbedringspunkt={forbedringspunkt} />
          </View>
          <Footer subject={forbedringspunkt.subjectTitle.title} />
        </Page>
      ))}
    </Document>
  )
}

Handlingsplan.propTypes = {
  forbedringspunkter: PropTypes.array,
  notice: PropTypes.shape({ title: PropTypes.string, content: PropTypes.string }),
  subjects: PropTypes.array,
}

Handlingsplan.defaultProps = {
  forbedringspunkter: null,
  notice: undefined,
  subjects: [],
}

export default Handlingsplan
