import React from "react"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import Autosuggest from "react-autosuggest"
import { IndustryType } from "../../../propTypes"
import "./autosuggestTextbox.css"
import { eventLogger } from "../../../utils"

// Imagine you have a list of languages that you'd like to autosuggest.
let languages = []

// Teach Autosuggest how to calculate suggestions for any given input value.
function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length

  return inputLength === 0
    ? []
    : languages.filter(lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue)
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
function getSuggestionValue(suggestion) {
  return suggestion.name
}

class AutosuggestTextbox extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)

    languages = props.industries.map(industry => {
      return { name: industry.title }
    })

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      // value: "",
      suggestions: [],
    }
  }

  onChange(_event, { newValue }) {
    this.props.onChange(newValue) // eslint-disable-line react/destructuring-assignment
    // this.setState({
    //   value: newValue,
    // })
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: getSuggestions(value),
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    })
  }

  // Use your imagination to render suggestions.
  renderSuggestion(suggestion) {
    return (
      <div
        onClick={() => {
          this.props.onSuggestionClicked(suggestion.name) // eslint-disable-line react/destructuring-assignment
          // Siteimprove
          window._sz?.push(["event", "Search", "Click", "Dropdown"])
          // Clickstream
          eventLogger.logSearchEvent(useLocation(), "Click/Search", "Dropdown")
        }}
        onKeyDown={e => {
          if (e.key === "Enter") {
            this.props.onSuggestionClicked(suggestion.name) // eslint-disable-line react/destructuring-assignment
            // Siteimprove
            window._sz?.push(["event", "Search", "Enter", "Dropdown"])
            // Clickstream
            eventLogger.logSearchEvent(useLocation(), "Enter/Search", "Dropdown")
          }
        }}
        tabIndex={0}
        role="button"
      >
        {suggestion.name}
      </div>
    )
  }

  render() {
    // const { value, suggestions } = this.state
    const { suggestions } = this.state
    const inputProps = { ...this.props, onChange: this.onChange }

    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    )
  }
}

AutosuggestTextbox.propTypes = {
  industries: PropTypes.arrayOf(IndustryType).isRequired,
  onChange: PropTypes.func.isRequired,
  onSuggestionClicked: PropTypes.func.isRequired,
}

export default AutosuggestTextbox
