import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"
import BlockContent from "../blockContent"
// import Process from "../../images/AH-prosessen.svg"

const ImageTextBlock = ({ imgUrl, imgAlt, content }) => {
  return (
    <>
      {imgUrl && content && (
        <div className={styles.textImageBlock}>
          <div className={styles.imageBlock}>
            {/* <img src={Process} alt="" /> */}
            <img className={styles.image} src={imgUrl} alt={imgAlt} />
          </div>
          <div className={styles.textBlock}>
            <BlockContent blocks={content || []} />
            {/* <p>
          <b>Velg tema</b> <br /> Velg et av de 6 temaene: Arbeidstid, Jobbe med mennesker, Manuelt
          arbeid, Omstilling, Organisering av arbeidet og Tids- og arbeidspress
        </p>
        <br />
        <p>
          <b>Steg 1: Diskuter </b>
          <br /> Diskuter i gruppen om dere kjenner dere igjen i påstandene som omhandler det valgte
          temaet.
        </p>
        <br />
        <p>
          <b>Steg 2: Velg og legg til punkter</b> <br />
          Basert på diskusjonen skal dere velge og legge til punkter på hva dere vil bevare og hva
          dere kan gjøre for å forbedre arbeidsmiljøet.
        </p>

        <br />
        <p>
          <b>Steg 3: Fyll ut handlingsplan</b> <br />
          Beskriv hvordan dere ønsker å løse de valgte forbedringspunktene fra forrige steg med
          ansvarliggjøring, prioriteringer, og frister
        </p>
        <br />
        <p>
          <b>Last ned handlingsplan</b> <br />
          Tiltakene dere har kommet frem til blir til en handlingsplan. Så starter det viktige
          arbeidet med oppfølging av tiltak
        </p> */}
          </div>
        </div>
      )}
    </>
  )
}

ImageTextBlock.propTypes = {
  imgUrl: PropTypes.any,
  imgAlt: PropTypes.any,
  content: PropTypes.any,
}

ImageTextBlock.defaultProps = { imgUrl: null, imgAlt: null, content: null }

export default ImageTextBlock
