import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { getBevaringspunkter } from "../_utils"
import styles from "./style.module.scss"
import { SubjectType } from "../../../../../propTypes"
import { Input } from "../../../../../components"
import {
  updateBevaringspunktTitle,
  setDirtyBevaringspunktTitle,
  cancelEditBevaringspunktTitle,
} from "../../../../../state/bevaringspunkter/actions"

const selectCompletedBevaringspunkter = createSelector(
  state => state.bevaringspunkter,
  (_, industryId) => industryId,
  (bevaringspunkter, industryId) =>
    getBevaringspunkter(industryId, -1, bevaringspunkter).filter(b => b.isChecked)
)

const BevaringspunkterCard = ({ industryId, subjects, editable }) => {
  const bevaringspunkterRaw = useSelector(s => selectCompletedBevaringspunkter(s, industryId))

  const bevaringspunkterFilter = subjects.map(s => ({
    [s._id]: bevaringspunkterRaw.filter(f => f.subjectId === s._id),
  }))

  return (
    <div className={styles.cardWrapper}>
      {bevaringspunkterFilter.map(b => {
        const vals = Object.values(b)[0]
        const key = Object.keys(b)[0]
        if (vals.length > 0) {
          return (
            <>
              <Bevaringspunkt
                key={key}
                vals={vals}
                subjectTitle={subjects.find(s => s._id === key).title}
                editable={editable}
              />
            </>
          )
        }
        return null
      })}
    </div>
  )
}
/* eslint-disable */
const Bevaringspunkt = ({ key, vals, subjectTitle, editable }) => {
  const dispatch = useDispatch()

  return (
    <div key={key}>
      <div className={styles.cardHeader}>
        <span>
          <span className={styles.bold}>Tema: </span>
          <span>{subjectTitle}</span>
        </span>
      </div>

      {vals.map(v => {
        return (
          <div className={styles.contentStyle}>
            <div className={styles.punkt}>
              <div key={`${v.id}-punkt`}>
                {!editable ? (
                  <div>
                    <span className={styles.bold}>Punkt: </span>
                    {v.title.text}
                  </div>
                ) : (
                  <div>
                    <span className={styles.bold}>Punkt: </span>
                    <Input.TextField
                      name={`bevaringspunkt-${v.id}`}
                      placeholder="Nytt punkt..."
                      value={v.title.text}
                      onFocus={() => {
                        dispatch(setDirtyBevaringspunktTitle(v.id, true))
                      }}
                      onBlur={() => {
                        dispatch(setDirtyBevaringspunktTitle(v.id, false))
                      }}
                      onChange={event => {
                        dispatch(
                          updateBevaringspunktTitle({ itemId: v.id, title: event.target.value })
                        )
                      }}
                      onCancelEdit={() => {
                        dispatch(cancelEditBevaringspunktTitle(v.id))
                      }}
                      style={{ marginRight: "1rem", flex: 1 }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
/* eslint-disable */
BevaringspunkterCard.propTypes = {
  industryId: PropTypes.string.isRequired,
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  editable: PropTypes.bool,
}

BevaringspunkterCard.defaultProps = {
  editable: false,
}

export default BevaringspunkterCard
