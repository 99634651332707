import React, { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { Link } from "../../../components"
import style from "./style.module.scss"
import { IndustryType } from "../../../propTypes"
import { H } from "../../../state/levelContext"
import AutosuggestTextbox from "./autosuggestTextbox"
import crossIcon from "../../../images/icons/cross.svg"
import magnifier from "../../../images/icons/search.png"
import { eventLogger } from "../../../utils"

function IndustrySearch({ title, industries, suggestions }) {
  // const other = industries
  //   .filter(i => i.frontpageVisible === false)
  //   .map(v => ({ value: v.route, label: v.title }))

  const [filteredIndustries, setFilteredIndustries] = useState(industries)
  const [currentFilterString, setCurrentFilterString] = useState("")
  const [showFilterResults, setShowFilterResults] = useState(false)
  const [noResultsString, setNoResultsString] = useState("")
  const [moreResultsString, setMoreResultsString] = useState("")
  const location = useLocation()

  function setFilter(newFilter) {
    function shouldIncludeIndustry(industry, filterString) {
      let shouldInclude = false
      const cleanedFilterString = filterString.toLowerCase().trim()
      const industryTitle = industry.title.toLowerCase().trim()

      if (industryTitle.indexOf(cleanedFilterString) > -1) {
        shouldInclude = true
      }

      if (industry.jobs) {
        industry.jobs.forEach(job => {
          const jobTitle = job.value.toLowerCase().trim()
          if (jobTitle.indexOf(cleanedFilterString) > -1) {
            shouldInclude = true
          }
        })
      }

      return shouldInclude
    }

    const filterResults = industries
      .filter(i => i.isActive === true)
      .filter(i => !i.showFactsPageOnly)
      .filter(i => shouldIncludeIndustry(i, newFilter))
    setFilteredIndustries(filterResults)
    setShowFilterResults(newFilter.length > 0)

    const eventTracker = []

    if (filterResults.length < 1) {
      setNoResultsString(newFilter)
      // Clickstream
      eventLogger.logSearchEvent(location, "at.search.negative", newFilter)
      // Siteimprove
      eventTracker.push(["event", "Search", "Negativt søk", newFilter])
      window._sz = eventTracker
    } else {
      setNoResultsString("")
      // // Clickstream
      eventLogger.logSearchEvent(location, "at.search.positive", newFilter)
      // Siteimprove
      eventTracker.push(["event", "Search", "Positivt søk", newFilter])
      window._sz = eventTracker
    }

    if (filterResults.length > 1) {
      setMoreResultsString(newFilter)
    }
  }

  const onFilterChanged = newFilter => {
    setCurrentFilterString(newFilter)
  }

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      setFilter(currentFilterString)
      // Siteimprove button Enter
      window._sz?.push(["event", "Search", "Enter", "Søk"])
      eventLogger.logSearchEvent(location, "Enter", "Søk")
    }
  }

  const handleClick = () => {
    setFilter(currentFilterString)
    // Siteimprove button event
    window._sz?.push(["event", "Search", "Click", "Søk"])
    // Clickstream
    eventLogger.logSearchEvent(location, "Click", "Søk")
  }

  const doSearch = s => {
    setCurrentFilterString(s)
    setFilter(s)
  }

  const clickedSuggestion = (s, event) => {
    if (event && event.key !== "Enter") {
      return
    }
    doSearch(s)
  }

  const clickedReset = event => {
    if (event && event.key !== "Enter") {
      return
    }
    setCurrentFilterString("")
    setFilter("")
  }

  const upcomingIndustries = industries.filter(
    i => i.isActive === true && i.route === "andre-bransjer"
  )

  return (
    <div
      aria-label={title}
      className={classNames("desktop-col-8", "mobile-col-12", style.findIndustryTools)}
    >
      <H className={classNames("h3")}>{title}</H>
      <div className={classNames(style.searchContainer, style.searchBar)}>
        <div style={{ float: "left", width: "100%" }}>
          <img
            src={magnifier}
            style={{ position: "absolute", zIndex: 100, top: 10, left: 8 }}
            width="24px"
            alt="Søk-ikon"
          />
          <AutosuggestTextbox
            aria-label="Søk på yrke eller bransje"
            placeholder="Søk på yrke eller bransje"
            onChange={newValue => onFilterChanged(newValue)}
            onSuggestionClicked={doSearch}
            onKeyDown={e => handleKeyDown(e)}
            industries={industries}
            className={style.inputTextField}
            value={currentFilterString}
          />
          <div className={style.buttonsContainer}>
            {currentFilterString.length > 0 && (
              <div
                role="button"
                aria-labelledby="label"
                onClick={() => clickedReset()}
                onKeyDown={event => clickedReset(event)}
                tabIndex="0"
                className={style.resetSearch}
              >
                <div className={style.resetSearchDiv}>
                  <div className={style.resetLabel} style={{ marginRight: "5px" }}>
                    Tøm
                  </div>
                  <img
                    id="label"
                    aria-hidden
                    src={crossIcon}
                    alt="Tøm søk"
                    className={style.emptySearchIcon}
                  />
                </div>
              </div>
            )}

            <button type="button" className={style.searchButton} onClick={handleClick}>
              <img
                aria-hidden
                src={magnifier}
                style={{ position: "relative", top: 4, left: -7 }}
                width="24px"
                alt="Søk"
              />
              SØK
            </button>
          </div>
        </div>
      </div>
      {suggestions && suggestions.length > 0 && (
        <div className={style.suggestionsContainer}>
          <div className={style.suggestionsLabel}>Foreslåtte søk:</div>
          {suggestions.map(s => (
            <div
              key={s}
              role="button"
              tabIndex="0"
              onClick={() => clickedSuggestion(s)}
              onKeyDown={event => clickedSuggestion(s, event)}
              className={style.suggestion}
            >
              {s}
            </div>
          ))}
        </div>
      )}
      {showFilterResults &&
        filteredIndustries.filter(i => i.isActive === true).filter(i => !i.showFactsPageOnly)
          .length > 1 && (
          <div className={style.moreResults}>
            <p>
              <span style={{ fontWeight: "bold" }}>{capitalizeFirstLetter(moreResultsString)}</span>{" "}
              ga flere resultater.
            </p>
            <p style={{ fontWeight: "bold" }}>
              Gå til bransjesiden som passer best til din arbeidsplass:
            </p>
          </div>
        )}
      {showFilterResults && (
        <ul className={style.filterResults}>
          {filteredIndustries
            .filter(i => i.isActive === true)
            .filter(i => !i.showFactsPageOnly)
            .sort((firstIndustry, secondIndustry) => firstIndustry.sort - secondIndustry.sort)
            .map(industry => (
              <li>
                <Link.Big
                  key={industry.route}
                  url={`/bransje/${industry.route}`}
                  style={{ marginBottom: "1rem" }}
                >
                  {industry.title}
                </Link.Big>
              </li>
            ))}
        </ul>
      )}
      {noResultsString && (
        <div className={style.noResults}>
          <span style={{ fontWeight: "bold" }}>{capitalizeFirstLetter(noResultsString)}</span> ga
          ingen treff.
        </div>
      )}
      {noResultsString && upcomingIndustries.length > 0 && (
        <div>
          <div className={style.noResultsLesMer}>
            Les mer om arbeidsmiljø og se oversikt over kommende bransjesider:
          </div>
          {upcomingIndustries.map(industry => (
            <Link.Big
              key={industry.route}
              url={`/bransje/${industry.route}`}
              displayBlock
              style={{ marginBottom: "1rem" }}
            >
              {industry.title}
            </Link.Big>
          ))}
        </div>
      )}

      {/* {other.length !== 0 ? (
        <DropDown
          name="industry"
          id="industry"
          options={other}
          className={style.select}
          placeholder="Flere bransjer"
          onChange={event => navigate(event.value)}
          ariaLabel="Velg en annen bransje"
        />
      ) : null} */}
    </div>
  )
}

IndustrySearch.propTypes = {
  title: PropTypes.string.isRequired,
  industries: PropTypes.arrayOf(IndustryType).isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default IndustrySearch
