import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import {
  Button,
  BackLink,
  Title,
  BlockContent,
  CheckBoxItem,
  SecondaryMenu,
  Loading,
} from "../../../components"
import SEO from "../../../components/SEO"
import { IndustryType, SubjectType } from "../../../propTypes"
import { withDefaults, useDispatch } from "../../../utils"
import { getBevaringspunkter } from "../handlingsplan/sammendrag/_utils"
import {
  addBevaringspunkt,
  deleteBevaringspunkt,
  checkBevaringspunkt,
  updateBevaringspunktTitle,
  setDirtyBevaringspunktTitle,
  cancelEditBevaringspunktTitle,
} from "../../../state/bevaringspunkter/actions"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"

const Bevaringspunkter = ({ industry, subjects, subject }) => {
  const bevaringspunkterRaw = useSelector(s => s.bevaringspunkter)
  const dispatch = useDispatch()

  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "hjelpen_bevaringspunkterPage",
      industryTag: industry.tag,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        industry-> {
          tag
        },
        smallTitle,
        title,
        ingress[] ${blockContentGroq},
        bevaringspunkterTitle,

        editButtonText,
        saveButtonText,
        deleteButtonText,

        addNewButtonText,
        nextPageButtonText,
      }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data, industry.tag)

  const bevaringspunkter = getBevaringspunkter(industry._id, subject._id, bevaringspunkterRaw)

  const onAddBevaringspunkt = () => {
    dispatch(addBevaringspunkt(industry._id))
  }

  const onItemChecked = bevaringspunktId => {
    dispatch(checkBevaringspunkt(bevaringspunktId))
  }

  return (
    <>
      <SEO title={page.smallTitle} />
      <SecondaryMenu industry={industry} subjects={subjects} />
      <div className="content">
        <div className="desktop-col-10">
          <Title.Underline title={page.title} smallTitle={page.smallTitle} />
          <BlockContent blocks={page.ingress || []} />
          <h4>{page.bevaringspunkterTitle}</h4>
          {bevaringspunkter.map(item => {
            const checkBoxItem = {
              id: item.id,
              title: item.title.text,
              isChecked: item.isChecked,
              isNew: item.isNew,
            }
            return (
              <CheckBoxItem
                key={`bevaringspunkt-${item.id}`}
                item={checkBoxItem}
                onClick={onItemChecked}
                canEdit
                editBtnText={page.editButtonText || "Endre"}
                deleteBtnText={page.deleteButtonText}
                saveBtnText={page.saveButtonText}
                setDirtyTitle={setDirtyBevaringspunktTitle}
                cancelEditTitle={cancelEditBevaringspunktTitle}
                updateItem={updateBevaringspunktTitle}
                deleteItem={deleteBevaringspunkt}
              />
            )
          })}

          <Button.AddNew onClick={onAddBevaringspunkt} title={page.addNewButtonText} />

          <Button.Primary
            style={{ marginTop: "3rem" }}
            url={`/bransje/${industry.route}#temavelger`}
            icon="arrow"
          >
            {page.nextPageButtonText}
          </Button.Primary>
        </div>
      </div>
      <BackLink to={`/bransje/${industry.route}`}>Til oversikt</BackLink>
    </>
  )
}

Bevaringspunkter.propTypes = {
  industry: IndustryType.isRequired,
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  subject: SubjectType.isRequired,
}

export default withPageDependencies(Bevaringspunkter)
