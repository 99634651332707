import Main from "./main"
import Om from "./om"
import Moteleder from "./moteleder"
import Veienvidere from "./veienvidere"
import Temavelger from "./temavelger"
import UtvidetVeileder from "./utvidet-veileder"
import Bevaringspunkter from "./bevaringspunkter"
import Forbedringspunkter from "./forbedringspunkter"
import Handlingsplan from "./handlingsplan"
import Sammendrag from "./handlingsplan/sammendrag"
import Intro from "./intro"
import LandingssideMedVelger from "./landingsside-med-velger"

export default {
  Main,
  Om,
  Moteleder,
  Veienvidere,
  Temavelger,
  UtvidetVeileder,
  Bevaringspunkter,
  Forbedringspunkter,
  Handlingsplan,
  Intro,
  LandingssideMedVelger,
  Sammendrag,
}
