import { UnderlineType, convertInchesToTwip, LevelFormat, AlignmentType } from "docx"

const DefaultStyles = {
  default: {
    heading1: {
      run: {
        size: 36,
        font: "Tahoma",
        bold: true,
        color: "000000",
      },
      paragraph: {
        spacing: {
          after: 450,
        },
      },
    },
    heading2: {
      run: {
        size: 30,
        font: "Tahoma",
        bold: true,
        color: "000000",
      },
      paragraph: {
        spacing: {
          before: 240,
          after: 240,
        },
      },
    },
    listParagraph: {
      run: {
        size: 20,
        color: "#FF0000",
      },
    },
  },
  paragraphStyles: [
    {
      id: "header",
      name: "Header",
      basedOn: "Normal",
      quickFormat: true,
      run: {
        size: 16,
        color: "#333333",
        font: "Tahoma",
      },
      paragraph: {
        spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
      },
    },
    {
      id: "body",
      name: "Body",
      basedOn: "Normal",
      quickFormat: true,
      run: {
        size: 20,
        font: "Tahoma",
        color: "#333333",
        // italics: true,
      },
      paragraph: {
        spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
      },
    },
    {
      id: "ingress",
      name: "Ingress",
      basedOn: "Normal",
      quickFormat: true,
      run: {
        size: 20,
        color: "#333333",
        font: "Tahoma",
      },
      paragraph: {
        spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
      },
    },
    {
      id: "aside",
      name: "Aside",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "999999",
        italics: true,
      },
      paragraph: {
        indent: {
          left: convertInchesToTwip(0.5),
        },
        spacing: {
          line: 276,
        },
      },
    },
    {
      id: "wellSpaced",
      name: "Well Spaced",
      basedOn: "Normal",
      quickFormat: true,
      paragraph: {
        spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
      },
    },
    {
      id: "strikeUnderline",
      name: "Strike Underline",
      basedOn: "Normal",
      quickFormat: true,
      run: {
        strike: true,
        underline: {
          type: UnderlineType.SINGLE,
        },
      },
    },
  ],
  characterStyles: [
    {
      id: "strikeUnderlineCharacter",
      name: "Strike Underline",
      basedOn: "Normal",
      quickFormat: true,
      run: {
        strike: true,
        underline: {
          type: UnderlineType.SINGLE,
        },
      },
    },
  ],
}

const DefaultNumbering = {
  config: [
    {
      reference: "my-crazy-numbering",
      levels: [
        {
          level: 0,
          format: LevelFormat.LOWER_LETTER,
          text: "%1)",
          alignment: AlignmentType.LEFT,
        },
      ],
    },
  ],
}

export { DefaultStyles, DefaultNumbering }
