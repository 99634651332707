import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { getBevaringspunkter } from "../_utils"
import styles from "./style.module.scss"
import ChevronDown from "../../../../../images/icons/chevron-down.svg"
import ChevronUp from "../../../../../images/icons/chevron-up.svg"
import { SubjectType } from "../../../../../propTypes"
import { Input, Button } from "../../../../../components"
import {
  updateBevaringspunktTitle,
  setDirtyBevaringspunktTitle,
  cancelEditBevaringspunktTitle,
} from "../../../../../state/bevaringspunkter/actions"

const selectCompletedBevaringspunkter = createSelector(
  state => state.bevaringspunkter,
  (_, industryId) => industryId,
  (bevaringspunkter, industryId) =>
    getBevaringspunkter(industryId, -1, bevaringspunkter).filter(b => b.isChecked)
)

const BevaringspunkterTable = ({ industryId, subjects, editable }) => {
  const bevaringspunkterRaw = useSelector(s => selectCompletedBevaringspunkter(s, industryId))

  const dispatch = useDispatch()

  const [sorting, setSorting] = React.useState({})
  const [bevaringspunkterSorted, setBevaringspunkterSorted] = React.useState(bevaringspunkterRaw)

  const sortBySubject = (a, b) => {
    const aValue = subjects.find(subject => subject._id === a.subjectId)
      ? subjects.find(subject => subject._id === a.subjectId).title
      : "Ukjent tema"
    const bValue = subjects.find(subject => subject._id === b.subjectId)
      ? subjects.find(subject => subject._id === b.subjectId).title
      : "Ukjent tema"

    if (aValue === bValue) {
      return 0
    }

    return aValue > bValue ? 1 : -1
  }

  const sortByPunkt = (a, b) => {
    if (a.title.text.toLowerCase() === b.title.text.toLowerCase()) {
      return 0
    }
    return a.title.text.toLowerCase() > b.title.text.toLowerCase() ? 1 : -1
  }

  // const sortColumn = (a, b, columnId) => {
  //   const aValue = a.getValue(columnId).text
  //   const bValue = b.getValue(columnId).text
  //   return aValue === bValue ? 0 : aValue > bValue ? 1 : -1
  // }

  const isSorted = column => {
    return sorting[column] ? sorting[column].direction : "none"
  }

  const sortColumn = column => {
    const currentSorting = isSorted(column)
    let direction = "none"

    if (currentSorting === "none") {
      direction = "asc"
    } else if (currentSorting === "asc") {
      direction = "desc"
    }
    setSorting({
      [column]: {
        direction,
      },
    })
  }

  useEffect(() => {
    const sortingObj = Object.keys(sorting)[0]
    if (sortingObj === "subject") {
      if (sorting[sortingObj].direction === "asc") {
        setBevaringspunkterSorted([...bevaringspunkterRaw].sort(sortBySubject))
      } else if (sorting[sortingObj].direction === "desc") {
        setBevaringspunkterSorted([...bevaringspunkterRaw].sort(sortBySubject).reverse())
      } else {
        setBevaringspunkterSorted([...bevaringspunkterRaw])
      }
    } else if (sortingObj === "punkt") {
      if (sorting[sortingObj].direction === "asc") {
        setBevaringspunkterSorted([...bevaringspunkterRaw].sort(sortByPunkt))
      } else if (sorting[sortingObj].direction === "desc") {
        setBevaringspunkterSorted([...bevaringspunkterRaw].sort(sortByPunkt).reverse())
      } else {
        setBevaringspunkterSorted([...bevaringspunkterRaw])
      }
    } else {
      setBevaringspunkterSorted([...bevaringspunkterRaw])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, bevaringspunkterRaw])

  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead className={styles.tableHeader}>
          <tr>
            <th style={{ width: 200 }}>
              <div>
                <Button.Tertiary
                  type="button"
                  onClick={() => {
                    sortColumn("subject")
                  }}
                >
                  Tema
                  {{
                    asc: <img src={ChevronUp} alt="Sort ascending" />,
                    desc: <img src={ChevronDown} alt="Sort descending" />,
                  }[isSorted("subject")] ?? <img src={ChevronDown} alt="Sort descending" />}
                </Button.Tertiary>
              </div>
            </th>
            <th>
              <div>
                <Button.Tertiary
                  type="button"
                  onClick={() => {
                    sortColumn("punkt")
                  }}
                >
                  Punkt
                  {{
                    asc: <img src={ChevronUp} alt="Sort ascending" />,
                    desc: <img src={ChevronDown} alt="Sort descending" />,
                  }[isSorted("punkt")] ?? <img src={ChevronDown} alt="Sort descending" />}
                </Button.Tertiary>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {bevaringspunkterSorted.map(item => (
            <tr key={item.id} className={styles.tableRow}>
              <td key={`${item.id}-tema`}>
                {item.subjectId !== undefined
                  ? subjects.find(subject => subject._id === item.subjectId).title
                  : "Ukjent tema"}
              </td>
              <td key={`${item.id}-punkt`}>
                {!editable ? (
                  <span>{item.title.text}</span>
                ) : (
                  <Input.TextField
                    name={`bevaringspunkt-${item.id}`}
                    placeholder="Nytt punkt..."
                    value={item.title.text}
                    // autoFocus={isFocused.rowIndex === index && isFocused.columnId === id}
                    onFocus={() => {
                      dispatch(setDirtyBevaringspunktTitle(item.id, true))
                    }}
                    onBlur={() => {
                      dispatch(setDirtyBevaringspunktTitle(item.id, false))
                    }}
                    onChange={event => {
                      dispatch(
                        updateBevaringspunktTitle({ itemId: item.id, title: event.target.value })
                      )
                      // setValue({ ...value, title: { ...value.title, text: event.target.value } }) //Dispatching update here make the field loose focus because of re-render
                    }}
                    onCancelEdit={() => {
                      dispatch(cancelEditBevaringspunktTitle(item.id))
                    }}
                    style={{ marginRight: "1rem", flex: 1 }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

BevaringspunkterTable.propTypes = {
  industryId: PropTypes.string.isRequired,
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  editable: PropTypes.bool,
}

BevaringspunkterTable.defaultProps = {
  editable: false,
}

export default BevaringspunkterTable
