/* eslint-disable react/prop-types */
import React from "react"
import { View, Text, Image } from "@react-pdf/renderer"
import { getDateString } from "../../../../../utils"
import styles from "./styles"

// TODO: The two below should come from an enum in constants/index.js
const PRIORITY = {
  "-1": "Ikke satt",
  1: "Lav",
  2: "Middels",
  3: "Høy",
}
const Header = () => (
  <View fixed style={styles.header}>
    <Text style={styles.headerLeft}>Arbeidsmiljøhjelpen</Text>
    <Text>{getDateString()}</Text>
  </View>
)
const Footer = ({ subject }) => (
  <View fixed style={styles.footer}>
    <Text>{subject}</Text>
    <Text style={{ textAlign: "right" }}>www.arbeidsmiljøhjelpen.no</Text>
  </View>
)

const Forbedringspunkt = ({ forbedringspunkt }) => (
  <View style={styles.forbedringspunkt} wrap={false}>
    <View style={styles.punkt}>
      <Text>{forbedringspunkt.title.text}</Text>
    </View>
    <View style={styles.forbedringspunktContent}>
      <View style={{ flex: 1 }}>
        <View style={styles.iconWithText}>
          <Image style={styles.icon} src="/wall_neste_sjekk.png" />
          <View style={styles.flexCenter}>
            <Text style={styles.smallHeader}>Neste sjekk: </Text>
            <Text style={styles.bold}>
              {forbedringspunkt.deadline === -1
                ? "Ikke satt"
                : getDateString(forbedringspunkt.deadline)}
            </Text>
          </View>
        </View>
        <View style={styles.iconWithText}>
          <Image style={styles.icon} src={`/wall_prioritet_${forbedringspunkt.priority}.png`} />
          <View style={styles.flexCenter}>
            <Text style={styles.smallHeader}>Prioritet: </Text>
            <Text style={styles.bold}>{PRIORITY[forbedringspunkt.priority]}</Text>
          </View>
        </View>
        <View style={styles.iconWithText}>
          <Image style={styles.icon} src="/wall_ansvarlig.png" />
          <View style={styles.flexCenter}>
            <Text style={styles.smallHeader}>Ansvarlig: </Text>
            <Text style={styles.bold}>
              {forbedringspunkt.responsible ? forbedringspunkt.responsible : "Ikke satt"}
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <TitleAndText title="Beskrivelse" text={forbedringspunkt.description} />
      </View>
    </View>
  </View>
)
const TitleAndText = ({ style, title, text }) => (
  <View style={[style, styles.titleAndText]}>
    <Text style={styles.h3}>{title}</Text>
    <Text style={styles.bold}>{text}</Text>
  </View>
)

export { styles, Header, Footer, Forbedringspunkt }
