import { StyleSheet, Font } from "@react-pdf/renderer"

Font.register({
  family: "worksans",
  fonts: [
    { src: "/WorkSans-Thin.ttf", fontStyle: "normal", fontWeight: 100 },
    { src: "/WorkSans-ExtraLight.ttf", fontStyle: "normal", fontWeight: 200 },
    { src: "/WorkSans-Light.ttf", fontStyle: "normal", fontWeight: 300 },
    { src: "/WorkSans-Regular.ttf", fontStyle: "normal", fontWeight: 400 },
    { src: "/WorkSans-Medium.ttf", fontStyle: "normal", fontWeight: 500 },
    { src: "/WorkSans-SemiBold.ttf", fontStyle: "normal", fontWeight: 600 },
    { src: "/WorkSans-Bold.ttf", fontStyle: "normal", fontWeight: 700 },
    { src: "/WorkSans-ExtraBold.ttf", fontStyle: "normal", fontWeight: 800 },
    { src: "/WorkSans-Black.ttf", fontStyle: "normal", fontWeight: 900 },

    { src: "/WorkSans-ThinItalic.ttf", fontStyle: "italic", fontWeight: 100 },
    { src: "/WorkSans-ExtraLightItalic.ttf", fontStyle: "italic", fontWeight: 200 },
    { src: "/WorkSans-LightItalic.ttf", fontStyle: "italic", fontWeight: 300 },
    { src: "/WorkSans-Italic.ttf", fontStyle: "italic", fontWeight: 400 },
    { src: "/WorkSans-MediumItalic.ttf", fontStyle: "italic", fontWeight: 500 },
    { src: "/WorkSans-SemiBoldItalic.ttf", fontStyle: "italic", fontWeight: 600 },
    { src: "/WorkSans-BoldItalic.ttf", fontStyle: "italic", fontWeight: 700 },
    { src: "/WorkSans-ExtraBoldItalic.ttf", fontStyle: "italic", fontWeight: 800 },
    { src: "/WorkSans-BlackItalic.ttf", fontStyle: "italic", fontWeight: 900 },
  ],
})

const styles = StyleSheet.create({
  viewer: {
    height: 600,
    width: "100%",
  },
  page: {
    height: "100%",
    padding: 30,
    backgroundColor: "#D3D3D3",
    opacity: 0.2,
    fontSize: 10,
    fontFamily: "worksans",
  },
  yellowBorder: {
    borderStyle: "solid",
    borderColor: "#FCC771",
    borderWidth: 1,
  },
  bgYellow: {
    backgroundColor: "#FFE9B4",
  },
  bgGreen: {
    backgroundColor: "#D6F1EE",
  },
  greenBorder: {
    borderStyle: "solid",
    borderColor: "#11C1B0",
    borderWidth: 1,
  },
  table: {
    marginTop: 15,
    display: "table",
    width: "auto",
    borderStyle: "solid",
    // borderColor: "#11C1B0",
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    // borderLeftWidth: 0,
  },

  tableRow: {
    width: "100%",
    // margin: "auto",
    flexDirection: "row",
  },
  tabelHead: {
    backgroundColor: "#D6F1EE",
    width: "100%",
    flexDirection: "row",
    fontWeight: 500,
  },

  tableCol: {
    borderStyle: "solid",
    borderColor: "#FCC771",
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableColFirst: {
    flexBasis: "15%",
    borderRight: "1px solid #F7F7F7",
    borderBottom: "1px solid #F7F7F7",
  },
  tableColSecond: {
    flexBasis: "85%",
    borderBottom: "1px solid #F7F7F7",
  },
  tableColHeadFirst: {
    flexBasis: "15%",
    borderRight: "1px solid #11C1B0",
    borderColor: "#11C1B0",
  },
  tableColHeadSecond: {
    flexBasis: "75%",
    borderRightWidth: 0,
  },
  tableCell: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 10,
    padding: 10,
  },

  tableYellow: {
    marginTop: 15,
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#11C1B0",
    borderWidth: 1,
    // borderBottomWidth: 0,
    // borderRightWidth: 0,
  },

  tabelHeadYellow: {
    backgroundColor: "#D6F1EE",
    width: "100%",
    flexDirection: "row",
    fontWeight: 500,
  },

  tableCol30: {
    flexBasis: "30%",
    borderBottom: "1px solid #F7F7F7",
  },
  tableCol10: {
    flexBasis: "15%",
    borderRight: "1px solid #F7F7F7",
    borderBottom: "1px solid #F7F7F7",
  },
  tableCol80: {
    flexBasis: "85%",
    borderBottom: "1px solid #F7F7F7",
  },
  tableColHead10: {
    flexBasis: "15%",
    borderRight: "1px solid #11C1B0",
    borderColor: "#11C1B0",
  },
  tableColHead80: {
    flexBasis: "75%",
    borderRightWidth: 0,
  },

  body: {
    fontFamily: "worksans",
    fontWeight: 400,
    fontSize: 10,
    paddingTop: 30,
    paddingHorizontal: 30,
    paddingBottom: 50,
  },
  introPage: {
    backgroundColor: "#FFFFFF",
    opacity: 1,
  },
  pagePadding: {
    paddingTop: 15,
    marginTop: 20,
  },
  content: {
    backgroundColor: "#FFFFFF",
    opacity: 1,
    padding: 25,
    minHeight: "94%",
  },

  header: {
    opacity: 1,
    height: "3%",
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 400,
    color: "#333333",
  },

  footer: {
    opacity: 0.7,
    height: "3%",
    paddingTop: "3px",
    textAlign: "right",
    fontFamily: "worksans",
    fontSize: 13,
    fontWeight: 400,
    color: "#333333",
  },

  introContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  introInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  h1: {
    fontFamily: "worksans",
    fontSize: 20,
    fontWeight: 600,
  },
  h2: {
    fontFamily: "worksans",
    fontSize: 14,
    fontWeight: 400,
  },
  h3: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 10,
  },
  hr: {
    paddingTop: 15,
    marginTop: 20,
    paddingBottom: 15,
    marginBottom: 20,
  },
  centerText: {
    textAlign: "center",
  },

  bevaringspunkt: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: -10,
  },
  pageTitle: {
    paddingBottom: 25,
  },
  titleAndText: {
    paddingHorizontal: 10,
  },
  boxPrimary: {
    marginVertical: 10,
    border: "2 solid #11c1b0",
    padding: 15,
  },
  boxHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingBottom: 7,
    marginBottom: 12,
  },
  subjectImportance: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  importanceImagesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  importanceImage: {
    display: "inline-flex",
    marginLeft: 5,
    width: 13,
    height: 13,
  },
  introText: {
    fontFamily: "worksans",
    fontWeight: 400,
    fontSize: "13px",
    width: "73%",
    lineHeight: "1.5px",
    paddingRight: 20,
  },
  introImage: {
    width: "20%",
  },
  processImage: {
    width: "100%",
  },
  bulletItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  bullet: {
    marginTop: 2,
    marginRight: 10,
    marginLeft: 5,
    width: 11,
    height: 11,
  },
  forbedringspunkt: {
    border: "2px solid #FCC771",
  },
  forbedringspunktHeader: {
    backgroundColor: "#FFE9B4",
    borderBottom: "2px solid #FCC771",
    padding: "15px",
  },
  subjectOverviewCompleted: {},
  subjectsOverviewFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  subject: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    backgroundColor: "#f2f2f2",
    borderRadius: "5px",
    width: "15%",
    height: "40px",
    marginRight: "2px",
    fontFamily: "worksans",
    fontWeight: 500,
    fontSize: "9px",
    textAlign: "center",
    padding: "4px",
    marginBottom: 10,
  },
  subjectCompleted: {
    border: "2.5 solid #fcc771",
  },

  imageContainer: {
    width: "15px",
    height: "15px",
    position: "absolute",
    bottom: "-9px",
    right: "5px",
  },
  forbedringspunktContent: {
    padding: "15px",
    display: "flex",
    flexDirection: "row",
  },
  forbedringspunktContentTitle: {
    fontSize: 8,
    fontWeight: 400,
    paddingBottom: "3px",
  },
  forbedringspunktContentTitleContent: {
    paddingTop: "3px",
    paddingBottom: "2px",
  },
  forbedringspunktContentTitleContentPriority: {
    border: "0.5px solid #D3D3D3",
  },
  forbedringspunktCheckPoint: {
    paddingBottom: "10px",
  },
  priorityLow: {
    backgroundColor: "#C9FFC8",
  },
  priorityMedium: {
    backgroundColor: "#FFECC8",
  },
  priorityHigh: {
    backgroundColor: "#FFC8C8",
  },
  done: {
    display: "flex",
    flexDirection: "row",
  },
  bold: {
    fontWeight: 500,
  },
  thin: {
    fontWeight: 300,
  },
  italic: {
    fontFamily: "worksans",
    fontStyle: "italic",
    fontWeight: 400,
  },
  boldItalic: {
    fontFamily: "worksans",
    fontStyle: "italic",
    fontWeight: 700,
  },
  dot: {
    width: 8,
    height: 8,
    backgroundColor: "#11c1b0", // Primary
    borderRadius: 4,
    marginLeft: -20,
  },
  smallDot: {
    width: 4,
    height: 4,
    borderRadius: 2,
  },
  primaryBgColor: {
    backgroundColor: "#11c1b0", // Primary
  },
  yellowBgColor: {
    backgroundColor: "#ffe6a6", // Yellow
  },
  blackBgColor: {
    backgroundColor: "black", // Yellow
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  infoPadding: {
    paddingHorizontal: 20,
  },
  bevaringspunktText: {
    paddingBottom: 10,
    paddingRight: 75,
    lineHeight: 1.7,
  },
})
export default styles
