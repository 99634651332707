import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useLocation } from "@reach/router"
import { Button } from "../../../../components"
import { openPdf } from "./_utils"
import { IndustryType, SubjectType } from "../../../../propTypes"
import ExcelButton from "./_excelButton"
import { eventLogger, getFileName } from "../../../../utils"
import HandlingplanWord from "./word/utskriftHandlingsplan"
import HandlingplanTable from "./word/tableHandlingsplan"
import HandlingsplanWall from "./pdfWall"
import HandlingsplanPdf from "./pdf"
import HandlingsplanTabell from "./pdfTabell"

import styles from "./style.module.scss"

const SaveDialogNew = ({
  texts,
  industry,
  subjects,
  premadeForbedringspunkter,
  forbedringspunkter,
  bevaringspunkter,
  notice,
}) => {
  const location = useLocation()
  return (
    <>
      <h1 className="normal-font-weight">{texts.popupTitle}</h1>
      <p>{texts.popupDescription}</p>
      <div>
        <h2 className={classNames("h3", styles.exportType)}>Veggpryd</h2>
        <div
          className="veggpryd content"
          style={{ display: "flex", gap: "1em", marginBottom: "2em" }}
        >
          <Button.Export
            id="export-wall-pdf"
            icon="pdf"
            type="Veggpryd"
            onClick={() => {
              eventLogger.logCustomEvent(location, "at.events.click.export.save.wallPdf")
              openPdf(
                HandlingsplanWall({ forbedringspunkter, subjects }),
                "export-wall-pdf",
                "Arbeidsmiljøhjelpen_handlingsplan_veggpryd.pdf"
              )
            }}
          >
            Lagre som PDF
          </Button.Export>
        </div>
      </div>
      <div>
        <h2 className={classNames("h3", styles.exportType)}>Utskriftsvennlig</h2>
        <div
          className="utskriftsvennlig content"
          style={{ display: "flex", gap: "1em", marginBottom: "2em" }}
        >
          <Button.Export
            id="export-print-pdf"
            type="Utskriftsvennlig"
            icon="pdf"
            onClick={() => {
              eventLogger.logCustomEvent(location, "at.events.click.export.save.printPdf")

              openPdf(
                HandlingsplanPdf({ bevaringspunkter, forbedringspunkter, notice, subjects }),
                "export-print-pdf",
                "Arbeidsmiljøhjelpen_handlingsplan.pdf"
              )
            }}
          >
            Lagre som PDF
          </Button.Export>
          <Button.Export
            id="export-print-word"
            type="Utskriftsvennlig"
            icon="word"
            onClick={() => {
              eventLogger.logCustomEvent(location, "at.events.click.export.save.printWord")
              HandlingplanWord({ bevaringspunkter, forbedringspunkter, notice, subjects })
            }}
          >
            Lagre i Word
          </Button.Export>
        </div>
      </div>
      <div>
        <h2 className={classNames("h3", styles.exportType)}>Tabell</h2>
        <div
          className="tabell content"
          style={{ display: "flex", gap: "1em", marginBottom: "2em" }}
        >
          <Button.Export
            id="export-table-word"
            type="Tabell"
            icon="word"
            onClick={() => {
              eventLogger.logCustomEvent(location, "at.events.click.export.save.tableWord")
              HandlingplanTable({ bevaringspunkter, forbedringspunkter, subjects })
            }}
          >
            Lagre i Word
          </Button.Export>

          <ExcelButton
            fileName={getFileName(industry.title)}
            buttonName="Åpne i Excel"
            industryId={industry._id}
            forbedringspunkterPremade={premadeForbedringspunkter}
            subjects={subjects}
          >
            <Button.Export
              id="export-table-excel"
              type="Tabell"
              icon="excel"
              onClick={() => {
                eventLogger.logCustomEvent(location, "at.events.click.export.save.tableExcel")
              }}
            >
              Lagre i Excel
            </Button.Export>
          </ExcelButton>
          <Button.Export
            id="export-table-pdf"
            icon="pdf"
            type="Tabell"
            onClick={() => {
              eventLogger.logCustomEvent(location, "at.events.click.export.save.tabelPdf")
              openPdf(
                HandlingsplanTabell({ bevaringspunkter, forbedringspunkter, notice, subjects }),
                "export-table-pdf",
                "Arbeidsmiljøhjelpen_handlingsplan_tabell.pdf"
              )
            }}
          >
            Lagre som PDF
          </Button.Export>
        </div>
      </div>
    </>
  )
}

SaveDialogNew.propTypes = {
  forbedringspunkter: PropTypes.array.isRequired,
  bevaringspunkter: PropTypes.array.isRequired,

  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  industry: IndustryType.isRequired,
  premadeForbedringspunkter: PropTypes.arrayOf(PropTypes.object),
  notice: PropTypes.shape({ title: PropTypes.string, content: PropTypes.string }),
  texts: PropTypes.shape({
    popupTitle: PropTypes.string.isRequired,
    popupDescription: PropTypes.arrayOf(PropTypes.object),
    popupLink: PropTypes.string.isRequired,
    popupLinkDescription: PropTypes.string.isRequired,
    forbedringspunkt_title: PropTypes.string.isRequired,
    forbedringspunkt_subjectImportance: PropTypes.string.isRequired,
    forbedringspunkt_priorityLabel: PropTypes.string.isRequired,
    forbedringspunkt_statusLabel: PropTypes.string.isRequired,
    forbedringspunkt_deadlineLabel: PropTypes.string.isRequired,
    forbedringspunkt_responsibleLabel: PropTypes.string.isRequired,
    forbedringspunkt_descriptionLabel: PropTypes.string.isRequired,
    forbedringspunkt_doneLabel: PropTypes.string.isRequired,
    forbedringspunkt_doneDateLabel: PropTypes.string.isRequired,
  }).isRequired,
}

SaveDialogNew.defaultProps = {
  premadeForbedringspunkter: null,
  notice: null,
}

export default SaveDialogNew
