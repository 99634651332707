/* eslint-disable react/prop-types */
import React from "react"
import { View, Text, Image } from "@react-pdf/renderer"
import styles from "./styles"

const Wrapper = ({ subject, children, style = {} }) => (
  <View>
    <Header subject={subject} />
    <View style={[styles.content, style]}>{children}</View>
  </View>
)

const Header = ({ subject }) => (
  <View fixed style={styles.header}>
    <Text style={styles.headerLeft}>{`Tema: ${subject.title}`}</Text>
    <Text fixed render={({ pageNumber, totalPages }) => `Side ${pageNumber} av ${totalPages}`} />
  </View>
)

const Footer = ({ leftText = " " }) => (
  <View fixed style={styles.footer}>
    <Text style={{ marginLeft: 5, marginTop: 3 }}>{leftText}</Text>
    <Text style={styles.rightFooter}>www.arbeidsmiljøhjelpen.no</Text>
  </View>
)

const TextHeader = ({ text, headerlevel = "h3" }) => <Text style={styles[headerlevel]}>{text}</Text>

const Intro = ({ ingress }) => (
  <View style={styles.intro}>
    <Text style={{ marginBottom: 10 }}>{ingress} </Text>
  </View>
)
const Hero = ({ text, color = "bla" }) => (
  <View style={styles.hero}>
    <View style={styles.heroText}>
      <Text style={{ marginBottom: 10 }}>{text}</Text>
    </View>
    <Image style={styles.heroImage} src={`/arbeidsmiljohjelpen_${color}.png`} />
  </View>
)

const Notice = ({ title, content }) => (
  <View style={styles.notice}>
    <Text style={styles.h3}>{title}</Text>
    <Text>{content}</Text>
  </View>
)

const TitleAndText = ({ style, title, text }) => (
  <View style={[style, styles.titleAndText]}>
    <Text style={styles.h3}>{title}</Text>
    <Text>{text}</Text>
  </View>
)

const Bevaringspunkter = ({ smallTitle, title, bevaringspunkter }) => (
  <View style={styles.box} wrap={false}>
    <BoxHeader smallTitle={smallTitle} bigTitle={title} />
    {bevaringspunkter.map(b => (
      <View key={`bevaringspunkt-${b.id}`} style={styles.bulletItem}>
        <Image style={styles.bullet} src="/circle-full-black.png" />
        <Text>{b.title.text}</Text>
      </View>
    ))}
  </View>
)

const Forbedringspunkter = ({ forbedringspunkter, ingress }) => (
  <View style={styles.forbedringspunkter}>
    <Text style={{ fontSize: 14, marginBottom: 30 }}>{ingress}</Text>
    {forbedringspunkter.map(f => (
      <View key={`forbedringspunkt-${f}`} style={styles.bulletItem}>
        <Image style={styles.bigBullet} src="/circle-empty-yellow.png" />
        <Text>{f}</Text>
      </View>
    ))}
  </View>
)

const Pastander = ({ title, pastander }) => (
  <View>
    <Text style={styles.h3}>{title}</Text>
    {pastander.map(p => (
      <View key={`pastand-${p._id}`} style={styles.bulletItem}>
        <Image style={styles.bigBullet} src="/circle-empty-yellow.png" />
        <Text>{p.title}</Text>
      </View>
    ))}
  </View>
)

const BlockContent = ({ blocks = [] }) => (
  <View style={{ paddingRight: 20, lineHeight: 2 }}>
    {blocks
      // loop through each block
      .map(block => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== "block" || !block.children) {
          return ""
        }

        let style = block.style ? styles[block.style] : {}
        const bullet =
          block.listItem && block.listItem === "bullet" ? (
            <Image style={styles.bullet} src="/circle-full-black.png" />
          ) : null

        if (bullet) {
          style = styles.bulletItem
        }
        // loop through the children spans, and join the
        // text strings

        return (
          <View style={style} key={block._key}>
            {bullet}
            <View style={bullet ? { marginBottom: 10 } : null}>
              <Text>{block.children.map(child => child.text).join("")}</Text>
            </View>
          </View>
        )
      })}
  </View>
)

const BoxHeader = ({ smallTitle, bigTitle }) => (
  <View style={styles.boxHeader}>
    <View>
      <Text style={styles.h2}>{smallTitle}</Text>
      <Text style={styles.h1}>{bigTitle}</Text>
    </View>
  </View>
)

const Dot = ({ color = "primary", small = false }) => (
  <View style={[styles.dot, styles[`${color}BgColor`], small ? styles.smallDot : ``]} />
)
const Lines = ({ color = "primary" }) => (
  <View>
    <Dot color={color} />
    <View style={styles.hr} />
    <View style={styles.hr} />
    <Dot color={color} />
    <View style={styles.hr} />
    <View style={styles.hr} />
    <View style={styles.hr} />
    <Dot color={color} />
    <View style={styles.hr} />
    <View style={styles.hr} />
    <View style={styles.hr} />
    <Dot color={color} />
    <View style={styles.hr} />
    <View style={styles.hr} />
    <View style={styles.hr} />
  </View>
)

const DiskusjonTips = () => (
  <View style={styles.diskusjonTips}>
    <View>
      <Text style={styles.h2}>Tips til gjennomføring</Text>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>
        <Text style={styles.bold}>Velg en gruppeleder </Text>
        <Text>som noterer underveis</Text>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Bli enige om hvordan dere skal bruke tiden</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 3 }}>
          <Text>Dere kan ...</Text>
          <Text>- diskutere alle påstandene</Text>
          <Text>
            - velge ut de påstandene som er mest relevante for dere, og hoppe over dem som ikke er
            relevante
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Skap gode diskusjoner</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 3 }}>
          <Text>
            Lytt til hverandre, og la alle komme til orde og si sine meninger. Ha respekt for at
            andre kan ha andre meninger og tanker enn deg.
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Vær konkret og bruk eksempler</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 3 }}>
          <Text>
            Vær så konkret som mulig når dere diskuterer, og kom gjerne med eksempler fra egen
            arbeidshverdag.
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Snakk om det dere kan gjøre noe med</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 3 }}>
          <Text>Ikke bruk for mye tid på ting det ikke er mulig å gjøre noe med.</Text>
        </View>
      </View>
    </View>
  </View>
)
const VelgTips = () => (
  <View style={styles.diskusjonTips}>
    <View>
      <Text style={styles.h2}>Tips til gjennomføring</Text>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>
        <Text style={styles.bold}>Ikke gap over for mye</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 1, marginBottom: 5 }}>
          <Text>Velg de forbedringsområdene some er viktigst for dere å jobbe med.</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Prioriter det dere kan gjøre noe med</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 1, marginBottom: 5 }}>
          <Text>
            Ikke bruk for mye tid opå ting det ikke er mulig å gjøre noe med. Ta heller temaet
            videre til lederen som kan ta det videre til riktig nivå.
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Vær konkret</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 1, marginBottom: 5 }}>
          <Text>
            Vær så konkrete som mulig når dere lager bevaringspunkter og forbedringspunkter.
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Noter forslag til tiltak</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 1, marginBottom: 5 }}>
          <Text>
            Noter eventueller forslag til hvordan dere kan jobbe med forbedringsforslagene i
            praksis. Dere vet hvor skoen trykker og har kanskje gode forslag til hvordan det kan
            gjøres bedre.Dette er nyttig når det i neste steg skal lages tiltak.
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Ta vare på det som fungerer godt</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 1, marginBottom: 5 }}>
          <Text>
            Det kan ofte være lettere å finne det som ikke fungerer enn det som fungerer godt, men
            for å ivareta et godt arbeidsmiljø er det også viktig å være beviss på det dere vil
            bevare.
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Ta vare på det som fungerer godt</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 1, marginBottom: 5 }}>
          <Text>
            Det kan ofte være lettere å finne det som ikke fungerer enn det som fungerer godt, men
            for å ivareta et godt arbeidsmiljø er det også viktig å være beviss på det dere vil
            bevare.
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={{ paddingLeft: 30, marginTop: 5 }}>
          <Dot color="black" small />
        </View>{" "}
        <Text style={styles.bold}>Eksempler på bevaringspunkter</Text>
      </View>
      <View style={styles.indent}>
        <View style={{ paddingLeft: 10, marginTop: 1, marginBottom: 5 }}>
          <Text>- Vi er flinke til å rose hverandre.</Text>
          <Text>- Vi er gode på å hjelpe hverandre på tvers av avdelingene.</Text>
          <Text>
            - Når vi planlegger arbeidet, sørger vi for å legge inn pauser og ha en rulleringsplan.
            Det sikrer variasjon.
          </Text>
        </View>
      </View>
    </View>
  </View>
)
const ProcessImage = () => <Image style={{ width: "100%" }} src="/process.png" />

export {
  styles,
  Header,
  Footer,
  Intro,
  TitleAndText,
  Bevaringspunkter,
  Forbedringspunkter,
  BoxHeader,
  Notice,
  BlockContent,
  Lines,
  TextHeader,
  Pastander,
  Wrapper,
  Hero,
  DiskusjonTips,
  ProcessImage,
  VelgTips,
}
