import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import ReactExport from "react-data-export"
import { SubjectType } from "../../../../propTypes"
import { getBevaringspunkter, getForbedringspunkter } from "./_utils"
import { getDateString } from "../../../../utils"
import {
  FORBEDRINGSPUNKT_PRIORITY as PRIORITIES,
  FORBEDRINGSPUNKT_STATUS as STATUS,
} from "../../../../constants"
import variables from "../../../../styles/variables.module.scss"

const { ExcelFile } = ReactExport
const { ExcelSheet } = ReactExport.ExcelFile

const getExcelHexColor = hex => {
  // Strip out the '#'
  let color = hex.substring(1)

  // Since sass compresses hex colors into 3-digits if possible, we need to expand them to use them in Excel
  // Sass compresses #ffdd88 into #fd8, but we need to revert this into #ffdd88 again
  if (color.length === 3) {
    color = color
      .split("")
      .map(c => c + c)
      .join("")
  }
  return `FF${color}`
}

const headerCellStyle = {
  font: { sz: 11, bold: true },
  border: {
    bottom: { style: "thin", color: { rgb: "FF000000" } },
  },
  fill: { patternType: "solid", fgColor: { rgb: getExcelHexColor(variables.headerCellBgColor) } },
}

const getPriority = pri => {
  let color = ""
  switch (pri) {
    case 1:
      color = getExcelHexColor(variables.lowPriorityColor)
      break
    case 2:
      color = getExcelHexColor(variables.mediumPriorityColor)
      break
    case 3:
      color = getExcelHexColor(variables.highPriorityColor)
      break
    default:
      color = "FFFFFFFF"
      break
  }
  return {
    value: Object.values(PRIORITIES).filter(item => item.ID === pri)[0].TEXT,
    style: { fill: { patternType: "solid", fgColor: { rgb: color } } },
  }
}

const getStatus = status => {
  return { value: Object.values(STATUS).filter(item => item.ID === status)[0].TEXT }
}

const ExcelButton = ({ fileName, industryId, forbedringspunkterPremade, subjects, children }) => {
  const bevaringspunkterRaw = useSelector(s => s.bevaringspunkter)
  const forbedringspunkterRaw = useSelector(s => s.forbedringspunkter)

  const bevaringspunkter = getBevaringspunkter(industryId, -1, bevaringspunkterRaw).filter(
    b => b.isChecked
  )

  const forbedringspunkter = getForbedringspunkter(
    forbedringspunkterRaw,
    forbedringspunkterPremade,
    subjects,
    industryId
  )

  const getBevaringspunkterSheet = () => {
    const dataSet = [
      {
        columns: [
          {
            title: "Nr",
            width: { wpx: 40 },
            style: headerCellStyle,
          },
          {
            title: "Bevaringspunkt",
            width: { wpx: 600 },
            style: headerCellStyle,
          },
        ],
        data: bevaringspunkter.map((b, index) => {
          return [
            { value: index + 1, style: { alignment: { horizontal: "left" } } },
            { value: b.title.text, style: { alignment: { wrapText: true } } },
          ]
        }),
      },
    ]
    return <ExcelSheet dataSet={dataSet} name="Bevaringspunkter" />
  }

  const getForbedringspunkterSheet = () => {
    const rows = []
    const subjectsWithForbedringspunkter = subjects.map(s => ({
      [s._id]: forbedringspunkter.filter(fb => fb.subjectId === s._id),
    }))

    subjectsWithForbedringspunkter.forEach(f => {
      const vals = Object.values(f)[0]
      const key = Object.keys(f)[0]
      if (vals.length > 0) {
        const subject = subjects.find(s => s._id === key)
        const subjectContent = [{ value: subject.title }]
        vals.forEach(val => {
          const row = subjectContent.concat([
            { value: val.title.text, style: { alignment: { wrapText: true } } },
            getPriority(val.priority),
            getStatus(val.status),
            {
              value: val.deadline === -1 ? "Ikke satt" : getDateString(val.deadline),
              style: val.deadline === -1 ? null : { numFmt: "dd/mm/yyyy" },
            },
            { value: val.responsible },
            { value: val.description, style: { alignment: { wrapText: true } } },
            { value: "" },
          ])
          rows.push(row)
        })
      }
    })

    const dataSet = [
      {
        columns: [
          {
            title: "Tema",
            width: { wpx: 160 },
            style: headerCellStyle,
          },
          {
            title: "Forbedringspunkt",
            width: { wpx: 400 },
            style: headerCellStyle,
          },
          {
            title: "Prioritet",
            width: { wpx: 80 },
            style: headerCellStyle,
          },
          {
            title: "Status",
            width: { wpx: 80 },
            style: headerCellStyle,
          },
          {
            title: "Tidsfrist",
            width: { wpx: 80 },
            style: headerCellStyle,
          },
          {
            title: "Ansvarlig",
            width: { wpx: 80 },
            style: headerCellStyle,
          },
          {
            title: "Beskrivelse",
            width: { wpx: 400 },
            style: headerCellStyle,
          },
          {
            title: "Utført dato",
            width: { wpx: 80 },
            style: headerCellStyle,
          },
        ],
        data: rows,
      },
    ]

    return <ExcelSheet dataSet={dataSet} name="Forbedringspunkter" />
  }
  const getButton = () => {
    return <div>{children}</div>
  }

  return (
    <ExcelFile filename={fileName} element={getButton()}>
      {getBevaringspunkterSheet()}
      {getForbedringspunkterSheet()}
    </ExcelFile>
  )
}

ExcelButton.propTypes = {
  fileName: PropTypes.string,
  children: PropTypes.string.isRequired,
  industryId: PropTypes.string.isRequired,
  forbedringspunkterPremade: PropTypes.arrayOf(PropTypes.object),
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
}

ExcelButton.defaultProps = {
  fileName: "Arbeidsmiljohjelpen-handlingsplan",
  forbedringspunkterPremade: null,
}

export default ExcelButton
