/* eslint-disable react/prop-types */
import React from "react"
import { View, Text, Image } from "@react-pdf/renderer"
import { getDateStringFormat } from "../../../../../utils/timeAndDateAltFormat"
import styles from "./styles"

// TODO: The two below should come from an enum in constants/index.js
const PRIORITY = {
  "-1": "Ikke satt",
  1: "Lav",
  2: "Middels",
  3: "Høy",
}

const Dot = ({ color = "primary", small = false }) => (
  <View style={[styles.dot, styles[`${color}BgColor`], small ? styles.smallDot : ""]} />
)

const Header = () => (
  <View fixed style={styles.header}>
    <Text>Handlingsplan - {getDateStringFormat()}</Text>
    <Text fixed render={({ pageNumber, totalPages }) => `Side ${pageNumber} av ${totalPages}`} />
  </View>
)

const Footer = () => (
  <View fixed style={styles.footer}>
    <Text>www.arbeidsmiljøhjelpen.no</Text>
  </View>
)

const Intro = () => (
  <View>
    <Text style={[styles.h1, styles.pageTitle]}>Handlingsplan - {getDateStringFormat()}</Text>
    <View style={styles.introContent}>
      <View style={styles.introText}>
        <View>
          <Text style={{ marginBottom: 13 }}>
            Denne handlingsplanen viser hva vi skal jobbe med framover for å forbedre arbeidsmiljøet
            vårt.
          </Text>
          <Text>
            For hvert forbedringsområde vi har valgt, har vi skrevet inn hvilke tiltak vi skal
            gjennomføre, hvem som er ansvarlig, prioritet og frist for oppfølging.
          </Text>
        </View>
      </View>
      <Image style={styles.introImage} src="/arbeidsmiljohjelpen_gul.png" />
    </View>
  </View>
)

const SubjectOverviewCompleted = ({ allSubjects, subjects }) => (
  <View style={{ paddingBottom: "8px" }}>
    <Text style={[styles.italic, { fontSize: 10, paddingBottom: "4px" }]}>
      Temaene vi har gjennomført:
    </Text>
    <View style={styles.subjectsOverviewFlex}>
      {subjects.map(subject => {
        const isIncluded = allSubjects.some(s => s.subjectId === subject._id)

        if (isIncluded) {
          return (
            <View style={[styles.subject, styles.subjectCompleted]}>
              <View style={styles.imageContainer}>
                <Image src="/checkmark-green.png" />
              </View>
              <Text>{subject.title}</Text>
            </View>
          )
        }
        return (
          <View style={[styles.subject]}>
            <Text>{subject.title}</Text>
          </View>
        )
      })}
    </View>
  </View>
)

// TODO: This was added last minute before Prod, so this should be fetched from Sanity
const IntroInfo = () => (
  <View style={[styles.notice]}>
    <View style={[styles.noticePunkter]}>
      <View style={[styles.row, styles.infoPadding]}>
        <View style={{ marginTop: 5 }}>
          <Dot color="black" small />
        </View>
        {/* DETTE SKAL INN I SANITY */}
        <Text style={{ marginBottom: 20 }}>
          <Text style={styles.boldItalic}>Lovpålagt tilknytning til BHT. </Text>
          <Text style={styles.italic}>
            Dersom risikoforholdene tilsier det, må arbeidsgiver knytte virksomheten til en godkjent
            bedriftshelsetjeneste (BHT). BHT skal blant annet hjelpe oss å følge opp arbeidsmiljøet
            i virksomheten og arbeidshelsen til de ansatte.
          </Text>
        </Text>
      </View>
      <View style={[styles.row, styles.infoPadding]}>
        <View style={{ marginTop: 5 }}>
          <Dot color="black" small />
        </View>
        <Text>
          <Text style={styles.boldItalic}>Arbeidsmiljø er et felles ansvar. </Text>
          <Text style={styles.italic}>
            Leder er ansvarlig for at arbeidsmiljøet på arbeidsplassen er forsvarlig, men alle
            ansatte har plikt og rett til å medvirke for å skape et godt og sikkert arbeidsmiljø.
            Arbeidsmiljø er derfor et felles ansvar, og alle sitt bidrag er viktig for å lykkes med
            oppfølgingen av tiltakene
          </Text>
        </Text>
      </View>
    </View>
    <Image style={styles.processImage} src="/process.png" />
  </View>
)

const Notice = ({ title, content }) => (
  <View style={styles.notice}>
    <Text style={styles.h3}>{title}</Text>
    <Text>{content}</Text>
  </View>
)

const TitleAndText = ({ style, title, text }) => (
  <View style={[style, styles.titleAndText]}>
    <Text style={styles.h3}>{title}</Text>
    <Text>{text}</Text>
  </View>
)
const temaTitle = title => {
  const test = title.charAt(0).toUpperCase() + title.slice(1)
  return `Tema: ${test}`
}

const Forbedringspunkt = ({ forbedringspunkt }) => (
  <View style={{ paddingTop: 20 }} wrap={false}>
    <View style={styles.forbedringspunkt}>
      <View style={styles.forbedringspunktHeader}>
        <Text>{forbedringspunkt.title.text}</Text>
      </View>

      <View style={styles.forbedringspunktContent}>
        <View style={{ flexBasis: "20%" }}>
          <View style={styles.forbedringspunktCheckPoint}>
            <Text style={[styles.forbedringspunktContentTitle]}>Neste sjekk:</Text>
            <Text style={[styles.forbedringspunktContentTitleContent]}>
              {forbedringspunkt.deadline === -1
                ? "Ikke satt"
                : getDateStringFormat(forbedringspunkt.deadline)}
            </Text>
          </View>

          <View style={styles.forbedringspunktCheckPoint}>
            <Text style={[styles.forbedringspunktContentTitle]}>Prioritet:</Text>
            <Text
              style={[
                styles.forbedringspunktContentTitleContent,
                styles.forbedringspunktContentTitleContentPriority,
                forbedringspunkt.priority === 1 && styles.priorityLow,
                forbedringspunkt.priority === 2 && styles.priorityMedium,
                forbedringspunkt.priority === 3 && styles.priorityHigh,
                { textAlign: "center" },
              ]}
            >
              {PRIORITY[forbedringspunkt.priority]}
            </Text>
          </View>

          <View>
            <Text style={[styles.forbedringspunktContentTitle]}>Ansvarlig hos oss:</Text>
            <Text style={[styles.forbedringspunktContentTitleContent]}>
              {forbedringspunkt.responsible ? forbedringspunkt.responsible : "Ikke satt"}
            </Text>
          </View>
        </View>

        <View style={{ flexBasis: "80%" }}>
          <View style={[styles.titleAndText]}>
            <Text
              style={
                (styles.forbedringspunktContentTitle,
                { fontWeight: 700, paddingBottom: "5px", fontSize: 8 })
              }
            >
              Hvordan skal vi løse dette?
            </Text>
            <Text style={{ lineHeight: 1.7 }}>{forbedringspunkt.description}</Text>
          </View>
        </View>
      </View>
    </View>
  </View>
)

const allSubjects = ({ bevaringspunkter, forbedringspunkter }) => [
  ...bevaringspunkter,
  ...forbedringspunkter,
]

const CompletedSubject = ({ subjects }) => (
  <View>
    {subjects.map(subject => {
      const isIncluded = allSubjects.some(s => s.subjectId === subject._id)

      if (isIncluded) {
        return <Text>{subjects.title} - checked</Text>
      }
      return <Text>{subject.title}</Text>
    })}
  </View>
)

const Bevaringspunkt = ({ bevaringspunkt }) => (
  <View wrap={false}>
    <View style={styles.bulletItem}>
      <Image style={styles.bullet} src="/circle-full-primary.png" />
      <Text style={styles.bevaringspunktText}>{bevaringspunkt.title.text}</Text>
    </View>
  </View>
)

const Subject = ({ subjectTitle, forbedringspunkter, bevaringspunkter, subjectId }) => (
  <View style={styles.contentPage} wrap>
    <View style={{ paddingBottom: 20 }}>
      <Text style={[styles.h1, styles.bold]}>{temaTitle(subjectTitle)}</Text>
    </View>
    <View>
      <Text style={[styles.h2, styles.bold, { paddingBottom: 20 }]}>Bevaringspunkter</Text>

      {bevaringspunkter.map(b => {
        if (b.subjectId === subjectId) {
          return <Bevaringspunkt key={`forbedringspunkt-item-${b.id}`} bevaringspunkt={b} />
        }
        return null
      })}
    </View>

    <View wrap>
      <Text style={[styles.h2, styles.bold]}>Forbedringspunkter</Text>
      {forbedringspunkter.map(f => {
        if (f.subjectId === subjectId) {
          return <Forbedringspunkt key={`forbedringspunkt-item-${f.id}`} forbedringspunkt={f} />
        }
        return null
      })}
    </View>
  </View>
)

export {
  styles,
  Header,
  Footer,
  Intro,
  IntroInfo,
  TitleAndText,
  Bevaringspunkt,
  Forbedringspunkt,
  Subject,
  Notice,
  CompletedSubject,
  SubjectOverviewCompleted,
}
