import React, { useState, useEffect } from "react"
import ReactGA from "react-ga"
import classNames from "classnames"
import styles from "./style.module.scss"

import {
  BackLink,
  SEO,
  Title,
  Loading,
  Progress,
  Accordion,
  ForwardLink,
} from "../../../components"

import { withDefaults } from "../../../utils"

import { IndustryType, SubjectType } from "../../../propTypes"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { Section } from "../../../state/levelContext"
import Bevaringspunkter from "../../../components/bevaringspunkter"
import Forbedringspunkter from "../../../components/forbedringspunkter"
import LinkWrapper from "../../../components/linkWrapper"

// import { loadState } from "../../../state/localStorage"

// DENNE MÅ ENDRE NAVN. DENNE INNEHOLDER NÅ BEVARINGSPUNKTER OG FORBEDRINGSPUNKTER

const Punkter = ({ industry, subject }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      bevaringspunkterType: "hjelpen_bevaringspunkterPage",
      forbedringspunkterType: "hjelpen_forbedringspunkterPage",
      statusViserDataType: "hjelpen_statusViser",
      type: "hjelpen_bevareForbedrePage",
      industryTag: industry.tag,
      subjectTag: subject.tag,
    })
  }, [industry, subject])

  const { loading, error, data } = useSanity(
    `{
      "bevareOgForbedre": *[_type==$type &&
      industry->tag in [$industryTag, "default"] &&
      count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          tag,
          title,
        },
        subject-> {
          tag,
          title,
        },
      },
      "statusviser": *[_type==$statusViserDataType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0]  {
          ...,
          industry-> {
            tag,
            title,
          },
        },
      "bevaringspunkter" : *[_type==$bevaringspunkterType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
          ...,
          industry-> {
            tag,
            title,
          },
          premadeIngress[] ${blockContentGroq},
          "formulationItemsRefs": coalesce(formulationItemsRef[]-> { ...,}, []),
          userIngress[] ${blockContentGroq},
        },
        "forbedringspunkter":
          *[_type==$forbedringspunkterType &&
            industry->tag in [$industryTag, "default"] &&
            subject->tag == $subjectTag &&
            count(*[_id=="drafts."+^._id])==0] {
              ...,
              industry-> {
                tag,
                title,
              },
              subject-> {
                tag,
                title,
              },
              premadeIngress[] ${blockContentGroq},
              "formulationItemsRefs": coalesce(formulationItemsRef[]-> { ...,}, []),
              userIngress[] ${blockContentGroq},
            }
      }`,
    params
  )

  ReactGA.initialize("UA-156102109-1")

  if (loading || error) return <Loading error={error} />

  const bevaringspunkterPage = withDefaults(data.bevaringspunkter, industry.tag)
  const forbedringspunkterPage = withDefaults(data.forbedringspunkter, industry.tag)
  const page = withDefaults(data.bevareOgForbedre, industry.tag)
  const statusLabels = withDefaults(data.statusviser, industry.tag)

  return (
    <>
      <SEO title="Velg punkter" />
      {/* <SecondaryMenu industry={industry} subjects={subjects} subject={subject} /> */}
      <div className="content">
        <div className={classNames("desktop-col-12", styles.introHero)}>
          <Section>
            <Progress activeStep={2} subject={subject} industry={industry} labels={statusLabels} />
            {page.smallTitle && <Title.Subject title={page.smallTitle} subject={subject} />}
          </Section>
        </div>
        {page.tipsTitle && (
          <Section>
            <div className="desktop-col-8">
              <Accordion.Default
                style={{ margin: 0, paddingBottom: 20 }}
                items={[
                  {
                    _key: "tips",
                    title: page.tipsTitle,
                    content: page.tipsContent,
                  },
                ]}
                portableContent
              />
            </div>
          </Section>
        )}
        <Section>
          <div className="desktop-col-12" style={{ paddingBottom: 20 }}>
            {/* Sende inn bevaringspunkter her */}
            <Bevaringspunkter industry={industry} subject={subject} page={bevaringspunkterPage} />
          </div>

          <div className="desktop-col-12">
            {/* Sende inn forbedringspunkter her */}
            <Forbedringspunkter
              industry={industry}
              subject={subject}
              page={forbedringspunkterPage}
            />
          </div>
        </Section>
        <LinkWrapper>
          <BackLink to={`/bransje/${industry.route}/${subject.route}`}>
            {page.previousPageButtonText}
          </BackLink>
          <ForwardLink to={`/bransje/${industry.route}/${subject.route}/handlingsplan`}>
            {page.nextPageButtonText}
          </ForwardLink>
        </LinkWrapper>
      </div>
    </>
  )
}

Punkter.propTypes = {
  industry: IndustryType.isRequired,
  subject: SubjectType.isRequired,
}

export default withPageDependencies(Punkter)
