/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import { Document, Page, Text, View } from "@react-pdf/renderer"
import {
  Footer,
  styles,
  BoxHeader,
  BlockContent,
  Lines,
  Forbedringspunkter,
  TextHeader,
  Pastander,
  Wrapper,
  Hero,
  DiskusjonTips,
  VelgTips,
} from "./_pdfComponents"
import { SubjectType } from "../../../../propTypes"

const Subject = ({
  ingress,
  paastander,
  bevaringspunktIntro,
  forbedringspunkterPremade,
  subject,
}) => {
  // TODO: Should be moved to a util
  const forbedringspunkter = forbedringspunkterPremade
    .map(f => {
      return f.formulationItemsRefs.map(fi => fi.title)
    })
    .reduce((prev, cur) => [...prev, ...cur], [])
    .filter(f => f !== undefined)

  return (
    <Document
      title="Arbeidsmiljøhjelpen - Arbeid uten PC"
      author="Arbeidsmiljøportalen"
      subject="Handlingsplan"
      creator="Arbeidsmiljøportalen"
      producer="Arbeidsmiljøportalen"
    >
      <Page size="A4" orientation="portrait" wrap style={styles.body}>
        <Wrapper subject={subject}>
          <BoxHeader bigTitle="Steg 1 av 3: Diskuter" />
          {/* <Intro ingress={ingress} /> */}
          <Hero text={ingress} color="gul" />
          <DiskusjonTips />
        </Wrapper>
        <Footer />
      </Page>
      <Page size="A4" orientation="portrait" style={styles.body}>
        <Wrapper subject={subject}>
          <BoxHeader bigTitle="Steg 1 av 3: Diskuter" />
          <TextHeader>Diskuter om dere kjenner dere igjen i følgende påstander:</TextHeader>
          <Pastander title={paastander.pastandTitle} pastander={paastander.formulationItemsRefs} />

          {/* <BlockContent blocks={bevaringspunktIntro} />
          <View style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
            <Lines />
          </View> */}
        </Wrapper>
        <Footer />
      </Page>
      <Page size="A4" orientation="portrait" wrap style={styles.body}>
        <Wrapper subject={subject}>
          <BoxHeader bigTitle="Steg 2 av 3: Velg og legg til punkter" />
          {/* <Intro ingress={ingress} /> */}
          <Hero text={ingress} />
          <VelgTips />
        </Wrapper>
        <Footer />
      </Page>
      <Page size="A4" orientation="portrait" wrap style={styles.body}>
        <Wrapper subject={subject}>
          <BoxHeader bigTitle="Steg 2 av 3: Velg og legg til punkter" />
          <BlockContent blocks={bevaringspunktIntro} />
          <Text>
            Bruk litt tid på å finne ut hva dere er gode på i arbeidsmiljøet for dette temaet, og
            skriv ned hva dere ønsker å ha fokus på å bevare. Prøv å være så konkret som mulig.
          </Text>
          <View style={{ marginTop: 20, paddingLeft: 20 }}>
            <Lines color="primary" />
          </View>
        </Wrapper>
        <Footer />
      </Page>
      <Page size="A4" orientation="portrait" wrap style={styles.body}>
        <Wrapper subject={subject}>
          <BoxHeader bigTitle="Steg 2 av 3: Velg og legg til punkter" />
          <Forbedringspunkter
            forbedringspunkter={forbedringspunkter}
            ingress="Velg forbedringspunkter blant forslagene"
          />
        </Wrapper>
        <Footer />
      </Page>
      <Page size="A4" orientation="portrait" wrap style={styles.body}>
        <Wrapper subject={subject}>
          <BoxHeader bigTitle="Steg 2 av 3: Velg og legg til punkter" />
          <TextHeader>Diskuter om dere kjenner dere igjen i følgende påstander:</TextHeader>
          <Text>Skriv ned egne forbedringspunkter. Prøv å være så konkret som mulig.</Text>
          <View style={{ marginTop: 20, paddingLeft: 20 }}>
            <Lines color="yellow" />
          </View>
        </Wrapper>
        <Footer leftText="Steg 3 gjøres på arbeidsmiljøhjelpen.no etter gruppearbeidet" />
      </Page>
    </Document>
  )
}

// TODO: Need better proptypes
Subject.propTypes = {
  notice: PropTypes.shape({ title: PropTypes.string, content: PropTypes.string }),
  subject: SubjectType.isRequired,
  ingress: PropTypes.string,
  paastander: PropTypes.object,
  bevaringspunktIntro: PropTypes.object,
  forbedringspunkterPremade: PropTypes.object,
}

Subject.defaultProps = {
  notice: undefined,
  ingress: "",
  paastander: null,
  bevaringspunktIntro: null,
  forbedringspunkterPremade: null,
}

export default Subject
