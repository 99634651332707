import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import styles from "./style.module.scss"
import ChevronDown from "../../../../../images/icons/chevron-down.svg"
import ChevronUp from "../../../../../images/icons/chevron-up.svg"
import CheckedIcon from "../../../../../images/icons/checkbox-checked.svg"
import UnCheckedIcon from "../../../../../images/icons/checkbox-unchecked.svg"

import { SubjectType } from "../../../../../propTypes"
import { DropDown, Input, Button } from "../../../../../components"
import {
  setDirtyTitle,
  cancelEditTitle,
  updateForbedringspunkt,
} from "../../../../../state/forbedringspunkter/actions"
import Priority from "../../../../../components/priority"
import { FORBEDRINGSPUNKT_PRIORITY as PRIORITIES } from "../../../../../constants"
import { getDateString } from "../../../../../utils"
import DatePickerField from "../../../../../components/textInput/dateField"

const ForbedringspunktTable = ({
  subjects,
  editable,
  forbedringspunkter,
  texts,
  options,
  onSelectionChanged,
}) => {
  const dispatch = useDispatch()

  const [sorting, setSorting] = React.useState({})
  const [forbedringspunkterSorted, setForbedringspunkterSorted] = React.useState([])

  const sortBySubject = (a, b) => {
    const aValue = subjects.find(subject => subject._id === a.subjectId)
      ? subjects.find(subject => subject._id === a.subjectId).title
      : "Ukjent tema"
    const bValue = subjects.find(subject => subject._id === b.subjectId)
      ? subjects.find(subject => subject._id === b.subjectId).title
      : "Ukjent tema"

    if (aValue === bValue) {
      return 0
    }

    return aValue > bValue ? 1 : -1
  }

  const sortByPunkt = (a, b) => {
    if (a.title.text.toLowerCase() === b.title.text.toLowerCase()) {
      return 0
    }
    return a.title.text.toLowerCase() > b.title.text.toLowerCase() ? 1 : -1
  }

  const sortByDescription = (a, b) => {
    if (a.description.toLowerCase() === b.description.toLowerCase()) {
      return 0
    }
    return a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1
  }

  const sortByPriority = (a, b) => {
    if (a.priority === b.priority) {
      return 0
    }
    return a.priority > b.priority ? 1 : -1
  }

  const sortByResponsible = (a, b) => {
    if (a.responsible.toLowerCase() === b.responsible.toLowerCase()) {
      return 0
    }
    return a.responsible.toLowerCase() > b.responsible.toLowerCase() ? 1 : -1
  }

  const sortByDeadline = (a, b) => {
    if (a.deadline === b.deadline) {
      return 0
    }
    return a.deadline > b.deadline ? 1 : -1
  }

  const onFieldChanged = (itemId, field, value) => {
    dispatch(updateForbedringspunkt({ itemId, [field]: value }))
  }

  const constantListToDropDownOptions = constantList => {
    return Object.values(constantList).map(item => {
      return {
        value: item.ID.toString(),
        label: item.TEXT,
      }
    })
  }

  const constantIdToDropdownOption = (id, list) => {
    const item = Object.values(list).find(i => i.ID === id)
    return {
      value: item.ID.toString(),
      label: item.TEXT,
    }
  }

  // const sortColumn = (a, b, columnId) => {
  //   const aValue = a.getValue(columnId).text
  //   const bValue = b.getValue(columnId).text
  //   return aValue === bValue ? 0 : aValue > bValue ? 1 : -1
  // }

  const isSorted = column => {
    return sorting[column] ? sorting[column].direction : "none"
  }

  const sortColumn = column => {
    const currentSorting = isSorted(column)
    let direction = "none"

    if (currentSorting === "none") {
      direction = "asc"
    } else if (currentSorting === "asc") {
      direction = "desc"
    }
    setSorting({
      [column]: {
        direction,
      },
    })
  }

  const getIcon = item => {
    return item.isChecked ? (
      <img src={CheckedIcon} alt="Påslått" className={styles.checkIcon} />
    ) : (
      <img src={UnCheckedIcon} alt="Avslått" className={styles.checkIcon} />
    )
  }

  const onCheck = item => {
    const newForbedringspunkter = forbedringspunkterSorted.map(f => {
      if (f.id !== item.id) {
        return f
      }
      return { ...f, isChecked: !f.isChecked }
    })

    setForbedringspunkterSorted(newForbedringspunkter)
    onSelectionChanged(newForbedringspunkter.filter(f => f.isChecked))
  }

  const onCheckAll = all => {
    const newForbedringspunkter = forbedringspunkterSorted.map(f => {
      return { ...f, isChecked: all }
    })
    setForbedringspunkterSorted(newForbedringspunkter)
    onSelectionChanged(newForbedringspunkter.filter(f => f.isChecked))
  }

  const TableHeader = ({ column, label }) => (
    <div>
      <Button.Tertiary
        type="button"
        onClick={() => {
          sortColumn(column)
        }}
      >
        {label}
        {{
          asc: <img src={ChevronUp} alt="Sort ascending" />,
          desc: <img src={ChevronDown} alt="Sort descending" />,
        }[isSorted(column)] ?? <img src={ChevronDown} alt="Sort descending" />}
      </Button.Tertiary>
    </div>
  )
  TableHeader.propTypes = {
    column: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }
  useEffect(() => {
    const sortingObj = Object.keys(sorting)[0]
    if (sortingObj === "subject") {
      if (sorting[sortingObj].direction === "asc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortBySubject))
      } else if (sorting[sortingObj].direction === "desc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortBySubject).reverse())
      } else {
        setForbedringspunkterSorted([...forbedringspunkter])
      }
    } else if (sortingObj === "punkt") {
      if (sorting[sortingObj].direction === "asc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByPunkt))
      } else if (sorting[sortingObj].direction === "desc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByPunkt).reverse())
      } else {
        setForbedringspunkterSorted([...forbedringspunkter])
      }
    } else if (sortingObj === "description") {
      if (sorting[sortingObj].direction === "asc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByDescription))
      } else if (sorting[sortingObj].direction === "desc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByDescription).reverse())
      } else {
        setForbedringspunkterSorted([...forbedringspunkter])
      }
    } else if (sortingObj === "priority") {
      if (sorting[sortingObj].direction === "asc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByPriority))
      } else if (sorting[sortingObj].direction === "desc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByPriority).reverse())
      } else {
        setForbedringspunkterSorted([...forbedringspunkter])
      }
    } else if (sortingObj === "responsible") {
      if (sorting[sortingObj].direction === "asc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByResponsible))
      } else if (sorting[sortingObj].direction === "desc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByResponsible).reverse())
      } else {
        setForbedringspunkterSorted([...forbedringspunkter])
      }
    } else if (sortingObj === "deadline") {
      if (sorting[sortingObj].direction === "asc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByDeadline))
      } else if (sorting[sortingObj].direction === "desc") {
        setForbedringspunkterSorted([...forbedringspunkter].sort(sortByDeadline).reverse())
      } else {
        setForbedringspunkterSorted([...forbedringspunkter])
      }
    } else {
      setForbedringspunkterSorted([...forbedringspunkter])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, forbedringspunkter])

  const getDeadlineString = item => {
    return item.deadline !== -1 ? getDateString(item.deadline) : ""
  }

  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead className={styles.tableHeader}>
          <tr>
            <th style={{ width: "10%" }}>
              <TableHeader column="subject" label="Tema" />
            </th>
            <th style={{ width: "30%" }}>
              <TableHeader column="punkt" label="Punkt" />
            </th>

            {options && options.hideColumn && !options.hideColumn.description && (
              <th style={{ width: "30%" }}>
                <TableHeader column="description" label={texts.descriptionLabel} />
              </th>
            )}
            <th>
              <TableHeader column="priority" label={texts.priorityLabel} />
            </th>
            <th>
              <TableHeader column="responsible" label={texts.responsibleLabel} />
            </th>
            <th>
              <TableHeader column="deadline" label={texts.deadlineLabel} />
            </th>
            {options && options.hideColumn && !options.hideColumn.selected && (
              <th>
                <TableHeader column="selected" label="Valgt" />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {forbedringspunkterSorted.map(item => {
            return (
              <tr key={item.id} className={styles.tableRow}>
                <td key={`${item.id}-tema`}>
                  {item.subjectId !== undefined
                    ? subjects.find(subject => subject._id === item.subjectId).title
                    : "Ukjent tema"}
                </td>
                <td key={`${item.id}-punkt`}>
                  {!editable ? (
                    <span>{item.title.text}</span>
                  ) : (
                    <Input.TextField
                      name={`forbedringspunkt-${item.id}`}
                      placeholder="Nytt punkt..."
                      value={item.title.text}
                      // autoFocus={isFocused.rowIndex === index && isFocused.columnId === id}
                      onFocus={() => {
                        dispatch(setDirtyTitle(item.id, true))
                      }}
                      onBlur={() => {
                        dispatch(setDirtyTitle(item.id, false))
                      }}
                      onChange={event => {
                        dispatch(
                          updateForbedringspunkt({
                            ...item,
                            itemId: item.id,
                            title: event.target.value,
                          })
                        )
                        // setValue({ ...value, title: { ...value.title, text: event.target.value } }) //Dispatching update here make the field loose focus because of re-render
                      }}
                      onCancelEdit={() => {
                        dispatch(cancelEditTitle(item.id))
                      }}
                      style={{ marginRight: "1rem", flex: 1 }}
                    />
                  )}
                </td>

                {options && options.hideColumn && !options.hideColumn.description && (
                  <td key={`${item.id}-beskrivelse`}>
                    {!editable ? (
                      <span>{item.description} </span>
                    ) : (
                      <Input.TextField
                        name={`forbedringspunkt-description-${item.id}`}
                        placeholder="Ny beskrivelse..."
                        value={item.description}
                        // autoFocus={isFocused.rowIndex === index && isFocused.columnId === id}
                        onFocus={() => {
                          dispatch(setDirtyTitle(item.id, true))
                        }}
                        onBlur={() => {
                          dispatch(setDirtyTitle(item.id, false))
                        }}
                        onChange={event => {
                          dispatch(
                            updateForbedringspunkt({
                              ...item,
                              itemId: item.id,
                              title: item.title.text,
                              description: event.target.value,
                            })
                          )
                          // setValue({ ...value, title: { ...value.title, text: event.target.value } }) //Dispatching update here make the field loose focus because of re-render
                        }}
                        onCancelEdit={() => {
                          dispatch(cancelEditTitle(item.id))
                        }}
                        style={{ marginRight: "1rem", flex: 1 }}
                      />
                    )}
                  </td>
                )}
                <td key={`${item.id}-priority`}>
                  {!editable ? (
                    <Priority priority={item.priority} />
                  ) : (
                    <DropDown
                      name="priority"
                      options={constantListToDropDownOptions(PRIORITIES)}
                      onChange={event => onFieldChanged(item.id, "priority", parseInt(event.value))}
                      optionValue={constantIdToDropdownOption(item.priority, PRIORITIES)}
                      ariaLabel="Prioritet"
                      className={styles.dropDown}
                    />
                  )}
                </td>
                <td key={`${item.id}-responsible`}>
                  {!editable ? (
                    <span>{item.responsible}</span>
                  ) : (
                    <Input.TextField
                      name={`forbedringspunkt-responsible-${item.id}`}
                      placeholder="Legg til ansvarlig..."
                      value={item.responsible}
                      // autoFocus={isFocused.rowIndex === index && isFocused.columnId === id}
                      onFocus={() => {
                        dispatch(setDirtyTitle(item.id, true))
                      }}
                      onBlur={() => {
                        dispatch(setDirtyTitle(item.id, false))
                      }}
                      onChange={event => {
                        dispatch(
                          updateForbedringspunkt({
                            ...item,
                            itemId: item.id,
                            title: item.title.text,
                            responsible: event.target.value,
                          })
                        )
                        // setValue({ ...value, title: { ...value.title, text: event.target.value } }) //Dispatching update here make the field loose focus because of re-render
                      }}
                      onCancelEdit={() => {
                        dispatch(cancelEditTitle(item.id))
                      }}
                      style={{ marginRight: "1rem", flex: 1 }}
                    />
                  )}
                </td>
                <td key={`${item.id}-deadline`}>
                  {!editable ? (
                    <span>{getDeadlineString(item)}</span>
                  ) : (
                    <DatePickerField
                      value={item.deadline}
                      onChange={value => {
                        onFieldChanged(item.id, "deadline", value)
                      }}
                    />
                  )}
                </td>
                {options && options.hideColumn && !options.hideColumn.selected && (
                  <td key={`${item.id}-select`}>
                    <button
                      type="button"
                      className={styles.checkButton}
                      onClick={() => onCheck(item)}
                    >
                      {getIcon(item)}
                    </button>
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
      {options && options.hideColumn && !options.hideColumn.selected && (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <button type="button" className={styles.checkButton} onClick={() => onCheckAll(true)}>
            <div className="flex flex-center-vertical">
              <img src={CheckedIcon} alt="Påslått" className={styles.checkIcon} />
              <span style={{ marginLeft: 10 }}>Velg alle</span>
            </div>
          </button>
          <button type="button" className={styles.checkButton} onClick={() => onCheckAll(false)}>
            <div className="flex flex-center-vertical">
              <img src={UnCheckedIcon} alt="Avslått" className={styles.checkIcon} />
              <span style={{ marginLeft: 10 }}>Fjern alle valg</span>
            </div>
          </button>
        </div>
      )}
    </div>
  )
}

ForbedringspunktTable.propTypes = {
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  editable: PropTypes.bool,
  forbedringspunkter: PropTypes.array,
  texts: PropTypes.object,
  options: PropTypes.object,
  onSelectionChanged: PropTypes.func,
}

ForbedringspunktTable.defaultProps = {
  editable: false,
  forbedringspunkter: null,
  texts: null,
  options: { hideColumn: {} },
  onSelectionChanged: () => {},
}

export default ForbedringspunktTable
