/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import { Document, Page, View } from "@react-pdf/renderer"
import {
  Header,
  Intro,
  Subject,
  Footer,
  styles,
  IntroInfo,
  SubjectOverviewCompleted,
} from "./_pdfComponents"

const Handlingsplan = ({ bevaringspunkter, forbedringspunkter, subjects }) => {
  // const subjectsWithForbedringspunkter = subjects.map(s => ({
  //   [s._id]: forbedringspunkter.filter(f => f.subjectId === s._id),
  // }))

  // const subjectsWithBevaringspunkter = subjects.map(s => ({
  //   [s._id]: bevaringspunkter.filter(b => b.subjectId === s._id),
  // }))
  const allSubjects = [...bevaringspunkter, ...forbedringspunkter]

  const sortedSubjects = subjects.map(s => ({
    [s._id]: allSubjects.filter(subject => subject.subjectId === s._id),
  }))

  return (
    <Document
      title="Arbeidsmiljøhjelpen - Handlingsplan"
      author="Arbeidsmiljøportalen"
      subject="Handlingsplan"
      creator="Arbeidsmiljøportalen"
      producer="Arbeidsmiljøportalen"
    >
      <Page size="A4" orientation="portrait" style={styles.page}>
        <Header />
        <View style={styles.content} wrap>
          <View style={styles.introPage}>
            <Intro />
            <SubjectOverviewCompleted allSubjects={allSubjects} subjects={subjects} />
            <IntroInfo />
          </View>
        </View>
        <Footer />
      </Page>
      {sortedSubjects &&
        sortedSubjects.map(f => {
          const vals = Object.values(f)[0]
          const key = Object.keys(f)[0]
          if (vals.length > 0) {
            return (
              <Page size="A4" orientation="portrait" style={styles.page}>
                <Header />
                <View style={styles.content} wrap>
                  <Subject
                    key={`tema-item-${key}`}
                    subjectTitle={subjects.find(s => s._id === key).title}
                    subjectId={subjects.find(s => s._id === key)._id}
                    forbedringspunkter={forbedringspunkter}
                    bevaringspunkter={bevaringspunkter}
                    wrap={false}
                  />
                </View>
                <Footer />
              </Page>
            )
          }
          return null
        })}
    </Document>
  )
}

Handlingsplan.propTypes = {
  bevaringspunkter: PropTypes.array,
  forbedringspunkter: PropTypes.array,
  notice: PropTypes.shape({ title: PropTypes.string, content: PropTypes.string }),
  subjects: PropTypes.array,
}

Handlingsplan.defaultProps = {
  bevaringspunkter: null,
  forbedringspunkter: null,
  notice: undefined,
  subjects: [],
}

export default Handlingsplan
