import {
  Document,
  Tab,
  Packer,
  TabStopType,
  Header,
  Footer,
  PageNumber,
  Paragraph,
  TextRun,
  TabStopPosition,
  PageBreak,
} from "docx"
import { saveAs } from "file-saver"
import { Intro, Bulletlist, ForbedringspunktTable, BevaringspunktTable } from "./_wordComponents"
import { DefaultStyles } from "./styles"
import { getDateStringFormat } from "../../../../../../utils/timeAndDateAltFormat"

const HandlingplanWord = ({ bevaringspunkter, forbedringspunkter, subjects }) => {
  // const subjectsWithForbedringspunkter = subjects.map(s => ({
  //   [s._id]: forbedringspunkter.filter(f => f.subjectId === s._id),
  // }))

  const forbedingspunkterWithSubject = forbedringspunkter.map(forbedringspunkt => ({
    ...forbedringspunkt,
    subjectTitle: subjects.find(subject => subject._id === forbedringspunkt.subjectId),
  }))
  const bevaringspunkterWithSubject = bevaringspunkter.map(bevaringspunkt => ({
    ...bevaringspunkt,
    subjectTitle: subjects.find(subject => subject._id === bevaringspunkt.subjectId),
  }))

  // const allSubjects = [...bevaringspunkter, ...forbedringspunkter]

  // const sortedSubjects = subjects.map(s => ({
  //   [s._id]: allSubjects.filter(subject => subject.subjectId === s._id),
  // }))

  // const mapToSubject =
  //   sortedSubjects &&
  //   sortedSubjects
  //     .map(f => {
  //       const vals = Object.values(f)[0]
  //       const key = Object.keys(f)[0]
  //       const fpunkter = forbedringspunkter
  //       const bpunkter = bevaringspunkter
  //       if (vals.length > 0) {
  //         return Subject({
  //           subjectTitle: subjects.find(s => s._id === key).title,
  //           subjectId: subjects.find(s => s._id === key)._id,
  //           forbedringspunkter: fpunkter,
  //           bevaringspunkter: bpunkter,
  //         })
  //       }
  //       return null
  //     })
  //     .filter(f => f !== null)

  const document = new Document({
    styles: DefaultStyles,
    sections: [
      {
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Handlingsplan - ${getDateStringFormat(new Date())}`,
                  }),
                  new TextRun({
                    children: [
                      new Tab(),
                      "Side ",
                      PageNumber.CURRENT,
                      " av ",
                      PageNumber.TOTAL_PAGES,
                    ],
                  }),
                ],
                style: "body",
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: "end",
                children: [
                  new TextRun({
                    children: ["www.arbeidsmiljohjelpen.no"],
                    font: "Tahoma",
                  }),
                ],
              }),
            ],
          }),
        },
        properties: {},
        children: [
          ...Intro(),
          // ...Notice(notice.title, notice.content),
          ...Bulletlist(),
          new Paragraph({
            style: "Strong",
            children: [new PageBreak()],
          }),
          ...BevaringspunktTable("Bevaringspunkter", bevaringspunkterWithSubject),
          new Paragraph({
            style: "Strong",
            children: [new PageBreak()],
          }),
          ...ForbedringspunktTable({
            smallTitle: "Forbedringspunkter",
            forbedringspunkter: forbedingspunkterWithSubject,
          }),
        ],
      },
      // ...mapToSubject.map(s => ({
      //   properties: {},
      //   children: [...s],
      // })),
    ],
  })

  Packer.toBlob(document).then(blob => {
    saveAs(blob, "Arbeidsmiljøhjelpen_Handlingsplan.docx")
  })
}

export default HandlingplanWord
