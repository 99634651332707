import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { Button, BlockContent, CheckBoxItem } from ".."
import { IndustryType, SubjectType } from "../../propTypes"
import { useDispatch } from "../../utils"
import { getBevaringspunkter } from "../../templates/hjelpen/handlingsplan/sammendrag/_utils"
import {
  addBevaringspunkt,
  deleteBevaringspunkt,
  checkBevaringspunkt,
  updateBevaringspunktTitle,
  setDirtyBevaringspunktTitle,
  cancelEditBevaringspunktTitle,
} from "../../state/bevaringspunkter/actions"
import { H } from "../../state/levelContext"
import styles from "./style.module.scss"

// Should not be a component as it has sanity references.
const Bevaringspunkter = ({ industry, subject, page }) => {
  const bevaringspunkterRaw = useSelector(s => s.bevaringspunkter)
  const dispatch = useDispatch()

  const bevaringspunkter = getBevaringspunkter(industry._id, subject._id, bevaringspunkterRaw)

  const onAddBevaringspunkt = () => {
    dispatch(addBevaringspunkt(industry._id, subject._id))
  }

  const onItemChecked = bevaringspunktId => {
    dispatch(checkBevaringspunkt(bevaringspunktId))
  }

  return (
    <div className={classNames(styles.bevaringspunkter, styles.greenBackground)}>
      <fieldset>
        <legend style={{ display: "none" }}>{page.title}</legend>
        <H className="h3">{page.title}</H>
        <p className="bold-text">{page.smallTitle}</p>
        <BlockContent blocks={page.ingress || []} />

        {bevaringspunkter.map(item => {
          const checkBoxItem = {
            id: item.id,
            title: item.title.text,
            isChecked: item.isChecked,
            isNew: item.isNew,
          }
          return (
            <CheckBoxItem
              key={`bevaringspunkt-${item.id}`}
              item={checkBoxItem}
              onClick={onItemChecked}
              canEdit
              editBtnText={page.editButtonText || "Endre"}
              deleteBtnText={page.deleteButtonText}
              saveBtnText={page.saveButtonText}
              setDirtyTitle={setDirtyBevaringspunktTitle}
              cancelEditTitle={cancelEditBevaringspunktTitle}
              updateItem={updateBevaringspunktTitle}
              deleteItem={deleteBevaringspunkt}
            />
          )
        })}
      </fieldset>
      <Button.AddNew onClick={onAddBevaringspunkt} title={page.addNewButtonText} />
    </div>
  )
}

Bevaringspunkter.propTypes = {
  industry: IndustryType.isRequired,
  subject: PropTypes.objectOf(SubjectType).isRequired,
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    smallTitle: PropTypes.string.isRequired,
    ingress: PropTypes.arrayOf(PropTypes.object).isRequired,
    editButtonText: PropTypes.string.isRequired,
    deleteButtonText: PropTypes.string.isRequired,
    saveButtonText: PropTypes.string.isRequired,
    addNewButtonText: PropTypes.string.isRequired,
  }).isRequired,
}

export default Bevaringspunkter
