import { ICalendar } from "datebook"
import { addMinutes, set } from "date-fns"
import NProgress from "nprogress"
import { pdf } from "@react-pdf/renderer"

/* eslint-disable no-param-reassign */
export const getBevaringspunkter = (industryId, subjectId, bevaringspunkterRaw) => {
  return Object.values(bevaringspunkterRaw).filter(
    b => b.industryId === industryId && (b.subjectId === subjectId || subjectId === -1)
  )
}

export const getForbedringspunkter = (
  forbedringspunkterRaw,
  forbedringspunkterPremade,
  subjects,
  industryId
  // language
) => {
  const forbedringspunkter = []

  Object.values(forbedringspunkterRaw)
    .filter(f => f.isChecked && f.industryId === industryId)
    .forEach(forbedringspunkt => {
      const { subjectId } = forbedringspunkt

      // Create a new entry for this subject if we do not have it from before
      // if (!forbedringspunkter.find(s => s.subjectId === subjectId)) {
      //   // // Check if subject is done
      //   // let subjectIsDone = false
      //   // const subjectDoneEntry = Object.values(subjectsDone).find(
      //   //   s => s.subjectId === subjectId && s.industryId === industryId
      //   // )
      //   // if (subjectDoneEntry) {
      //   //   subjectIsDone = subjectDoneEntry.done
      //   // }
      //   // if (!subjectIsDone) {
      //   //   return
      //   // }

      //   // Get subject importance
      //   let importance = 0
      //   const importanceEntry = Object.values(subjectImportance).find(
      //     i => i.subjectId === subjectId && i.industryId === industryId
      //   )

      //   if (importanceEntry) {
      //     importance = importanceEntry.importance
      //   }

      //   forbedringspunkter.push({
      //     subjectId,
      //     title: subjects.find(s => s.tag === subjectId).title,
      //     importance,
      //     forbedringspunkter: [],
      //   })
      // }
      // Get premade title text
      if (!forbedringspunkt.isUserDefined) {
        const subject = subjects.find(s => s.tag === subjectId)

        const premade = forbedringspunkterPremade
          .filter(f => f.industry.tag === industryId)
          .find(f => f.subject._id === subject.tag)

        // let titleLanguage = ""
        // if (language === "no") {
        //   const title = premade.formulationItemsRefs.find(f => f._id === forbedringspunkt.dataId)
        //   titleLanguage = title ? title.title_no : ""
        // } else if (language === "en") {
        //   const title = premade.formulationItemsRefs.find(f => f._id === forbedringspunkt.dataId)
        //   titleLanguage = title ? title.title_en : ""
        // } else {
        //   const title = premade.formulationItemsRefs.find(f => f._id === forbedringspunkt.dataId)
        //   titleLanguage = title ? title.title : ""
        // }
        const formulationItem = premade?.formulationItemsRefs.find(
          f => f._id === forbedringspunkt.dataId
        )
        forbedringspunkt.title.text = formulationItem
          ? formulationItem.title
          : forbedringspunkt.title.text
      }

      forbedringspunkter.push(forbedringspunkt)
    })

  // Sort forbedringspunkter subjects based on subject importance
  // forbedringspunkter.sort((a, b) => {
  //   return b.importance - a.importance
  // })

  // Sort forbedringspunkter based on forbedringspunkt priority
  // forbedringspunkter.sort((a, b) => b.priority - a.priority)
  // }

  return forbedringspunkter
}

export const getICalendarEvent = forbedringspunkt => {
  let startDate

  if (forbedringspunkt.deadline === -1) {
    startDate = new Date()
  } else {
    startDate = set(new Date(forbedringspunkt.deadline), { hours: 9 })
  }
  return new ICalendar({
    title: "Oppfølging av tiltak: Arbeidsmiljøhjelpen",
    description: `Dette er en kalenderpåminnelse på å følge opp et tiltak fra handlingsplanen\n\nPunkt:\n${
      forbedringspunkt.title.text
    }\n\nBeskrivelse:\n${forbedringspunkt.description}\n\n${
      forbedringspunkt.responsible ? `Ansvarlig:\n${forbedringspunkt.responsible}` : ""
    }`,
    start: startDate,
    end: addMinutes(startDate, 30),
  })
}

export const getICalendarEvents = forbedringspunkter => {
  let iCalendarEvents
  forbedringspunkter
    .filter(f => f.deadline)
    .forEach(forbedringspunkt => {
      const iCalEvent = getICalendarEvent(forbedringspunkt)
      if (iCalendarEvents === undefined) {
        iCalendarEvents = iCalEvent
      } else {
        iCalendarEvents.addEvent(iCalEvent)
      }
    })

  return iCalendarEvents
}

export const openPdf = (pdfDocument, buttonName, fileName) => {
  NProgress.configure({ showSpinner: true })
  NProgress.configure({ parent: `#${buttonName}` })
  NProgress.start()

  try {
    pdf(pdfDocument)
      .toBlob()
      .then(blob => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName)
        } else {
          const blobUrl = URL.createObjectURL(blob)
          window.open(blobUrl, "_blank")
        }
      })
      .finally(NProgress.done())
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`En feil oppstod ved PDF-generering: ${err.name} ${err.message}`)
    NProgress.done()
  }
}
