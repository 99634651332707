import React, { useState, useEffect } from "react"
import { BackLink, Chapter, Loading } from "../../components"
import SEO from "../../components/SEO"
import { IndustryType } from "../../propTypes"
import { withDefaults } from "../../utils"
import withPageDependencies from "../../hoc/withPageDependencies"
import useSanity from "../../hooks/useSanity"
import { blockContentGroq } from "../../groq"

const UtvidetVeileder = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "hjelpen_utvidetVeilederPage",
      industryTag: industry.tag,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          tag
        },
        heroImage {
          ...,
          asset-> {
            url,
          },
        },
        chapterItems[] {
          ...,
          content[] ${blockContentGroq},
        },
      }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data, industry.tag)

  return (
    <>
      <SEO title={page.title} />
      <div className="content">
        <div className="desktop-col-10">
          <div className="center-text">
            <h1>{page.title}</h1>
            <h5>{page.ingress}</h5>
            <img
              style={{ marginTop: "4rem", marginBottom: "2rem" }}
              src={page.heroImage.asset.url}
              alt={page.heroImage.alt}
            />
          </div>
        </div>
      </div>

      {page.chapterItems.map(chapter => {
        return (
          <Chapter
            title={chapter.title}
            headerTag="h2"
            content={chapter.content}
            key={chapter._key}
          />
        )
      })}

      <BackLink to={`/bransje/${industry.route}`}>Tilbake</BackLink>
    </>
  )
}

UtvidetVeileder.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(UtvidetVeileder)
