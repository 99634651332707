import React, { useState } from "react"
import classNames from "classnames"
import {
  BackLink,
  Title,
  Chapter,
  DeleteDataDialog,
  CookieConsent,
  BlockContent,
  ChapterWrapping,
  Loading,
  LinkWrapper,
} from "../components"
import SEO from "../components/SEO"
import { IndustryType } from "../propTypes"
import styles from "./_personvern/style.module.scss"
import withPageDependencies from "../hoc/withPageDependencies"
import useSanity from "../hooks/useSanity"
import { blockContentGroq } from "../groq"
import { H, Section } from "../state/levelContext"

const Personvern = ({ industry }) => {
  const [params] = useState({ deleteDataModuleId: "deleteDataModule", privacyId: "privacyPage" })
  const { loading, error, data } = useSanity(
    `{
    "deleteDataModule": *[_id==$deleteDataModuleId || _id=="drafts."+$deleteDataModuleId] {
      ...,
      description[] ${blockContentGroq},
    } | order(_updatedAt desc) [0],
    "privacyData": *[_id==$privacyId || _id=="drafts."+$privacyId] {
      ...,
      privacyItems[] ${blockContentGroq},
      cookies[] ${blockContentGroq},
    } | order(_updatedAt desc) [0]
  }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const iaDomain = typeof window === "undefined" ? "" : window.location.hostname

  const cookiesList = data.privacyData.cookies.map(cookie => {
    return (
      <div key={cookie.name} className={classNames(styles.cookie)}>
        <div className="desktop-col-2 mobile-col-12">
          <H className={classNames(styles.cookieTitle, "h3")}>{cookie.name}</H>
        </div>
        <div className="desktop-col-7 mobile-col-12">
          <BlockContent blocks={cookie.purpose || []} />
          <p>
            <strong>Domene: </strong>
            {cookie.domain === "#iaDomene" ? iaDomain : cookie.domain}
            <br />
            <strong>Type: </strong>
            {cookie.cookieType}
            <br />
            <strong>Lagringstid: </strong>
            {cookie.storageTime}
            <br />
            <strong>Eier: </strong>
            {cookie.owner}
            <br />
            <strong>Kategori: </strong>
            {cookie.category}
            <br />
          </p>
        </div>
      </div>
    )
  })

  const article = data.privacyData.privacyItems.map(item => {
    if (item._type === "cookieSettings") {
      return (
        <ChapterWrapping key={item.name}>
          <CookieConsent.Small />
        </ChapterWrapping>
      )
    }
    if (item._type === "deleteStorage") {
      return (
        <ChapterWrapping key={item.name}>
          <DeleteDataDialog small text={data.deleteDataModule} />
        </ChapterWrapping>
      )
    }
    if (item._type === "chapter") {
      return (
        <Chapter
          headerTag="h2"
          key={`personvern-${item.title}`}
          title={item.title}
          content={item.content}
        />
      )
    }
    return undefined
  })

  return (
    <>
      <SEO title={data.privacyData.title} />
      <Title.Default
        title={data.privacyData.title}
        description={data.privacyData.ingress}
        centerText={false}
      />
      <div className="content">
        <Section>
          <div className="desktop-col-9">{article}</div>
          <Section>{cookiesList}</Section>
        </Section>
        <LinkWrapper>
          <BackLink to={industry ? `/bransje/${industry.route}` : "/"} />
        </LinkWrapper>
      </div>
    </>
  )
}

Personvern.propTypes = {
  industry: IndustryType,
}

Personvern.defaultProps = {
  industry: null,
}

export default withPageDependencies(Personvern)
