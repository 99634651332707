import React, { useState } from "react"
import PropTypes from "prop-types"
import SEO from "../../components/SEO"
import { Link, FindIndustryFullWidth, Hero, Loading } from "../../components"
import { IndustryType } from "../../propTypes"
import withPageDependencies from "../../hoc/withPageDependencies"
import useSanity from "../../hooks/useSanity"
import { blockContentGroq } from "../../groq"

const LandingssideMedVelger = ({ industries }) => {
  const [params] = useState({ id: "hjelpenLandingPage" })

  const {
    loading,
    error,
    data: page,
  } = useSanity(
    `*[_id==$id || _id=="drafts."+$id] {
        ...,
        ingress[] ${blockContentGroq},
        heroImage {
          ...,
          asset-> {
            url
          }
        }
      } | order(_updatedAt desc) [0] `,
    params
  )

  if (loading || error) return <Loading error={error} />

  return (
    <>
      <SEO />
      <Hero
        title={page.title}
        content={page.ingress}
        portableContent
        image={page.heroImage.asset.url}
        altImageText={page.heroImage.alt}
        imageRightSide
      />

      <FindIndustryFullWidth
        title={page.findIndustryTitle}
        text={page.findIndustryIngress}
        postFix="/verktoy/arbeidsmiljohjelpen"
        industries={industries.filter(a => a.tools.includes("hjelpen"))}
      />

      <div className="content">
        <div className="desktop-col-12">
          <Link.Big url="/" arrow="left">
            Til Arbeidsmiljøportalen
          </Link.Big>
        </div>
      </div>
    </>
  )
}

LandingssideMedVelger.propTypes = {
  industries: PropTypes.arrayOf(IndustryType).isRequired,
}

export default withPageDependencies(LandingssideMedVelger)
