import {
  Document,
  Packer,
  Header,
  Footer,
  PageNumber,
  PageBreak,
  Paragraph,
  TextRun,
  Tab,
  TabStopType,
  TabStopPosition,
} from "docx"
import { saveAs } from "file-saver"
import { Intro, Bulletlist, BevaringspunktTable, ForbedringspunktTable } from "./_wordComponents"
import { getDateStringFormat } from "../../../../../../utils/timeAndDateAltFormat"
import { DefaultStyles } from "./styles"

const HandlingplanTable = ({ bevaringspunkter, forbedringspunkter, subjects }) => {
  const forbedingspunkterWithSubject = forbedringspunkter.map(forbedringspunkt => ({
    ...forbedringspunkt,
    subjectTitle: subjects.find(subject => subject._id === forbedringspunkt.subjectId),
  }))

  const bevaringspunkterWithSubject = bevaringspunkter.map(bevaringspunkt => ({
    ...bevaringspunkt,
    subjectTitle: subjects.find(subject => subject._id === bevaringspunkt.subjectId),
  }))

  const document = new Document({
    styles: DefaultStyles,
    sections: [
      {
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Handlingsplan - ${getDateStringFormat(new Date())}`,
                  }),
                  new TextRun({
                    children: [
                      new Tab(),
                      new Tab(),
                      new Tab(),
                      new Tab(),
                      new Tab(),
                      new Tab(),
                      new Tab(),

                      "Side ",
                      PageNumber.CURRENT,
                      " av ",
                      PageNumber.TOTAL_PAGES,
                    ],
                  }),
                ],
                style: "body",
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: "end",
                children: [
                  new TextRun({
                    children: ["www.arbeidsmiljohjelpen.no"],
                    font: "Tahoma",
                  }),
                ],
              }),
            ],
          }),
        },
        properties: {
          page: {
            size: {
              orientation: "landscape",
            },
          },
        },
        children: [
          ...Intro(),
          ...Bulletlist(),
          new Paragraph({
            style: "Strong",
            children: [new PageBreak()],
          }),
          ...BevaringspunktTable("Bevaringspunkter", bevaringspunkterWithSubject),

          new Paragraph({
            style: "Strong",
            children: [new PageBreak()],
          }),
          ...ForbedringspunktTable({
            smallTitle: "Bevaringspunkter",
            forbedringspunkter: forbedingspunkterWithSubject,
          }),
        ],
      },
    ],
  })

  Packer.toBlob(document).then(blob => {
    saveAs(blob, "Arbeidsmiljøhjelpen_Handlingsplan.docx")
  })
}

export default HandlingplanTable
