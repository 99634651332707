import React from "react"
import { useLocation } from "@reach/router"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link as GatsbyLink } from "gatsby"
import CheckmarkGreenIcon from "../../../../images/icons/checkmark-green.svg"
import styles from "./style.module.scss"
import { SubjectType, IndustryType } from "../../../../propTypes"
import { H } from "../../../../state/levelContext"
import { eventLogger } from "../../../../utils"

const SubjectToggle = ({ industry, subjects }) => {
  const location = useLocation()
  const bevaringspunkter = Object.values(useSelector(state => state.bevaringspunkter))
    .filter(b => b.industryId === industry._id)
    .map(b => ({ id: b.id, subjectId: b.subjectId }))
  const forbedringspunkter = Object.values(useSelector(state => state.forbedringspunkter))
    .filter(f => f.industryId === industry._id)
    .map(b => ({ id: b.id, subjectId: b.subjectId }))
  const punkter = [...bevaringspunkter, ...forbedringspunkter]
  return (
    <>
      <div className={classNames("desktop-col-12")}>
        <div className={styles.linkContainer}>
          {subjects
            .sort((a, b) => a.id - b.id)
            .map(subject => {
              const isVisited = punkter.some(s => s.subjectId === subject._id)

              return (
                <GatsbyLink
                  key={subject.tag}
                  aria-label={`${subject.title}${isVisited ? " Tema er fullført" : ""}`}
                  className={classNames(styles.link)}
                  to={`/bransje/${industry.route}/${subject.route}`}
                  onClick={
                    // Siteimprove
                    (window._sz?.push(["event", "Tema", "Click", subject.title]),
                    // Clickstream
                    eventLogger.logSearchEvent(location, "Click/Tema", subject.title))
                  }
                >
                  <img
                    className={isVisited ? styles.checkmarkCompleted : styles.checkmarkHidden}
                    src={CheckmarkGreenIcon}
                    alt=""
                  />
                  <H className={classNames("h3", styles.text)}>{subject.title}</H>
                  <div className={classNames(styles.icon, { [styles.completed]: isVisited })}>
                    <div className={classNames(styles.image, { [styles.completed]: isVisited })}>
                      <img src={subject.icon} alt={subject.alt} />
                    </div>

                    <ul>
                      {subject.keywords.map(keyword => (
                        <li key={`keyword-${keyword}`}>{keyword}</li>
                      ))}
                    </ul>
                  </div>
                </GatsbyLink>
              )
            })}
        </div>
      </div>
    </>
  )
}

SubjectToggle.propTypes = {
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  industry: IndustryType.isRequired,
}

export default SubjectToggle
