// import { redirectTo } from "@reach/router"
import { StyleSheet, Font } from "@react-pdf/renderer"

Font.register({
  family: "worksans",
  fonts: [
    { src: "/WorkSans-Thin.ttf", fontStyle: "normal", fontWeight: 300 },
    { src: "/WorkSans-Regular.ttf", fontStyle: "normal", fontWeight: 400 },
    { src: "/WorkSans-Medium.ttf", fontStyle: "normal", fontWeight: 500 },
    { src: "/WorkSans-Bold.ttf", fontStyle: "normal", fontWeight: 700 },
  ],
})

const styles = StyleSheet.create({
  viewer: {
    height: 600,
    width: "100%",
  },
  body: {
    fontFamily: "worksans",
    fontWeight: 400,
    lineHeight: 1.7,
    fontSize: 12,
    paddingTop: 30,
    paddingHorizontal: 30,
    paddingBottom: 50,
  },
  content: {
    padding: 30,
  },
  intro: {
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
  },
  notice: {
    backgroundColor: "#FAF5E9",
    borderRadius: 10,
    padding: 15,
    marginTop: 10,
  },
  h1: {
    fontFamily: "worksans",
    fontSize: 20,
  },
  h2: {
    fontFamily: "worksans",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 5,
  },
  h3: {
    fontFamily: "worksans",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
  },
  h5: {
    fontFamily: "worksans",
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
  },
  hr: {
    paddingBottom: 15,
    marginBottom: 20,
    borderBottom: "1 solid black",
  },
  centerText: {
    textAlign: "center",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerLeft: {
    fontSize: 14,
  },
  footer: {
    position: "absolute",
    right: 30,
    bottom: 30,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 11,
  },
  rightFooter: {
    fontWeight: 300,
    fontSize: 15,
  },
  bevaringspunkt: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: -10,
  },
  titleAndText: {
    paddingHorizontal: 10,
  },
  box: {
    marginVertical: 10,
    border: "2 solid #FFDD88",
    padding: 15,
  },
  boxHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 20,
  },
  subjectImportance: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  importanceImagesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  importanceImage: {
    display: "inline-flex",
    marginLeft: 5,
    width: 13,
    height: 13,
  },
  bulletItem: {
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
    fontSize: 14,
    marginBottom: 10,
    paddingRight: 10,
  },
  bullet: {
    marginTop: 3,
    marginRight: 10,
    marginLeft: 5,
    width: 8,
    height: 8,
  },
  bigBullet: {
    marginRight: 10,
    marginBottom: 5,

    width: 20,
    height: 20,
    padding: 1,
  },
  forbedringspunkter: {
    paddingHorizontal: 0,
  },
  forbedringspunkt: {
    marginBottom: 10,
  },
  forbedringspunktLastOne: {
    marginTop: 20,
    marginBottom: 20,
  },
  forbedringspunktContent: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  done: {
    display: "flex",
    flexDirection: "row",
  },
  bold: {
    fontWeight: 500,
  },
  hero: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  heroText: {
    width: "80%",
    paddingRight: 40,
  },
  heroImage: {
    width: "20%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  indent: {
    marginLeft: 20,
  },
  dot: {
    width: 14,
    height: 14,
    backgroundColor: "#11c1b0", // Primary
    borderRadius: 10,
    marginLeft: -20,
  },
  smallDot: {
    width: 4,
    height: 4,
    borderRadius: 2,
  },
  bigDot: {
    width: 15,
    height: 15,
    borderRadius: 2,
  },
  primaryBgColor: {
    backgroundColor: "#11c1b0", // Primary
  },
  yellowBgColor: {
    backgroundColor: "#ffe6a6", // Yellow
  },
  blackBgColor: {
    backgroundColor: "black", // Yellow
  },
})

export default styles
