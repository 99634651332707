/* eslint-disable react/prop-types */
import React from "react"
import { View, Text, Image } from "@react-pdf/renderer"
import { getDateStringFormat, getDateString } from "../../../../../utils/timeAndDate"
import styles from "./styles"

// TODO: The two below should come from an enum in constants/index.js
const PRIORITY = {
  "-1": "Ikke satt",
  1: "Lav",
  2: "Middels",
  3: "Høy",
}

const Dot = ({ color = "primary", small = false }) => (
  <View style={[styles.dot, styles[`${color}BgColor`], small ? styles.smallDot : ""]} />
)

const Header = () => (
  <View fixed style={styles.header}>
    <Text>Handlingsplan - {getDateStringFormat()}</Text>
    <Text fixed render={({ pageNumber, totalPages }) => `Side ${pageNumber} av ${totalPages}`} />
  </View>
)

const Footer = () => (
  <View fixed style={styles.footer}>
    <Text>www.arbeidsmiljøhjelpen.no</Text>
  </View>
)

const Intro = ({ allSubjects, subjects }) => (
  <View>
    <Text style={[styles.h1, styles.pageTitle]}>Handlingsplan - {getDateStringFormat()}</Text>
    <View style={styles.introContent}>
      <View style={styles.introText}>
        <View>
          <Text style={{ marginBottom: 13 }}>
            Denne handlingsplanen viser hva vi skal jobbe med framover for å forbedre arbeidsmiljøet
            vårt.
          </Text>
          <Text>
            For hvert forbedringsområde vi har valgt, har vi skrevet inn hvilke tiltak vi skal
            gjennomføre, hvem som er ansvarlig, prioritet og frist for oppfølging.
          </Text>
        </View>
      </View>
      <Image style={styles.introImage} src="/arbeidsmiljohjelpen_gul.png" />
    </View>
    <SubjectOverviewCompleted allSubjects={allSubjects} subjects={subjects} />
  </View>
)

const SubjectOverviewCompleted = ({ allSubjects, subjects }) => (
  <View style={{ paddingBottom: "8px" }}>
    <Text style={[styles.italic, { fontSize: 10, paddingBottom: "4px" }]}>
      Temaene vi har gjennomført:
    </Text>
    <View style={styles.subjectsOverviewFlex}>
      {subjects.map(subject => {
        const isIncluded = allSubjects.some(s => s.subjectId === subject._id)

        if (isIncluded) {
          return (
            <View style={[styles.subject, styles.subjectCompleted]}>
              <View style={styles.imageContainer}>
                <Image src="/checkmark-green.png" />
              </View>
              <Text>{subject.title}</Text>
            </View>
          )
        }
        return (
          <View style={[styles.subject]}>
            <Text>{subject.title}</Text>
          </View>
        )
      })}
    </View>
  </View>
)

// TODO: This was added last minute before Prod, so this should be fetched from Sanity
const IntroInfo = () => (
  <View style={[styles.introInfo]}>
    <View style={{ flexBasis: "35%", paddingTop: 8 }}>
      <View style={[styles.noticePunkter]}>
        <View style={[styles.row, styles.infoPadding]}>
          <View style={{ marginTop: 5 }}>
            <Dot color="black" small />
          </View>
          {/* DETTE SKAL INN I SANITY */}
          <Text style={{ marginBottom: 20 }}>
            <Text style={styles.boldItalic}>Lovpålagt tilknytning til BHT. </Text>
            <Text style={styles.italic}>
              Dersom risikoforholdene tilsier det, må arbeidsgiver knytte virksomheten til en
              godkjent bedriftshelsetjeneste (BHT). BHT skal blant annet hjelpe oss å følge opp
              arbeidsmiljøet i virksomheten og arbeidshelsen til de ansatte.
            </Text>
          </Text>
        </View>
        <View style={[styles.row, styles.infoPadding]}>
          <View style={{ marginTop: 5 }}>
            <Dot color="black" small />
          </View>
          <Text>
            <Text style={styles.boldItalic}>Arbeidsmiljø er et felles ansvar. </Text>
            <Text style={styles.italic}>
              Leder er ansvarlig for at arbeidsmiljøet på arbeidsplassen er forsvarlig, men alle
              ansatte har plikt og rett til å medvirke for å skape et godt og sikkert arbeidsmiljø.
              Arbeidsmiljø er derfor et felles ansvar, og alle sitt bidrag er viktig for å lykkes
              med oppfølgingen av tiltakene
            </Text>
          </Text>
        </View>
      </View>{" "}
    </View>
    <View style={{ flexBasis: "60%" }}>
      <Image style={styles.processImage} src="/process.png" />
    </View>
  </View>
)

const Notice = ({ title, content }) => (
  <View style={styles.notice}>
    <Text style={styles.h3}>{title}</Text>
    <Text>{content}</Text>
  </View>
)

const TitleAndText = ({ style, title, text }) => (
  <View style={[style, styles.titleAndText]}>
    <Text style={styles.h3}>{title}</Text>
    <Text>{text}</Text>
  </View>
)

const BevaringspunkterTable = ({ bevaringspunkter, subjects }) => (
  <View style={styles.content} wrap>
    <View>
      <Text style={styles.h2}>Bevaringspunkter</Text>
      <View style={[styles.table, styles.greenBorder]}>
        <View style={[styles.tableRow, styles.tabelHead]}>
          <View style={[styles.tableCol, styles.greenBorder, { flexBasis: "12%" }]}>
            <Text style={styles.tableCell}>Tema</Text>
          </View>
          <View style={[styles.tableCol, styles.greenBorder, { flexBasis: "88%" }]}>
            <Text style={styles.tableCell}>Beskrivelse</Text>
          </View>
        </View>
        {bevaringspunkter.map(b => (
          <View key={`forbedringspunkt-item-${b.id}`} style={styles.tableRow}>
            <View style={[styles.tableCol, styles.greenBorder, { flexBasis: "12%" }]}>
              <Text style={styles.tableCell}>
                {subjects.find(s => s._id === b.subjectId).title}
              </Text>
            </View>
            <View style={[styles.tableCol, styles.greenBorder, { flexBasis: "88%" }]}>
              <Text style={styles.tableCell}>{b.title.text}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  </View>
)

const ForbedringspunkterTable = ({ forbedringspunkter, subjects }) => (
  <View style={styles.content} wrap>
    <Text style={[styles.h2, styles.bold]}>Forbedringspunkter</Text>
    <View style={[styles.table, styles.yellowBorder]}>
      <View style={[styles.tableRow, styles.tabelHead, styles.bgYellow]}>
        <View style={[styles.tableCol, { flexBasis: "12%" }]}>
          <Text style={styles.tableCell}>Tema</Text>
        </View>
        <View style={[styles.tableCol, { flexBasis: "28%" }]}>
          <Text style={styles.tableCell}>Punkt</Text>
        </View>
        <View style={[styles.tableCol, { flexBasis: "30%" }]}>
          <Text style={styles.tableCell}>Beskrivelse</Text>
        </View>
        <View style={[styles.tableCol, { flexBasis: "10%" }]}>
          <Text style={styles.tableCell}>Prioritet</Text>
        </View>
        <View style={[styles.tableCol, { flexBasis: "10%" }]}>
          <Text style={styles.tableCell}>Ansvarlig</Text>
        </View>
        <View style={[styles.tableCol, { flexBasis: "10%" }]}>
          <Text style={styles.tableCell}>Sjekk</Text>
        </View>
      </View>
      {forbedringspunkter.map(b => (
        <View key={`forbedringspunkt-item-${b.id}`} style={styles.tableRow} wrap={false}>
          <View style={[styles.tableCol, { flexBasis: "12%" }]}>
            <Text style={styles.tableCell}>{subjects.find(s => s._id === b.subjectId).title}</Text>
          </View>
          <View style={[styles.tableCol, { flexBasis: "28%" }]}>
            <Text style={styles.tableCell}>{b.title.text}</Text>
          </View>
          <View style={[styles.tableCol, { flexBasis: "30%", fontWeight: 500 }]}>
            <Text style={[styles.tableCell]}>{b.description}</Text>
          </View>
          <View style={[styles.tableCol, { flexBasis: "10%" }]}>
            <Text
              style={[
                styles.tableCell,
                styles.forbedringspunktContentTitleContent,
                styles.forbedringspunktContentTitleContentPriority,
                b.priority === 1 && styles.priorityLow,
                b.priority === 2 && styles.priorityMedium,
                b.priority === 3 && styles.priorityHigh,
                { textAlign: "center" },
              ]}
            >
              {PRIORITY[b.priority]}
            </Text>
          </View>
          <View style={[styles.tableCol, { flexBasis: "10%" }]}>
            <Text style={[styles.tableCell, styles.forbedringspunktContentTitleContent]}>
              {b.responsible ? b.responsible : "Ikke satt"}
            </Text>
          </View>
          <View style={[styles.tableCol, { flexBasis: "10%" }]}>
            <Text style={[styles.tableCell, styles.forbedringspunktContentTitleContent]}>
              {b.deadline === -1 ? "Ikke satt" : getDateString(b.deadline)}
            </Text>
          </View>
        </View>
      ))}
    </View>
  </View>
)

const allSubjects = ({ bevaringspunkter, forbedringspunkter }) => [
  ...bevaringspunkter,
  ...forbedringspunkter,
]

const CompletedSubject = ({ subjects }) => (
  <View>
    {subjects.map(subject => {
      const isIncluded = allSubjects.some(s => s.subjectId === subject._id)

      if (isIncluded) {
        return <Text>{subjects.title} - checked</Text>
      }
      return <Text>{subject.title}</Text>
    })}
  </View>
)

export {
  styles,
  Header,
  Footer,
  Intro,
  IntroInfo,
  TitleAndText,
  Notice,
  CompletedSubject,
  ForbedringspunkterTable,
  BevaringspunkterTable,
  SubjectOverviewCompleted,
}
