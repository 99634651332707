import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { pdf } from "@react-pdf/renderer"
import NProgress from "nprogress"
import classNames from "classnames"
import { Loading, Button } from "../../../../components"
import Handlingsplan from "./pdf"
import { getBevaringspunkter, getForbedringspunkter } from "./_utils"
import { getStringFromBlockContent, withDefaults } from "../../../../utils"
import { SubjectType } from "../../../../propTypes"
import useSanity from "../../../../hooks/useSanity"
import { blockContentGroq } from "../../../../groq"
import { loadState } from "../../../../state/localStorage"

const stateLocalStorage = loadState()
const language =
  stateLocalStorage.languageOption !== undefined
    ? stateLocalStorage.languageOption.languageOption
    : ""

const PDFButton = ({
  include,
  buttonName,
  industryId,
  industryTag,
  print,
  children,
  disabled,
  className,
  forbedringspunkterPremade,
  subjects,
  fileName,
  onClick,
}) => {
  const bevaringspunkterRaw = useSelector(s => s.bevaringspunkter)
  const forbedringspunkterRaw = useSelector(s => s.forbedringspunkter)
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "hjelpen_handlingsplanSammendragPage",
      industryTag,
    })
  }, [industryTag])

  const { loading, error, data } = useSanity(
    `*[_type==$type &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        industry-> {
          tag,
        },
        notice1 {
          ...,
          content[] ${blockContentGroq},
        }
      }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data, industryTag)

  // Parse notice1 from handlingsplan, it's in blockContent, and we will only display it as simple string in the PDF
  const noticeContent = getStringFromBlockContent(page?.notice1?.content)
  const notice = { title: page?.notice1?.title, content: noticeContent }

  const openPdf = () => {
    NProgress.configure({ showSpinner: true })
    NProgress.configure({ parent: `#${buttonName}` })
    NProgress.start()

    const bevaringspunkter = include.includes("bevaringspunkter")
      ? getBevaringspunkter(industryId, -1, bevaringspunkterRaw).filter(b => b.isChecked)
      : null

    const forbedringspunkter =
      include.includes("forbedringspunkter") && forbedringspunkterPremade
        ? getForbedringspunkter(
            forbedringspunkterRaw,
            forbedringspunkterPremade,
            subjects,
            industryId,
            language
          )
        : null

    try {
      pdf(
        <Handlingsplan
          bevaringspunkter={bevaringspunkter}
          forbedringspunkter={forbedringspunkter}
          notice={notice}
          subjects={subjects}
        />
      )
        .toBlob()
        .then(blob => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName)
          } else {
            const blobUrl = URL.createObjectURL(blob)
            const pdfw = window.open(blobUrl, "_blank")
            if (print) {
              pdfw.focus()
              pdfw.onload = () => {
                pdfw.print()
              }
            }
          }
        })
        .finally(NProgress.done())
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`En feil oppstod ved PDF-generering: ${err.name} ${err.message}`)
      NProgress.done()
    }
  }
  return (
    <Button.Icon
      id={buttonName}
      disabled={disabled}
      className={classNames(className)}
      onClick={() => {
        if (onClick) {
          onClick()
        }
        openPdf()
      }}
      type="button"
      icon={print ? "printprimary" : "download"}
    >
      {children}
    </Button.Icon>
  )
}

PDFButton.propTypes = {
  include: PropTypes.arrayOf(PropTypes.string),
  buttonName: PropTypes.string.isRequired,
  industryId: PropTypes.string.isRequired,
  industryTag: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  forbedringspunkterPremade: PropTypes.arrayOf(PropTypes.object),
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
  print: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fileName: PropTypes.string,
  onClick: PropTypes.func,
}

PDFButton.defaultProps = {
  include: ["bevaringspunkter", "forbedringspunkter"],
  print: false,
  disabled: false,
  className: null,
  fileName: "sammendrag.pdf",
  forbedringspunkterPremade: null,
  onClick: null,
}

export default PDFButton
