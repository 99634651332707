import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useLocation } from "@reach/router"

import { getBevaringspunkter, getForbedringspunkter } from "./_utils"
import { BackLink, SEO, BlockContent, Loading, Title, Button, Link } from "../../../../components"
import { IndustryType, SubjectType } from "../../../../propTypes"
import Modal from "../../../../components/modal"
import Empty from "./_empty"
import {
  withDefaults,
  getFileName,
  getStringFromBlockContent,
  eventLogger,
} from "../../../../utils"
import styles from "./style.module.scss"
import withPageDependencies from "../../../../hoc/withPageDependencies"
import useSanity from "../../../../hooks/useSanity"
import { blockContentGroq } from "../../../../groq"
import { H, Section } from "../../../../state/levelContext"
import BevaringspunkterTable from "./bevaringspunkt/_bevaringspunktTable"
import ForbedringspunktTable from "./forbedringspunkt/_forbedringspunktTable"
import ForbedringspunktMobileAndTablet from "./forbedringspunkt/_forbedringspunktMobileAndTablet"
import BevaringspunkterCard from "./bevaringspunkt/_bevaringspunktCard"

import LinkWrapper from "../../../../components/linkWrapper"
import PDFButton from "./_pdfButton"

import CalendarPicker from "./_calendarPicker"
import SaveDialogNew from "./_saveDialogNew"

const Sammendrag = ({ industry, subjects }) => {
  const bevaringspunkterRaw = useSelector(s => s.bevaringspunkter)
  const bevaringspunkter = getBevaringspunkter(
    industry._id,
    -1, // all
    bevaringspunkterRaw
  ).filter(b => b.isChecked)
  const forbedringspunkterRaw = useSelector(s => s.forbedringspunkter)

  const [params, setParams] = useState(-1)
  const [editBevaringspunkter, setEditbevaringspunkter] = useState(false)
  const [editForbedringspunkter, setEditForbedringspunkter] = useState(false)
  const [showCalendarPicker, setShowCalendarPicker] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }
  const openCalendarPicker = () => {
    setShowCalendarPicker(true)
  }
  const closeCalendarPicker = () => {
    setShowCalendarPicker(false)
  }
  const location = useLocation()
  useEffect(() => {
    setParams({
      pageDataType: "hjelpen_handlingsplanSammendragPage",
      premadeForbType: "hjelpen_forbedringspunkterPage",
      industryTag: industry.tag,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `{
      "premadeForbedringspunkter": *[_type==$premadeForbType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        industry-> {
          ...,
          tag,
        },
        subject-> {
          ...,
          _id,
          tag
        },
        "formulationItemsRefs": coalesce(formulationItemsRef[]-> { ...,}, []),
      },
      "industrySubjects": *[_type == "subject" && _id in *[_id=="${industry.tag}_hjelpen_temaerPage"][0].subjectList[]._ref ]{
        ...,
        image {
          ...,
          asset->{
            ...
          }
        }
      },
      "defaultSubjects": *[_type == "subject" && _id in *[_id=="default_hjelpen_temaerPage"][0].subjectList[]._ref ]{
        ...,
        image {
          ...,
          asset->{
            ...
          }
        }
      },
      "pageData": *[_type==$pageDataType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
          industry-> {
            tag,
          },
          ...,
          ingress[] ${blockContentGroq},
          notice1 {
            ...,
            content[] ${blockContentGroq},
            icon {
              ...,
              asset-> {
                url
              }
            },
          },
          emptyPopupDescription[] ${blockContentGroq},
      }
    }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const text = withDefaults(data.pageData, industry.tag)

  const industrySubjects = data.industrySubjects.map(s => {
    return {
      subjectId: s.subjectId,
      _id: s._id,
      route: s.route,
      title: s.title,
      tag: s.tag,
      icon: s.image.asset.url,
      alt: s.image.alt,
    }
  })
  const defaultSubjects = data.defaultSubjects.map(s => {
    return {
      subjectId: s.subjectId,
      _id: s._id,
      route: s.route,
      title: s.title,
      tag: s.tag,
      icon: s.image.asset.url,
      alt: s.image.alt,
    }
  })

  function sortAscendingSubjectsBySubjectId(a, b) {
    return a.subjectId - b.subjectId
  }

  defaultSubjects.sort(sortAscendingSubjectsBySubjectId)
  industrySubjects.sort(sortAscendingSubjectsBySubjectId)

  const forbedringspunkter = getForbedringspunkter(
    forbedringspunkterRaw,
    data.premadeForbedringspunkter,
    subjects,
    industry._id
  )

  const noData = forbedringspunkter.length === 0 && bevaringspunkter.length === 0

  const onEdit = () => {
    setEditbevaringspunkter(!editBevaringspunkter)
  }

  const onEditForbedringspunkter = () => {
    setEditForbedringspunkter(!editForbedringspunkter)
  }

  // Parse notice1 from handlingsplan, it's in blockContent, and we will only display it as simple string in the PDF
  const noticeContent = getStringFromBlockContent(text?.notice1?.content)
  const notice = { title: text?.notice1?.title, content: noticeContent }

  return (
    <>
      <SEO title="Sammendrag" />
      <div className={classNames("content")} aria-hidden="true">
        <Section>
          <div className="desktop-col-12">
            <Title.Subject title={text.title} />
          </div>
          <div className="desktop-col-7">
            <BlockContent blocks={text.ingress || []} />
          </div>
        </Section>
        <div className="desktop-col-12">
          <div className={classNames(styles.buttonRow, "flexrow")}>
            <Button.Icon
              id="lagre-handlingsplan"
              disabled={noData}
              icon="lagreprimary"
              type="button"
              onClick={() => {
                openModal()
                // SiteImprove Event
                window._sz?.push([
                  "event",
                  "File Download",
                  "Click/Download",
                  "Lagre Handlingsplan",
                ])
                // Clickstream
                eventLogger.logSearchEvent(location, "Click/Download", "Lagre Handlingsplan")
              }}
            >
              Lagre handlingsplan
            </Button.Icon>
            {modalIsOpen && (
              <Modal isOpen={modalIsOpen} closeModal={closeModal}>
                <SaveDialogNew
                  texts={{
                    ...text,
                    openPDFButton: text.openPDFButton,
                    popupTitle: "Lagre handlingsplan",
                    popupDescription:
                      "Her kan du lagre handlingsplanen i tabellformat, utskriftsvennlig format, eller som veggpryd i enten Excel, Word eller som PDF.",
                    popupLink: text.calendarInvite_popupLink,
                    popupLinkDescription: text.calendarInvite_popupLinkDescription,
                  }}
                  industry={industry}
                  subjects={
                    industrySubjects.length !== 0 ? industrySubjects : defaultSubjects || []
                  }
                  premadeForbedringspunkter={data.premadeForbedringspunkter}
                  forbedringspunkter={forbedringspunkter}
                  bevaringspunkter={bevaringspunkter}
                  notice={notice}
                />
              </Modal>
            )}
            <PDFButton
              buttonName="print-handlingsplan"
              industryId={industry._id}
              industryTag={industry.tag}
              disabled={noData}
              forbedringspunkterPremade={data.premadeForbedringspunkter}
              subjects={industrySubjects.length !== 0 ? industrySubjects : defaultSubjects || []}
              fileName={getFileName(industry.title)}
              print
              onClick={() => {
                eventLogger.logCustomEvent(location, "at.events.click.export.print.printPdf")
                // SiteImprove Event
                window._sz?.push(["event", "File Download", "Click/Download", text.printButton])
                // Clickstream
                eventLogger.logSearchEvent(location, "Click/Download", text.printButton)
              }}
            >
              {text.printButton}
            </PDFButton>
            <Button.Icon
              id="calendar-handlingsplan"
              disabled={noData}
              icon="calendarprimary"
              onClick={() => {
                openCalendarPicker()
                // setShowCalendarPicker(true)
                // SiteImprove Event
                window._sz?.push(["event", "File Download", "Click/Download", text.calendarButton])
                // Clickstream
                eventLogger.logSearchEvent(location, "Click/Download", text.calendarButton)
              }}
              type="button"
            >
              {text.calendarButton}
            </Button.Icon>
            {showCalendarPicker && (
              <Modal isOpen={showCalendarPicker} closeModal={closeCalendarPicker}>
                <CalendarPicker
                  forbedringspunkter={forbedringspunkter}
                  industryId={industry._id}
                  subjects={subjects}
                  onButtonClick={() => {}}
                  texts={{
                    ...text,
                    popupTitle: text.calendarInvite_popupTitle,
                    popupDescription: text.calendarInvite_popupDescription,
                    popupLink: text.calendarInvite_popupLink,
                    popupLinkDescription: text.calendarInvite_popupLinkDescription,
                  }}
                />
              </Modal>
            )}
            {/* <CalendarPicker
              forbedringspunkter={forbedringspunkter}
              industryId={industry._id}
              subjects={subjects}
              open={showCalendarPicker}
              onClose={() => {
                setShowCalendarPicker(false)
              }}
              onButtonClick={() => {}}
              texts={{
                ...text,
                popupTitle: text.calendarInvite_popupTitle,
                popupDescription: text.calendarInvite_popupDescription,
                popupLink: text.calendarInvite_popupLink,
                popupLinkDescription: text.calendarInvite_popupLinkDescription,
              }}
            /> */}
          </div>
        </div>

        <Section>
          <div className="desktop-col-12">
            <H className="h3 no-margin" style={{ marginBlockStart: "0.5em" }}>
              {text.veienVidere_title}
            </H>
          </div>
          <div className="desktop-col-8">
            <p style={{ margin: 0 }}>{text.veienVidere_text}</p>
          </div>
          <div className="desktop-col-4 flex flex-flex-end">
            <Link.Big secondary url={`/bransje/${industry.route}/veienvidere`} arrow="right">
              {text.veienVidere_button}
            </Link.Big>
          </div>
        </Section>
      </div>
      <Section>
        <div className="content">
          <div className="desktop-col-12">
            <H className="h3 no-margin">{text.bevaringspunkt_forbedringspunkt_title}</H>

            <div className={classNames(styles.bevaringspunkt)}>
              <div className="flex" style={{ alignItems: "center" }}>
                <Section>
                  <H className={classNames(styles.titleStyle, "h5 normal-font-weight")}>
                    {text.bevaringspunkt_smallTitle}
                  </H>
                </Section>

                <div className={classNames(styles.buttonMobileView)}>
                  <Button.Tertiary
                    icon="pencil"
                    type="button"
                    onClick={() => onEdit()}
                    style={{ marginRight: "1rem", marginLeft: "1rem" }}
                  >
                    {editBevaringspunkter
                      ? text.bevaringspunkt_saveButton
                      : text.bevaringspunkt_editButton}
                  </Button.Tertiary>
                </div>
              </div>

              <BevaringspunkterTable
                industryId={industry._id}
                subjects={subjects}
                editable={editBevaringspunkter}
              />
              <BevaringspunkterCard
                industryId={industry._id}
                subjects={subjects}
                editable={editBevaringspunkter}
              />
            </div>

            <Section>
              <div className={classNames(styles.bevaringspunkt)} style={{ marginTop: "4rem" }}>
                <div className="flex" style={{ alignItems: "center" }}>
                  <H className={classNames(styles.titleStyle, "h5 normal-font-weight")}>
                    {text.forbedringspunkt_smallTitle}
                  </H>
                  <div className={classNames(styles.buttonMobileView)}>
                    <Button.Tertiary
                      type="button"
                      icon="pencil"
                      onClick={() => onEditForbedringspunkter()}
                      style={{ marginRight: "1rem", marginLeft: "1rem" }}
                    >
                      {editForbedringspunkter
                        ? text.bevaringspunkt_saveButton
                        : text.bevaringspunkt_editButton}
                    </Button.Tertiary>
                  </div>
                </div>
                <div style={{ paddingBottom: 20 }}>
                  <Section>
                    <ForbedringspunktTable
                      industryId={industry._id}
                      subjects={subjects}
                      forbedringspunkter={forbedringspunkter}
                      editable={editForbedringspunkter}
                      options={{ hideColumn: { selected: true } }}
                      texts={{
                        title: text.forbedringspunkt_title,
                        subjectImportance: text.forbedringspunkt_subjectImportance,
                        priorityLabel: text.forbedringspunkt_priorityLabel,
                        statusLabel: text.forbedringspunkt_statusLabel,
                        deadlineLabel: text.forbedringspunkt_deadlineLabel,
                        responsibleLabel: text.forbedringspunkt_responsibleLabel,
                        descriptionLabel: text.forbedringspunkt_descriptionLabel,
                        doneLabel: text.forbedringspunkt_doneLabel,
                        doneDateLabel: text.forbedringspunkt_doneDateLabel,
                      }}
                    />
                    <div>
                      <ForbedringspunktMobileAndTablet
                        industryId={industry._id}
                        subjects={subjects}
                        forbedringspunkter={forbedringspunkter}
                        editable={editForbedringspunkter}
                        options={{ hideColumn: { selected: true } }}
                        texts={{
                          title: text.forbedringspunkt_title,
                          subjectImportance: text.forbedringspunkt_subjectImportance,
                          priorityLabel: text.forbedringspunkt_priorityLabel,
                          statusLabel: text.forbedringspunkt_statusLabel,
                          deadlineLabel: text.forbedringspunkt_deadlineLabel,
                          responsibleLabel: text.forbedringspunkt_responsibleLabel,
                          descriptionLabel: text.forbedringspunkt_descriptionLabel,
                          doneLabel: text.forbedringspunkt_doneLabel,
                          doneDateLabel: text.forbedringspunkt_doneDateLabel,
                        }}
                      />
                    </div>
                  </Section>
                </div>

                <div>
                  <LinkWrapper>
                    <BackLink to={`/bransje/${industry.route}/temavelger`}>Tilbake</BackLink>
                    <Link.Big primary url={`/bransje/${industry.route}/veienvidere`} arrow="right">
                      {text.veienVidere_button}
                    </Link.Big>
                  </LinkWrapper>
                </div>
              </div>
            </Section>
          </div>
        </div>
      </Section>

      {noData ? (
        <Empty
          texts={{
            listTitle: text.empty_listTitle,
            listLink: text.empty_listLink,
            popupTitle: text.empty_popupTitle,
            popupDescription: text.empty_popupDescription,
            popupLink: text.empty_popupLink,
          }}
          urlHjelpen={`/bransje/${industry.route}`}
          urlBevaring={`/bransje/${industry.route}/temavelger`}
        />
      ) : null}
    </>
  )
}

Sammendrag.propTypes = {
  industry: IndustryType.isRequired,
  subjects: PropTypes.arrayOf(SubjectType).isRequired,
}

export default withPageDependencies(Sammendrag)
