import React, { useState } from "react"
import { BackLink, Title, Loading, LinkWrapper, Accordion } from "../components"
import SEO from "../components/SEO"
import { IndustryType } from "../propTypes"
import withPageDependencies from "../hoc/withPageDependencies"
import useSanity from "../hooks/useSanity"
import { blockContentGroq } from "../groq"
import { Section } from "../state/levelContext"

const Vanligesporsmal = ({ industry }) => {
  const [params] = useState({ id: "commonQuestionsPage" })

  const { loading, error, data } = useSanity(
    `*[_id==$id || _id=="drafts."+$id]{
      ...,
        title,
        ingress[] ${blockContentGroq},
      } | order(_updatedAt desc) [0]`,
    params
  )

  const page = data

  if (loading || error) return <Loading error={error} />

  return (
    <>
      <SEO title={page.title} />
      <Title.Default title={page.title} content={page.ingress} />
      <div className="content">
        <Section>
          <div className="desktop-col-9">
            <Accordion.Default
              items={page.accordionItems}
              itemStyle={{ marginBottom: "1rem" }}
              portableContent
            />
          </div>
        </Section>
        <LinkWrapper>
          <BackLink to={industry ? `/bransje/${industry.route}` : "/"} />
        </LinkWrapper>
      </div>
    </>
  )
}

Vanligesporsmal.propTypes = {
  industry: IndustryType,
}

Vanligesporsmal.defaultProps = {
  industry: null,
}

export default withPageDependencies(Vanligesporsmal)
