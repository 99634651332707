import Hjelpen from "./hjelpen"
import Personvern from "./personvern"
import Vanligesporsmal from "./vanligesporsmal"
import Kontakt from "./kontakt"
import NotFound from "../pages/404"
import Index from "./main"

export { Hjelpen, Personvern, Kontakt, NotFound, Index }

const prefixFunc = (prefix, object) =>
  Object.entries(object).reduce((accumulator, currentValue) => {
    const [key, value] = currentValue
    accumulator[`${prefix}${key}`] = value
    return accumulator
  }, {})

export const ComponentList = {
  ...prefixFunc("Hjelpen.", Hjelpen),
  Personvern,
  Vanligesporsmal,
  Kontakt,
  NotFound,
  Index,
}
