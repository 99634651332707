import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { BackLink, ForwardLink, Loading } from "../../components"
import SEO from "../../components/SEO"
import { SubjectSelector } from "./_index"
import { IndustryType } from "../../propTypes"
import { withDefaults } from "../../utils"
import withPageDependencies from "../../hoc/withPageDependencies"
import useSanity from "../../hooks/useSanity"
import LinkWrapper from "../../components/linkWrapper"
import { Section } from "../../state/levelContext"
// import { loadState } from "../../state/localStorage"

const Temavelger = ({ industry }) => {
  // const stateLocalStorage = loadState()
  // const language =
  //   stateLocalStorage.languageOption !== undefined
  //     ? stateLocalStorage.languageOption.languageOption
  //     : ""
  const bevaringspunkter = Object.values(useSelector(state => state.bevaringspunkter))
    .filter(b => b.industryId === industry._id)
    .map(b => ({ id: b.id, subjectId: b.subjectId }))
  const forbedringspunkter = Object.values(useSelector(state => state.forbedringspunkter))
    .filter(f => f.industryId === industry._id)
    .map(b => ({ id: b.id, subjectId: b.subjectId }))
  const punkter = [...bevaringspunkter, ...forbedringspunkter]

  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      pageDataType: "hjelpen_temavelgerPage",
      stikkordDataType: "hjelpen_stikkordPage",
      forbedringspunkterPageType: "hjelpen_forbedringspunkterPage",
      industryTag: industry.tag,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `{
      "pageData": *[_type==$pageDataType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
          ...,

          industry-> {
            tag,
          },

        },
        "stikkord": *[_type==$stikkordDataType &&
          industry->tag in [$industryTag, "default"] &&
          count(*[_id=="drafts."+^._id])==0] {
            ...,

            industry-> {
              tag,
            },

          },
          "industrySubjects": *[_type == "subject" && _id in *[_id=="${industry._id}_hjelpen_temaerPage"][0].subjectList[]._ref ]{
            ...,
            image {
              ...,
              asset->{
                ...
              }
            }
          },
          "defaultSubjects": *[_type == "subject" && _id in *[_id=="default_hjelpen_temaerPage"][0].subjectList[]._ref ]{
            ...,
            image {
              ...,
              asset->{
                ...
              }
            }
          },
      "premadeForb": *[_type==$forbedringspunkterPageType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
          industry-> {
            tag,
          },
          subject-> {
            _id,
          },
          "formulationItemsRefs": coalesce(formulationItemsRef[]-> { ...,}, []),
        },
    }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data.pageData, industry.tag)

  const industrySubjects = data.industrySubjects.map(s => {
    const stikkordForSubject = data.stikkord.filter(so => so.subject._ref === s._id)
    const mappedStikkords = withDefaults(stikkordForSubject, industry.tag)

    return {
      subjectId: s.subjectId,
      _id: s._id,
      route: s.route,
      title: s.title,
      tag: s.tag,
      icon: s.image.asset.url,
      alt: s.image.alt,
      keywords: (mappedStikkords && mappedStikkords.keywords) || [],
    }
  })
  const defaultSubjects = data.defaultSubjects.map(s => {
    const stikkordForSubject = data.stikkord.filter(so => so.subject._ref === s._id)
    const mappedStikkords = withDefaults(stikkordForSubject, industry.tag)

    return {
      subjectId: s.subjectId,
      _id: s._id,
      route: s.route,
      title: s.title,
      tag: s.tag,
      icon: s.image.asset.url,
      alt: s.image.alt,
      keywords: (mappedStikkords && mappedStikkords.keywords) || [],
    }
  })

  function sortAscendingSubjectsBySubjectId(a, b) {
    return a.subjectId - b.subjectId
  }

  defaultSubjects.sort(sortAscendingSubjectsBySubjectId)
  industrySubjects.sort(sortAscendingSubjectsBySubjectId)

  return (
    <>
      <SEO title={page.title} />
      <div className="content">
        <Section>
          <SubjectSelector
            title={page.title}
            ingress={page.ingress}
            tips={page.tips}
            subjectsTitle={page.subjectsHeader}
            subjects={industrySubjects.length !== 0 ? industrySubjects : defaultSubjects || []}
            industry={industry}
          />
        </Section>
        <LinkWrapper>
          <BackLink to={`/bransje/${industry.route}`}>{page.backLink}</BackLink>
          <ForwardLink
            secondary={punkter.length === 0}
            to={`/bransje/${industry.route}/handlingsplan/sammendrag`}
          >
            {page.forwardLink}
          </ForwardLink>
        </LinkWrapper>
      </div>
    </>
  )
}

Temavelger.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(Temavelger)
