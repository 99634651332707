import React, { useState, useEffect } from "react"
import { BackLink, Chapter, Loading, LinkWrapper } from "../../components"
import SEO from "../../components/SEO"
import { IndustryType } from "../../propTypes"
import withPageDependencies from "../../hoc/withPageDependencies"
import useSanity from "../../hooks/useSanity"
import { blockContentGroq } from "../../groq"
import { Section } from "../../state/levelContext"

const OmArbeidsmiljohjelpen = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      omId: "hjelpen_omPage",
      deleteDataModuleType: "deleteDataModule",
      industryTag: industry ? industry.tag : "",
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `{
      "pageData": *[_id==$omId || _id=="drafts."+$omId] {
          ...,
          industry-> {
            tag,
          },
          heroImage {
            ...,
            asset-> {
              url
            },
          },
          chapterItems[] {
            ...,
            content[] ${blockContentGroq},
          }
      } | order(_updatedAt desc) [0],
      "deleteDataModule": *[_id==$deleteDataModuleType || _id=="drafts."+$deleteDataModuleType]{
          ...,
          description[] ${blockContentGroq},
      } | order(_updatedAt desc) [0]
    }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = data.pageData

  return (
    <>
      <SEO title={page.title} />
      <div className="content">
        <div className="desktop-col-7">
          <div>
            <h1>{page.title}</h1>
            <p className="h5">{page.ingress}</p>
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <img
                style={{ marginTop: "4rem", marginBottom: "2rem" }}
                src={page.heroImage.asset.url}
                alt={page.heroImage.alt}
              />
            </div> */}
          </div>
        </div>
      </div>

      <div className="content">
        <div className="desktop-col-9">
          {page.chapterItems &&
            page.chapterItems.map(chapter => {
              return (
                <Section>
                  <Chapter
                    title={chapter.title}
                    headerTag="h2"
                    content={chapter.content}
                    key={chapter._key}
                  />
                </Section>
              )
            })}
        </div>
      </div>

      <div className="content">
        {/* <div className="desktop-col-12">
          <DeleteDataDialog text={data.deleteDataModule} />
        </div> */}
        <LinkWrapper>
          <BackLink to={industry ? `/bransje/${industry.route}` : "/"}>Tilbake</BackLink>
        </LinkWrapper>
      </div>
    </>
  )
}

OmArbeidsmiljohjelpen.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(OmArbeidsmiljohjelpen)
