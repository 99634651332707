import React, { useState, useEffect } from "react"
import classNames from "classnames"
import {
  SEO,
  Title,
  BackLink,
  Loading,
  Progress,
  Accordion,
  ForwardLink,
} from "../../../components"
import { IndustryType, SubjectType } from "../../../propTypes"
import Subject from "./_subject"
import { withDefaults } from "../../../utils"
import withPageDependencies from "../../../hoc/withPageDependencies"
import useSanity from "../../../hooks/useSanity"
import { blockContentGroq } from "../../../groq"
import { Section } from "../../../state/levelContext"
import LinkWrapper from "../../../components/linkWrapper"
import styles from "./style.module.scss"

const Handlingsplan = ({ industry, subject }) => {
  // const subjectsDone = useSelector(state => state.subjectDone)
  // const industrySubjects = Object.values(subjectsDone).filter(s => s.industryId === industry._id)

  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      type: "hjelpen_handlingsplanSkjemaPage",
      statusViserDataType: "hjelpen_statusViser",
      industryTag: industry.tag,
      subjectTag: subject.tag,
    })
  }, [industry, subject])

  const { loading, error, data } = useSanity(
    `{
      "pageData": *[_type==$type &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
        ...,
        industry-> {
          tag,
        },
        ingress[] ${blockContentGroq},
        notice1 {
          ...,
          content[] ${blockContentGroq},
          icon {
            ...,
            asset-> {
              url,
            },
          },
        },
        notice2[] ${blockContentGroq},
      },
      "statusviser": *[_type==$statusViserDataType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0]  {
          ...,
          industry-> {
            tag,
            title,
          },
        },
    }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data.pageData, industry.tag)
  const statusLabels = withDefaults(data.statusviser, industry.tag)

  return (
    <>
      <SEO title="Handlingsplan" />
      {/* <SecondaryMenu industry={industry} subjects={subjects} /> */}
      <div className="content">
        <div className={classNames("desktop-col-12", styles.introHero)}>
          <Section>
            <Progress activeStep={3} subject={subject} industry={industry} labels={statusLabels} />
            {page.subtitle ? (
              <Title.Subject title={page.subtitle} subject={subject} />
            ) : (
              <p>Legg til Liten overskrift i Standard Tekst</p>
            )}
          </Section>
        </div>
        {page.tipsTitle && (
          <Section>
            <div className="desktop-col-8" style={{ paddingBottom: 20 }}>
              <Accordion.Default
                style={{ margin: 0 }}
                items={[
                  {
                    _key: "tips",
                    title: page.tipsTitle,
                    content: page.tipsContent,
                  },
                ]}
                portableContent
              />
            </div>
          </Section>
        )}
        <Section>
          <div className="desktop-col-12">
            <Subject
              subject={subject}
              industryId={industry._id}
              texts={{
                noContent: page.noContent,
                subjects_subtitle: page.subjects_subtitle,
                subjects_subtitle_noContent: page.subjects_subtitle_noContent,
                subjects_noRank: page.subjects_noRank,
                subjects_howLabel: page.subjects_howLabel,
                subjects_howPlaceholder: page.subjects_howPlaceholder,
                subjects_priorityLabel: page.subjects_priorityLabel,
                subjects_helpLabel: page.subjects_helpLabel,
                subjects_deadlineLabel: page.subjects_deadlineLabel,
                subjects_responsibleLabel: page.subjects_responsibleLabel,
                subjects_responsiblePlaceholder: page.subjects_responsiblePlaceholder,
              }}
            />
          </div>
        </Section>
      </div>

      <div className="content">
        <LinkWrapper>
          <BackLink to={`/bransje/${industry.route}/${subject.route}/forbedringspunkter`}>
            {page.backButtonText}
          </BackLink>
          <div className="flex flex-clumn flex-flex-start">
            <ForwardLink to={`/bransje/${industry.route}/temavelger`} rotateArrow={-90}>
              {page.temaButtonText}
            </ForwardLink>
            <ForwardLink to={`/bransje/${industry.route}/handlingsplan/sammendrag`}>
              {page.nextButtonText}
            </ForwardLink>
          </div>
        </LinkWrapper>
      </div>
    </>
  )
}

Handlingsplan.propTypes = {
  industry: IndustryType.isRequired,
  subject: SubjectType.isRequired,
}

export default withPageDependencies(Handlingsplan)
