export const getDateStringFormat = timeStamp => {
  const time = timeStamp ? new Date(timeStamp) : new Date()

  const date = `0${time.getDate()}`.slice(-2)
  const month = time.getMonth() // time.getMonth() returns 0 for January
  const year = `${time.getFullYear()}`

  const monthName = [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "July",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember",
  ]

  const monthNames = monthName[month]

  return `${date}. ${monthNames} ${year}`
}

export const getTimeString = timeStamp => {
  const time = timeStamp ? new Date(timeStamp) : new Date()

  const hours = `0${time.getHours()}`.slice(-2)
  const minutes = `0${time.getMinutes()}`.slice(-2)

  return `${hours}.${minutes}`
}

export const todayOrInTheFuture = date => {
  if (!date) {
    return false
  }
  return date >= new Date()
}
