import { StyleSheet, Font } from "@react-pdf/renderer"

Font.register({
  family: "worksans",
  fonts: [
    { src: "/WorkSans-Thin.ttf", fontStyle: "normal", fontWeight: 100 },
    { src: "/WorkSans-ExtraLight.ttf", fontStyle: "normal", fontWeight: 200 },
    { src: "/WorkSans-Light.ttf", fontStyle: "normal", fontWeight: 300 },
    { src: "/WorkSans-Regular.ttf", fontStyle: "normal", fontWeight: 400 },
    { src: "/WorkSans-Medium.ttf", fontStyle: "normal", fontWeight: 500 },
    { src: "/WorkSans-SemiBold.ttf", fontStyle: "normal", fontWeight: 600 },
    { src: "/WorkSans-Bold.ttf", fontStyle: "normal", fontWeight: 700 },
    { src: "/WorkSans-ExtraBold.ttf", fontStyle: "normal", fontWeight: 800 },
    { src: "/WorkSans-Black.ttf", fontStyle: "normal", fontWeight: 900 },

    { src: "/WorkSans-ThinItalic.ttf", fontStyle: "italic", fontWeight: 100 },
    { src: "/WorkSans-ExtraLightItalic.ttf", fontStyle: "italic", fontWeight: 200 },
    { src: "/WorkSans-LightItalic.ttf", fontStyle: "italic", fontWeight: 300 },
    { src: "/WorkSans-Italic.ttf", fontStyle: "italic", fontWeight: 400 },
    { src: "/WorkSans-MediumItalic.ttf", fontStyle: "italic", fontWeight: 500 },
    { src: "/WorkSans-SemiBoldItalic.ttf", fontStyle: "italic", fontWeight: 600 },
    { src: "/WorkSans-BoldItalic.ttf", fontStyle: "italic", fontWeight: 700 },
    { src: "/WorkSans-ExtraBoldItalic.ttf", fontStyle: "italic", fontWeight: 800 },
    { src: "/WorkSans-BlackItalic.ttf", fontStyle: "italic", fontWeight: 900 },
  ],
})

const styles = StyleSheet.create({
  viewer: {
    height: 600,
    width: "100%",
  },
  page: { backgroundColor: "#f7f7f7", padding: 50 },
  body: {
    fontFamily: "worksans",
    fontSize: 12,
    backgroundColor: "#f7f7f7",
  },
  intro: {
    backgroundColor: "#FAF5E9",
    padding: 15,
    marginTop: 10,
  },
  notice: {
    backgroundColor: "#FAF5E9",
    borderRadius: 10,
    padding: 15,
    marginTop: 10,
  },
  h1: {
    fontFamily: "worksans",
    fontSize: 20,
  },
  h2: {
    fontFamily: "worksans",
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 5,
  },
  h3: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 10,
  },
  hr: {
    paddingBottom: 15,
    marginBottom: 20,
    borderBottom: "1 solid black",
  },
  centerText: {
    textAlign: "center",
  },
  title: {
    paddingHorizontal: 30,
    paddingVertical: 30,
    fontSize: 16,
  },
  icon: {
    width: 60,
    height: 60,
    marginRight: 20,
  },
  iconWithText: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  smallHeader: {
    color: "#333333",
    marginBottom: 10,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerLeft: {
    width: 147,
    height: 20,
    fontSize: 14,
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 10,
    opacity: 0.7,
    fontFamily: "worksans",
    fontSize: 15,
    fontWeight: 400,
  },
  bevaringspunkt: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: -10,
  },
  titleAndText: {
    paddingHorizontal: 10,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    gap: 10,
  },
  box: {
    marginVertical: 10,
    border: "2 solid #FFDD88",
    padding: 15,
  },
  boxHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderBottom: "1 solid black",
    paddingBottom: 15,
    marginBottom: 20,
  },

  forbedringspunkt: {
    height: "100%",
  },
  punkt: {
    backgroundColor: "#ffe9b4",
    padding: 30,
    fontSize: 16,
  },
  forbedringspunktContent: {
    fontSize: 16,
    backgroundColor: "white",
    padding: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bold: {
    fontWeight: 500,
  },
})

export default styles
