import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { Accordion } from "../../../components"
import HandlingsplanSchema from "./_handlingsplanSchema"
import { H } from "../../../state/levelContext"
import styles from "./style.module.scss"

const getForbedringspunktForSubject = (subjectId, industryId, forbedringspunkter) => {
  return Object.values(forbedringspunkter).filter(
    f => f.isChecked && f.industryId === industryId && f.subjectId === subjectId
  )
}

const Subject = ({ subject, industryId, texts }) => {
  // State
  const forbedringspunkter = useSelector(state => state.forbedringspunkter)

  const subjectForbedringspunkter = getForbedringspunktForSubject(
    subject._id,
    industryId,
    forbedringspunkter
  )
  return (
    <div className={classNames(styles.content, styles.subject)}>
      {subjectForbedringspunkter.length === 0 &&
      texts.noContent &&
      texts.subjects_subtitle_noContent ? (
        <H className="h3">{texts.subjects_subtitle_noContent}</H>
      ) : (
        <H className="h3">{texts.subjects_subtitle}</H>
      )}
      {subjectForbedringspunkter.length === 0 && texts.noContent && <p>{texts.noContent}</p>}
      {subjectForbedringspunkter.map((forbedringspunkt, i) => {
        return (
          <Accordion.CustomContent
            style={i !== subjectForbedringspunkter.length - 1 ? { marginBottom: "2rem" } : {}}
            id={forbedringspunkt.id}
            key={forbedringspunkt.id}
            title={forbedringspunkt.title.text}
            isVisited={forbedringspunkt.isVisited}
            defaultOpen={i === 0}
            headerBackgroundColor="yellow"
            headerBorderColor="yellow"
            backgroundColor="white"
            openText=""
            closeText=""
          >
            <HandlingsplanSchema
              forbedringspunkt={forbedringspunkt}
              texts={{
                subjects_howLabel: texts.subjects_howLabel,
                subjects_howPlaceholder: texts.subjects_howPlaceholder,
                subjects_priorityLabel: texts.subjects_priorityLabel,
                subjects_helpLabel: texts.subjects_helpLabel,
                subjects_deadlineLabel: texts.subjects_deadlineLabel,
                subjects_responsibleLabel: texts.subjects_responsibleLabel,
                subjects_responsiblePlaceholder: texts.subjects_responsiblePlaceholder,
              }}
            />
          </Accordion.CustomContent>
        )
      })}
    </div>
  )
}

Subject.propTypes = {
  subject: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    done: PropTypes.bool.isRequired,
  }).isRequired,
  industryId: PropTypes.string.isRequired,
  texts: PropTypes.shape({
    noContent: PropTypes.string,
    subjects_subtitle: PropTypes.string.isRequired,
    subjects_subtitle_noContent: PropTypes.string.isRequired,
    subjects_noRank: PropTypes.string.isRequired,
    subjects_howLabel: PropTypes.string.isRequired,
    subjects_howPlaceholder: PropTypes.string.isRequired,
    subjects_priorityLabel: PropTypes.string.isRequired,
    subjects_helpLabel: PropTypes.string.isRequired,
    subjects_deadlineLabel: PropTypes.string.isRequired,
    subjects_responsibleLabel: PropTypes.string.isRequired,
    subjects_responsiblePlaceholder: PropTypes.string.isRequired,
  }).isRequired,
}

Subject.defaultProps = {}

export default Subject
