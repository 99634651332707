import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { BlockContent } from "../../../components"
import styles from "./style.module.scss"
import { H } from "../../../state/levelContext"

function GoodWorkingEnv({ smallTitle, title, content, imageSource, altText }) {
  return (
    <div className={classNames("content", styles.goodWorkingEnv)}>
      <div className={classNames(styles.text, "desktop-col-7")}>
        {smallTitle && <H className="h3 normal-font-weight no-margin">{smallTitle}</H>}
        <H className="h1">{title}</H>
        <span>
          <BlockContent blocks={content} />
        </span>
      </div>
      <div className={classNames(styles.rightImage, "desktop-col-5")}>
        <img src={imageSource} alt={altText} />
      </div>
      {/* <div className="content" style={{ paddingBottom: "0" }}>
        <ScrollDown.Yellow />
      </div> */}
    </div>
  )
}

GoodWorkingEnv.propTypes = {
  smallTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  imageSource: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
}

GoodWorkingEnv.defaultProps = {
  smallTitle: null,
}

export default GoodWorkingEnv
