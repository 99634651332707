import React, { useState, useEffect } from "react"
import { BackLink, ForwardLink, Hero, Loading } from "../../components"
import SEO from "../../components/SEO"
import { IndustryType } from "../../propTypes"
import { withDefaults } from "../../utils"
import withPageDependencies from "../../hoc/withPageDependencies"
import useSanity from "../../hooks/useSanity"
import { blockContentGroq } from "../../groq"
import LinkWrapper from "../../components/linkWrapper"
import { H, Section } from "../../state/levelContext"
import Link from "../../components/link"
import ImageTextBlock from "../../components/imageTextBlock"

// import { loadState } from "../../state/localStorage"

const Arbeidsmiljohjelpen = ({ industry }) => {
  const [params, setParams] = useState(-1)

  useEffect(() => {
    setParams({
      pageDataType: "hjelpen_mainPage",
      forbedringspunkterPageType: "hjelpen_forbedringspunkterPage",
      industryTag: industry.tag,
    })
  }, [industry])

  const { loading, error, data } = useSanity(
    `{
      "pageData": *[_type==$pageDataType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
          ...,

          industry-> {
            tag,
          },

          hero {
            ...,
            image {
              ...,
              asset-> {
                url
              },
            },
            ingress[] ${blockContentGroq},
          },

          illustration_process {
            ...,
            asset-> {
              url
            },
          },

          step1 {
            smallTitle,
            title,
            buttonText,
            footerPostFixSingle,
            footerPostFixPlural,
            footerPostFixEmpty,
            footerButtonText,
            image {
              ...,
              asset-> {
                url
              },
            },
            ingress[] ${blockContentGroq},
          },


          step2 {
            ...,
            ingress[] ${blockContentGroq},
          },

          step3 {
            ...,
            image {
              ...,
              asset-> {
                url
              },
            },
            ingress[] ${blockContentGroq},
          },
        },
      "premadeForb": *[_type==$forbedringspunkterPageType &&
        industry->tag in [$industryTag, "default"] &&
        count(*[_id=="drafts."+^._id])==0] {
          industry-> {
            tag,
          },
          subject-> {
            _id,
          },
          "formulationItemsRefs": coalesce(formulationItemsRef[]-> { ...,}, []),
        },
    }`,
    params
  )

  if (loading || error) return <Loading error={error} />

  const page = withDefaults(data.pageData, industry.tag)

  return (
    <>
      <SEO title={industry.title} />
      <Hero
        title={page.hero.title}
        content={page.hero.ingress}
        portableContent
        industryName={industry.title}
        image={page.hero.image.asset.url}
        imageRightSide
        altImageText={page.hero.image.alt}
      />
      <section>
        <Section>
          <div className="content">
            <div className="desktop-col-12">
              <H className="h3">{page.illustration_header}</H>
              <ImageTextBlock
                imgUrl={page.illustration_process.asset.url}
                imgAlt={page.illustration_process.alt}
                content={page.content || []}
              />
            </div>
          </div>
        </Section>
      </section>
      <div className="content">
        <LinkWrapper>
          <div>
            <BackLink to="/">{page.backLink}</BackLink>
          </div>
          <div>
            {page.excludeStep === false && page.buttonText && (
              <Link.Big
                primary
                url={`/bransje/${industry.route}/moteleder`}
                arrow="right"
                style={{ width: "100%", justifyContent: "space-between", margin: "0 0 2rem 0" }}
              >
                {page.buttonText}
              </Link.Big>
            )}
            {industry.tag && page.forwardLink && (
              <ForwardLink to={`/bransje/${industry.route}/temavelger`}>
                {page.forwardLink}
              </ForwardLink>
            )}
          </div>
        </LinkWrapper>
      </div>
    </>
  )
}

Arbeidsmiljohjelpen.propTypes = {
  industry: IndustryType.isRequired,
}

export default withPageDependencies(Arbeidsmiljohjelpen)
